import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { Image, ImageProps, LayoutChangeEvent, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import Animated from 'react-native-reanimated'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const app_icon = require('../assets/app_icon.png') as ImageProps

interface Props {
  bannerUrl: string | undefined
  name: string
  color?: string
  spacing?: SpacingProps
  showName?: boolean
  style?: StyleProp<ViewStyle>
  size?: number
  onLayout?: (event: LayoutChangeEvent) => void
}

const IMAGE_DIMENSION = 26

const OrganizationBadge = memo(function OrganizationBadge({ bannerUrl, name, color, spacing, showName, style, size, onLayout }: Props) {
  const { themeUtils } = useTheme()

  const badgeColor = useMemo(() => themeUtils.getRandomColorFromTheme(name), [name])
  const imageSource = useMemo(() => (bannerUrl ? { uri: bannerUrl } : app_icon), [bannerUrl])

  return (
    <IM.View style={[IMLayout.flex.row, styles.container, style]} spacing={spacing} onLayout={onLayout}>
      <Animated.View
        style={[
          styles.badge,
          {
            width: size ?? IMAGE_DIMENSION,
            height: size ?? IMAGE_DIMENSION,
            backgroundColor: color ?? badgeColor,
          },
        ]}>
        <Image
          source={imageSource}
          style={[
            { width: size ?? IMAGE_DIMENSION, height: size ?? IMAGE_DIMENSION },
            { borderRadius: IMLayout.iconRadius },
            { backgroundColor: color ?? '#FFFFFF' },
          ]}
        />
      </Animated.View>
      {showName && (
        <IM.View spacing={['left']} style={[IMLayout.flex.f1]}>
          <IM.Text>{name}</IM.Text>
        </IM.View>
      )}
    </IM.View>
  )
})

export default OrganizationBadge

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  badge: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMLayout.iconRadius,
  },
})
