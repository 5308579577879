import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../types'

export default function DropdownCheck() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return <IM.Icon icon={['fal', 'check']} size={16} color={theme.dropdown.arrow} />
}
