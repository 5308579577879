import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostGradeRequest } from '../../apis/apiRequestTypes'
import { Grade } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import GroupSpacer from '../../components/GroupSpacer'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_GRADE_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'

const buttonAnimationValue = new Animated.Value(0)

export interface GradeCreateViewProps {
  editId?: string
  onCreateGrade?: (grade: Grade) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

const GradeCreateView: React.FC<GradeCreateViewProps & Props> = ({ editId, onCreateGrade, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [grade, setGrade] = useState<Partial<PostGradeRequest>>({})
  const [missingFields, setMissingFields] = useState<string[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostGradeRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditGrade().catch(console.error)
    }
  }, [editId])

  async function loadEditGrade() {
    setIsLoading(true)
    await api
      .getGrade({ gradeId: Number(editId) })
      .then(response => {
        const editGrade = {
          gradeName: response.gradeName,
          // Add other fields as necessary
        }
        setGrade(editGrade)
        setInitialValue(editGrade)
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostGradeRequest>) {
    setGrade(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields = [
      { value: grade.gradeName, field: 'gradeName' },
      // Add other required fields
    ]
    const notFilledFields = requiredFields.filter(field => !field.value).map(field => field.field)
    setMissingFields(notFilledFields)
    return notFilledFields.length === 0
  }

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate().finally(() => {
      setIsLoading(false)
    })
  }

  const handleCreate = async () => {
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [{ text: i18n.t('OK'), style: 'default' }])
      return
    }

    const gradeBody: Grade = {
      gradeId: grade.gradeId ?? 0,
      gradeName: grade.gradeName ?? '',
      organizationId: grade.organizationId ?? 0,
      gradeCode: grade.gradeCode ?? '',
    }

    if (editId) {
      await api
        .putGrade(gradeBody)
        .then(response => onUploadStatus('done'))
        .catch(error => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
      return
    }

    await api
      .postGrade(gradeBody)
      .then(() => onUploadStatus('done'))
      .catch(error => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={grade.gradeName ?? ''}
              onChangeText={value => handleChange({ gradeName: value })}
              spacing={'all'}
              error={missingFields.includes('gradeName')}
            />
            <TextInput
              title={i18n.t('CODE')}
              placeholder={i18n.t('CODE')}
              value={grade.gradeCode ?? ''}
              onChangeText={value => handleChange({ gradeCode: value })}
              spacing={'all'}
            />{' '}
            <GroupSpacer />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_GRADE_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default GradeCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
