import { IM, TextProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleSheet } from 'react-native'

const Text = memo(function Text({ children, ...props }: TextProps) {
  return (
    <IM.Text {...props} style={[props.style, Platform.OS === 'web' && styles.text]}>
      {children}
    </IM.Text>
  )
})

export default Text

const styles = StyleSheet.create({
  text: {
    // @ts-ignore web
    display: 'inherit',
  },
})
