import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { LotType } from '../../types/types'

export type LotTypeProps = { lotType: LotType } & CardProps

export default function LotTypeCard({ lotType, ...cardProps }: LotTypeProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{lotType.value}</IM.Text>
    </IM.Card>
  )
}
