/* eslint-disable @typescript-eslint/no-explicit-any */

import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import type { StyleProp, TextStyle, ViewStyle } from 'react-native'
import { Pressable, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import { TableHeaderIconData } from '../../types/types'
import TableUtils from '../../utils/TableUtils'
import Cell from '../cells/Cell'
import EditDeleteCell from '../cells/EditDeleteCell'

interface RowProps<T> {
  data: TableHeaderIconData<T>[] | string[]
  additionalEmptyCell?: boolean
  hasEditAndDelete?: boolean
  onDeletePress?: () => void
  onEditPress?: () => void
  onPress?: () => void
  height?: number
  widthArray?: number[]
  flexArray?: number[]
  selectable?: boolean
  cellStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  cellTextStyle?: (item: any) => StyleProp<TextStyle>
}

export default function Row<T>({
  data,
  additionalEmptyCell,
  hasEditAndDelete,
  onDeletePress,
  onEditPress,
  onPress,
  cellStyle,
  rowStyle,
  height,
  widthArray,
  flexArray,
  textStyle,
  cellTextStyle,
  selectable,
  ...props
}: RowProps<T>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'
  const tableFlex = flexArray ? TableUtils().sum(flexArray) : 1
  const hasDivider = hasEditAndDelete || additionalEmptyCell

  if (!data) return <></>

  return (
    <Pressable
      onPress={onPress}
      style={[
        {
          width: tableWidth,
          flex: tableFlex,
          height: height ?? 'auto',
        },
        IMLayout.flex.row,
        styles.row,
        rowStyle,
      ]}>
      {data.map((item, idx) => {
        const flex = flexArray?.[idx]
        const width = widthArray?.[idx]

        let cellData = ''
        if (typeof item === 'string') {
          cellData = item
        } else {
          cellData = item.title
        }

        const cellIcon = typeof item !== 'string' ? item.rightIcon : undefined
        const cellIconPress = typeof item !== 'string' ? item.rightIconPress : undefined
        const cellIconColor = typeof item !== 'string' ? item.rightIconColor : undefined

        return (
          <Cell
            key={idx}
            text={cellData}
            width={width}
            height={height}
            rightIcon={cellIcon}
            selectable={selectable}
            onRightIconPress={cellIconPress}
            iconColor={cellIconColor}
            flex={flex}
            cellContainerStyle={[
              hasDivider || !TableUtils().isLastItem(idx, data.length) ? styles.divider : undefined,
              { borderRightColor: theme.table.divider },
              cellStyle,
            ]}
            textStyle={[cellTextStyle && cellTextStyle(item), textStyle]}
            {...props}
          />
        )
      })}
      {hasEditAndDelete && (
        <EditDeleteCell
          numItems={data.length}
          onDeletePress={onDeletePress}
          onEditPress={onEditPress}
          widthArray={widthArray}
          flexArray={flexArray}
          height={height}
        />
      )}
      {additionalEmptyCell && <Cell width={widthArray?.[data.length]} height={height} />}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  row: {
    overflow: 'hidden',
  },
  divider: {
    borderRightWidth: 2,
  },
})
