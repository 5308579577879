import { IM, SpacingProps, SpacingType, useTheme } from '@infominds/react-native-components'
import React, { memo, ReactNode } from 'react'
import { Platform, StyleSheet } from 'react-native'
import Animated from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { SCREEN_CONSTANTS, ScreenHeaderShadows } from '../constants/constants'
import useScreen from '../hooks/useScreen'

interface ContentContainerProps {
  children: ReactNode
  spacing?: SpacingProps
  spacingType?: SpacingType
  forceBackground?: string
}

const ContentContainer = memo(function ContentContainer({ children, spacingType, forceBackground, spacing }: ContentContainerProps) {
  const { theme } = useTheme()
  const { height, detail } = useScreen()
  const insets = useSafeAreaInsets()
  const enableAnimation = typeof children === 'function'

  return (
    <Animated.View
      style={[
        styles.container,
        { top: height.header + insets.top + (enableAnimation ? 0 : detail.open ? height.detail : 0) },

        Platform.OS !== 'web' && ScreenHeaderShadows,
      ]}>
      <IM.View
        spacing={spacing}
        spacingType={spacingType}
        style={[styles.view, { backgroundColor: forceBackground ?? theme.background }, Platform.OS === 'web' && ScreenHeaderShadows]}>
        {children}
      </IM.View>
    </Animated.View>
  )
})

export default ContentContainer

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
  },
  view: {
    flex: 1,
    overflow: 'hidden',
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})
