import { useState } from 'react'
import { LayoutChangeEvent, LayoutRectangle } from 'react-native'

export default function useOnLayout(externalLayout?: (event: LayoutChangeEvent) => void) {
  const [layout, setLayout] = useState<LayoutRectangle | null>(null)

  return {
    onLayout: (event: LayoutChangeEvent) => {
      setLayout(event.nativeEvent.layout)
      externalLayout?.(event)
    },
    layout,
  }
}
