import { CardProps, IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Organization } from '../../apis/apiResponseTypes'
import OrganizationUtils from '../../utils/organizationUtils'
import TimeUtils from '../../utils/TimeUtils'
import InfoRow from '../InfoRow'

export type OrganizationInfoCardProps = { organization: Organization } & CardProps

export default function OrganizationInfoCard({ organization, ...cardProps }: OrganizationInfoCardProps) {
  const { i18n, language } = useLanguage()
  return (
    <IM.Card {...cardProps}>
      <InfoRow title={i18n.t('DATE')} value={TimeUtils.format(organization.addDate, language)} />
      <InfoRow title={i18n.t('NAME')} value={organization.organizationName} />
      <InfoRow title={i18n.t('VAT_NUMBER')} value={organization.vatNumber} />
      <InfoRow title={i18n.t('EMAIL')} value={OrganizationUtils.getEmail(organization.emails)} type="email" />
      <InfoRow title={i18n.t('PHONE_NUMBER')} value={OrganizationUtils.getPhoneNumber(organization.phoneNumbers)} type="phone" />
      <InfoRow title={i18n.t('WEBSITE')} value={organization.homepageUrl} type="link" />
    </IM.Card>
  )
}
