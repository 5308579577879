import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import IMInput from './IMInput'

type LoginInputProps = {
  usernameValue: string
  passwordValue: string
  spacing?: SpacingProps
  error?: boolean
  info?: boolean
  onUsernameChange: (value: string) => void
  onPasswordChange: (value: string) => void
  onSubmit?: () => void
}

export default function LoginInput({
  usernameValue,
  passwordValue,
  onUsernameChange,
  onPasswordChange,
  spacing,
  error,
  info,
  onSubmit,
}: LoginInputProps) {
  const { i18n } = useLanguage()

  const [showPassword, setShowPassword] = useState(false)

  return (
    <IM.View spacing={spacing} style={styles.container}>
      <IMInput
        value={usernameValue}
        underlineColorAndroid="transparent"
        placeholder={i18n.t('USERNAME')}
        rightIconStyle={styles.rightIcon}
        onChangeText={onUsernameChange}
        error={error}
        info={info}
        autoCapitalize="none"
        enterKeyHint={'enter'}
        onSubmitEditing={onSubmit}
      />
      <IMInput
        value={passwordValue}
        placeholder={i18n.t('PASSWORD')}
        rightIconStyle={styles.rightIcon}
        underlineColorAndroid="transparent"
        textContentType="password"
        rightIcon={['fal', showPassword ? 'eye-slash' : 'eye']}
        secureTextEntry={!showPassword}
        onChangeText={onPasswordChange}
        rightOnPress={() => setShowPassword(prev => !prev)}
        error={error}
        info={info}
        autoCapitalize="none"
        enterKeyHint={'enter'}
        onSubmitEditing={onSubmit}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    gap: 10,
  },
  rightIcon: {
    margin: 10,
  },
})
