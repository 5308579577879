import { useData } from '../contexts/DataContext'
import { Lot } from '../types/types'

export const useLots = () => {
  const { lots, setLots } = useData()

  const addLot = (lot: Lot) => {
    try {
      setLots([...lots, lot])
    } catch (error) {
      console.error('Failed to save lots to AsyncStorage', error)
    }
  }

  const updateLot = (lot: Lot) => {
    setLots(prev => [...prev.map(l => (l.tmpId === lot.tmpId ? lot : l))])
  }

  const clearLots = () => {
    try {
      const openLots = lots.filter(lot => !lot.closed)
      setLots(openLots)
    } catch (error) {
      console.error('Failed to clear lots from AsyncStorage', error)
    }
  }

  const deleteLot = (id: string) => {
    try {
      setLots(prev => [...prev.filter(l => (l.tmpId !== id ))])
    } catch (error) {
      console.error('Failed to delete Lot from AsyncStorage', error)
    }
  }

  const getLotById = (id: string): Lot | undefined => {
    try {
      return lots.find(lot => lot.tmpId === id) || undefined
    } catch (error) {
      console.error('Failed to get Lot by id', error)
      return undefined
    }
  }

  return { addLot, clearLots, getLotById, updateLot, deleteLot }
}
