import AsyncStorage from '@react-native-async-storage/async-storage'
import { Route } from '@react-navigation/native'
import Color from 'color'
import { i18n } from 'i18next'
import { ColorSchemeName } from 'react-native'
import { AtomEffect, DefaultValue, WrappedValue } from 'recoil'
import { serializeError } from 'serialize-error'

import { ThemeColorExpanded } from '../types'
import { ApiError } from '../types/types'

type AbortError = {
  name: string
}

type setSelfType<T> =
  | T
  | DefaultValue
  | Promise<T | DefaultValue>
  | WrappedValue<T>
  | ((param: T | DefaultValue) => T | DefaultValue | WrappedValue<T>)

const appUtils = {
  isAbortError(err: AbortError | unknown): err is AbortError {
    return (err as AbortError).name !== undefined && (err as AbortError).name === 'AbortError'
  },
  isBackendError(obj: unknown): obj is ApiError {
    return (obj as ApiError).Message !== undefined
  },
  getBackendErrorMessage(obj: unknown) {
    const error = serializeError(obj)
    if (appUtils.isBackendError(error)) {
      return error.Message
    } else {
      if (error.code && error.message) {
        return `${error.code} - ${error.message}`
      } else if (error.code) {
        return error.code
      } else if (error.message) {
        return error.message
      } else {
        return 'Generic error'
      }
    }
  },
  parseFloatFromText(text: string) {
    return Number.parseFloat((text ?? '').replace(',', '.').replace(/[^0-9+-.]/g, ''))
  },
  localStorageEffect<T>(key: string): AtomEffect<T> {
    return ({ setSelf, onSet }) => {
      AsyncStorage.getItem(key)
        .then((savedValue: string | null) => {
          if (savedValue !== null) {
            setSelf(JSON.parse(savedValue) as setSelfType<T>)
          }
        })
        .catch((err: Error) => console.error(`Failed fetching ${key}:`, err))

      onSet((newValue, _, isReset) => {
        if (isReset) {
          AsyncStorage.removeItem(key).catch((err: Error) => console.error(`Failed resetting ${key}:`, err))
        } else {
          AsyncStorage.setItem(key, JSON.stringify(newValue)).catch((err: Error) => console.error(`Failed saving ${key}:`, err))
        }
      })
    }
  },
  getModalOpacity(colorScheme: NonNullable<ColorSchemeName>) {
    return colorScheme === 'light' ? 0.45 : 0.65
  },
  getModalBackground(colorScheme: NonNullable<ColorSchemeName>, theme: ThemeColorExpanded) {
    return colorScheme === 'light' ? theme.background : Color(theme.header.detail.background).darken(0.3).toString()
  },
  getDocumentTitle: (route: Route<string> | undefined, translator: i18n) => {
    const appName = 'IlCubatore'
    if (route?.name.includes('Lots')) {
      return translator.t('LOTS') + ' | ' + appName
    } else if (route?.name.includes('Packages')) {
      return translator.t('PACKAGES') + ' | ' + appName
    } else if (route?.name.includes('AllOrganizations')) {
      return translator.t('ALL_ORGANIZATIONS') + ' | ' + appName
    } else if (route?.name.includes('Organization')) {
      return translator.t('ORGANIZATION') + ' | ' + appName
    } else if (route?.name.includes('Settings')) {
      return translator.t('SETTINGS') + ' | ' + appName
    } else {
      return appName
    }
  },
}

export default appUtils
