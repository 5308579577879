import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { TextInput as DefaultTextInput, GestureResponderEvent, Platform, StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import Icon from './Icon'

type Props = {
  label?: string
  labelMinWidth?: number
  spacing?: SpacingProps
  rightIcon?: IconProp
  rightIconColor?: string
  rightIconStyle?: StyleProp<ViewStyle>
  rightOnPress?: (event: GestureResponderEvent) => void
  error?: boolean // TODO change
  errorMessag?: string // TODO change
  info?: boolean // TODO change
  infoMessage?: string // TODO change
}

export type InputProps = Props & DefaultTextInput['props']

export default function IMInput(props: InputProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { style, spacing, label, rightOnPress, rightIconColor, rightIconStyle, error, errorMessag, info, infoMessage, ...otherProps } = props

  const borderColor = error ? theme.error : info ? theme.info : theme.input.border

  return (
    <IM.View
      style={[
        styles.container,
        {
          backgroundColor: theme.input.background,
          borderColor: borderColor,
        },
      ]}
      spacing={spacing}
      spacingType="margin">
      {label && (
        <>
          <IM.Text
            style={[styles.label, styles.marginRight, { color: theme.textPlaceholder }, !!props.labelMinWidth && { minWidth: props.labelMinWidth }]}>
            {label}
          </IM.Text>
          <IM.View style={[styles.marginRight, styles.separator, { backgroundColor: theme.textPlaceholder }]} />
        </>
      )}
      <DefaultTextInput
        style={[styles.textInput, Platform.select({ web: styles.textInputWeb }), { color: theme.text }, style]}
        placeholderTextColor={theme.textPlaceholder}
        {...otherProps}
      />
      {errorMessag && <IM.Text style={{ color: theme.error }}>{errorMessag}</IM.Text>}
      {infoMessage && <IM.Text style={{ color: theme.info }}>{infoMessage}</IM.Text>}
      {props.rightIcon && (
        <TouchableOpacity style={[styles.rightComponent, rightIconStyle]} onPress={rightOnPress}>
          <Icon color={rightIconColor} icon={props.rightIcon} size={20} />
        </TouchableOpacity>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: IMStyle.layout.borderRadius,
    flexDirection: 'row',
    ...Platform.select({
      web: {
        outlineStyle: 'none',
      },
    }),
  },
  marginRight: {
    marginRight: 10,
  },
  separator: {
    width: 1,
    borderRadius: 10,
  },
  label: {
    alignSelf: 'center',
    padding: 12,
  },
  textInput: {
    padding: 12,
    flex: 1,
  },
  textInputWeb: {
    //@ts-ignore only for web
    outlineStyle: 'none', //web
  },
  rightComponent: {
    justifyContent: 'center',
  },
})
