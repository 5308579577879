import { IM, useAlert, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { SupportedLanguages } from '../types/types'
import DropdownArrowDown from './DropdownArrowDown'
import DropdownArrowUp from './DropdownArrowUp'
import DropdownCheck from './DropdownCheck'

export type LanguageSelectorRef = { close: () => void }

interface Props {
  onOpen: () => void
  onReLogIn: () => void
}

const LanguageSelector = ({ onOpen, onReLogIn }: Props, ref: ForwardedRef<LanguageSelectorRef>) => {
  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
  }))

  const { alert } = useAlert()
  const { i18n, language, setLanguageAsync } = useLanguage()
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(language)

  const originalLanguage = useRef(language)

  useEffect(() => {
    if (originalLanguage.current !== selected) {
      alert(i18n.t('WARNING'), i18n.t('CHANGE_LANGUAGE_WARNING'), [
        {
          text: i18n.t('CANCEL'),
          style: 'default',
          onPress: () => {
            return setSelected(originalLanguage.current)
          },
        },
        {
          text: i18n.t('RESTART_APP'),
          style: 'destructive',
          onPress: () => {
            setLanguageAsync(selected)
              .then(() => {
                onReLogIn()
              })
              .catch(console.error)
              .catch(err => console.error('Failed language re-auth:', err))
          },
        },
      ])
    }
  }, [selected])

  return (
    <IM.Dropdown
      closeAfterSelecting
      open={open}
      value={selected}
      items={SupportedLanguages}
      setOpen={setOpen}
      setValue={setSelected}
      onChangeValue={val => {
        val !== null && setSelected(val)
      }}
      listMode="SCROLLVIEW"
      containerStyle={styles.dropdown}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ minHeight: 42 }}
      TickIconComponent={DropdownCheck}
      ArrowDownIconComponent={DropdownArrowDown}
      ArrowUpIconComponent={DropdownArrowUp}
      onOpen={onOpen}
    />
  )
}

export default forwardRef(LanguageSelector)

const styles = StyleSheet.create({
  dropdown: { width: 220 },
})
