import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { atom } from 'recoil'

export const plannerActivityFilterCountAtom = atom<number | undefined>({
  key: 'plannerActivityFilterCountAtom',
  default: undefined,
})

export const plannerActivitySearchFilterCountAtom = atom<number | undefined>({
  key: 'plannerActivitySearchFilterCountAtom',
  default: undefined,
})

export const plannerAbsenceFilterCountAtom = atom<number | undefined>({
  key: 'plannerAbsenceFilterCountAtom',
  default: undefined,
})

export const activityHistoryFilterEnableAtom = atom({
  key: 'activityHistoryFilterEnableAtom',
  default: false,
})

export const activityPlanningFilterEnableAtom = atom({
  key: 'activityPlanningFilterEnableAtom',
  default: false,
})

export const landingPageUrlAtom = atom<string | undefined>({
  key: 'landingPageUrlAtom',
  default: undefined,
})

export const revalidateSettingsAtom = atom({
  key: 'revalidateSettingsAtom',
  default: false,
})

export const navigationRefAtom = atom<NavigationContainerRefWithCurrent<ReactNavigation.RootParamList> | undefined>({
  key: 'navigationRefAtom',
  default: undefined,
})
