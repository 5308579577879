import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import { StackActions, useNavigation } from '@react-navigation/core'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import React, { memo, ReactNode } from 'react'
import { GestureResponderEvent, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import useBackHandlerWrapper from '../../../hooks/useBackHandlerWrapper'
// import useBackHandlerWrapper from '../../../hooks/useBackHandlerWrapper'
import useLayout from '../../../hooks/useLayout'
import { RootStackParamList } from '../../../navigation/types'
import { ThemeColorExpanded } from '../../../types'
import LoadingIcon from '../../LoadingIcon'
import PressableIcon from '../../PressableIcon'
import { SCREEN_CONSTANTS, ScreenHeaderShadows } from '../constants/constants'
import DefaultHeader from '../headers/default/DefaultHeader'
import type { StringContextText } from '../types'

type HeaderContainerProps = (DefaultBar | CustomBar) &
  (GoBack | NoGoBack) & {
    style?: StyleProp<ViewStyle>
    onRightIconPress?: boolean | ((event: GestureResponderEvent) => void)
    rightIcon?: IconProp
    disable?: boolean
    loading?: boolean
  }

type GoBack = {
  goBack: boolean | ((event: GestureResponderEvent) => void) | false
  goBackLoading?: boolean
  isModal?: boolean
  backHandlerCallback?: () => void
}

type NoGoBack = {
  goBack?: never
  goBackLoading?: never
  isModal?: never
  backHandlerCallback?: never
}

type DefaultBar = {
  children?: never
  title?: string
  subTitle?: StringContextText
}

type CustomBar = {
  children: ReactNode
  title?: never
  subTitle?: never
}

const HeaderContainer = memo(function HeaderContainer({
  children,
  title,
  subTitle,
  backHandlerCallback,
  isModal = false,
  ...props
}: HeaderContainerProps) {
  const navigation = useNavigation<DrawerNavigationProp<RootStackParamList>>()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { isLargeDevice } = useLayout(true)

  useBackHandlerWrapper(() => {
    if (backHandlerCallback) {
      backHandlerCallback()
      return true
    }
    // let the default thing happen
    return false
  })

  const showGoBackIcon =
    (Platform.OS !== 'web' || (Platform.OS === 'web' && isModal)) && !!props.goBack && (typeof props.goBack === 'function' || navigation.canGoBack())
  const showRightIcon = !!props.onRightIconPress
  const showMenu = Platform.OS === 'web' && !isLargeDevice

  function handleGoBack(event: GestureResponderEvent) {
    if (!props.goBack) return
    if (typeof props.goBack === 'function') return props.goBack(event)
    navigation.goBack()
  }

  function handleClose(event: GestureResponderEvent) {
    if (!props.onRightIconPress) return
    if (typeof props.onRightIconPress === 'function') return props.onRightIconPress(event)
    navigation.dispatch(StackActions.popToTop())
  }

  return (
    <IM.View
      style={[
        styles.container,
        { backgroundColor: theme.header.main.background },
        Platform.OS === 'web' && styles.containerWeb,
        Platform.OS !== 'web' && ScreenHeaderShadows,
      ]}>
      <IM.View style={[styles.view, props.style]}>
        {showGoBackIcon ? (
          <>
            {props.goBackLoading ? (
              <IM.View style={[styles.paddingRight, styles.paddingLeft]}>
                <LoadingIcon size={20} />
              </IM.View>
            ) : (
              <PressableIcon
                style={styles.menuIcon}
                icon={['fal', isModal ? 'times' : 'arrow-left']}
                color={IMStyle.palette.white}
                onPress={handleGoBack}
                size={30}
              />
            )}
          </>
        ) : (
          <>
            {showMenu && (
              <PressableIcon
                style={styles.menuIcon}
                icon={['fal', 'bars']}
                color={IMStyle.palette.white}
                onPress={() => navigation.openDrawer()}
                size={20}
              />
            )}
          </>
        )}
        <IM.View style={[styles.childrenContainer, !showGoBackIcon && !showMenu && styles.paddingLeft, !showRightIcon && styles.paddingRight]}>
          {children ? children : <DefaultHeader title={title} subtitle={subTitle} />}
        </IM.View>
        {showRightIcon && (
          <>
            {props.loading ? (
              <IM.View style={styles.paddingRight}>
                <LoadingIcon size={20} />
              </IM.View>
            ) : (
              <PressableIcon
                icon={props.rightIcon ?? ['fal', 'times']}
                color={IMStyle.palette.white}
                style={styles.icon}
                onPress={handleClose}
                disabled={props.disable}
                disabledColor={colorScheme === 'dark' ? theme.pressable.disabled : '#8d8d8d'}
                size={22}
              />
            )}
          </>
        )}
      </IM.View>
    </IM.View>
  )
})

export default HeaderContainer

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
  containerWeb: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  view: {
    height: SCREEN_CONSTANTS.headerBarHeight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  childrenContainer: { flexDirection: 'row', alignItems: 'center', flex: 1, height: '100%' },
  icon: {
    marginHorizontal: IMLayout.horizontalMargin,
  },
  menuIcon: {
    marginLeft: IMLayout.horizontalMargin,
  },
  paddingLeft: {
    paddingLeft: SCREEN_CONSTANTS.headerHorizontalMargin,
  },
  paddingRight: {
    paddingRight: SCREEN_CONSTANTS.headerHorizontalMargin,
  },
})
