import { IM } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { StyleSheet } from 'react-native'

import DefaultBaseTextInputTitle, { DefaultBaseTextInputTitleProps } from './DefaultBaseTextInputTitle'

const BaseTextInputTitle = memo(function BaseTextInputTitle({ children, ...props }: PropsWithChildren<DefaultBaseTextInputTitleProps>) {
  return <IM.View style={styles.container}>{children ? children : <DefaultBaseTextInputTitle {...props} />}</IM.View>
})

export default BaseTextInputTitle

const styles = StyleSheet.create({
  container: {
    paddingBottom: 4,
  },
})
