import { SpacingProps, SpacingType, useSpacing } from '@infominds/react-native-components'
import React from 'react'
import { ViewProps } from 'react-native'
import Animated, { AnimateProps } from 'react-native-reanimated'

interface Props extends AnimateProps<ViewProps> {
  spacingType?: SpacingType
  spacing?: SpacingProps
}

export default function AnimatedView({ children, spacing, style, spacingType, ...others }: Props) {
  const calculatedStyle = useSpacing(spacing, spacingType ?? 'padding')

  return (
    <Animated.View {...others} style={[calculatedStyle, style]}>
      {children}
    </Animated.View>
  )
}
