import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Origin } from '../../apis/apiResponseTypes' // Assuming Origin type is defined

export type OriginCardProps = { origin: Origin } & CardProps

export default function OriginCard({ origin, ...cardProps }: OriginCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{origin.originName}</IM.Text>
    </IM.Card>
  )
}
