import { useMemo } from 'react'

import { useData } from '../contexts/DataContext'
import { Package } from '../types/types'

export const usePackage = (packageTmpId: string) => {
  const { packages, setPackages } = useData()
  const pkg = useMemo(() => packages.find(p => p.tmpId === packageTmpId), [packages, packageTmpId])

  function setPackage(updatedPackage: Partial<Package>) {
    setPackages(prev => [...prev.map(p => (p.tmpId === packageTmpId ? { ...p, ...updatedPackage } : p))])
  }

  return { pkg, setPackage }
}
