import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Grade } from '../../apis/apiResponseTypes' // Assuming Grade type is defined

export type GradeCardProps = { grade: Grade } & CardProps

export default function GradeCard({ grade, ...cardProps }: GradeCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{grade.gradeName}</IM.Text>
    </IM.Card>
  )
}
