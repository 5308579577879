import { IM, IMLayout, useAlert, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useState } from 'react'
import { ImageSourcePropType, ImageStyle, StyleProp, StyleSheet } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'

import LoginImage from '../../components/LoginImage'
import LoginInput from '../../components/LoginInput'
import Spacer from '../../components/Spacer'
import CONSTANTS from '../../constants/constants'
import { STORAGE_KEYS } from '../../constants/keys'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import useKeyPress from '../../hooks/useKeyPress'
import { ThemeColorExpanded } from '../../types'

export type NotificationMessage = {
  message: string
  type: 'error' | 'warning'
}

export type LoginViewProps = {
  iconSource?: ImageSourcePropType
  iconStyle?: StyleProp<ImageStyle>
  codeScannerOrientationOnExit?: OrientationType
  onSuccess?: () => void
  onScannerPress?: () => void
  onScannerClose?: () => void
}

function LoginView({ ...props }: LoginViewProps) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showUrl, setShowUrl] = useState(false)
  const alert = useAlert()
  const [busy, setBusy] = useState(true)
  const { userLogin } = useAuthentication()

  const loginButtonDisabled = !username || !password

  useKeyPress(
    {
      key: ['Enter'],
      cb: () => {
        onLoginButtonPress()
      },
    },
    []
  )

  useEffect(() => {
    const attemptSilentLogin = async () => {
      const storedUsername = await AsyncStorage.getItem(STORAGE_KEYS.USERNAME_KEY)
      const storedPassword = await AsyncStorage.getItem(STORAGE_KEYS.PASSWORD_KEY)
      if (!storedUsername || !storedPassword) throw new Error('No username or password stored')
      await login(storedUsername, storedPassword)
    }
    attemptSilentLogin().catch(error => {
      setBusy(false)
      console.error('silent login failed:', error)
    })
  }, [])

  const showAlert = (message: string) => {
    alert.alert(i18n.t('ERROR'), message, [
      {
        onPress: () => {
          return
        },
        text: i18n.t('OK'),
      },
    ])
  }

  const login = async (usernameInput: string, passwordInput: string) => {
    setBusy(true)
    await userLogin(usernameInput, passwordInput)
      .catch(error => {
        if (error instanceof Error) {
          showAlert(Utils.stringValueReplacer(i18n.t('LOGIN_FAILED_ALERT'), error.message))
        } else {
          showAlert(Utils.stringValueReplacer(i18n.t('LOGIN_FAILED_ALERT')))
        }
      })
      .finally(() => {
        setBusy(false)
      })
  }

  function onLoginButtonPress() {
    if (loginButtonDisabled) return
    if (!username || !password) return

    login(username, password).catch(error => {
      console.error('login error', error)
    })
  }

  return (
    <IM.View style={styles.container}>
      {busy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <IM.View style={[styles.login, IMLayout.shadow, { backgroundColor: colorScheme === 'dark' ? theme.backgroundSecondary : theme.background }]}>
        <LoginImage
          urlShown={showUrl}
          source={props.iconSource}
          small={showUrl}
          iconStyle={props.iconStyle}
          onLongPress={() => setShowUrl(prev => !prev)}
        />
        <Spacer height={60} />

        <LoginInput
          usernameValue={username}
          passwordValue={password}
          onUsernameChange={setUsername}
          onPasswordChange={setPassword}
          spacing="bottom"
          onSubmit={onLoginButtonPress}
        />

        <Spacer height={40} />

        <IM.Button title={'LOGIN'} onPress={onLoginButtonPress} disabled={loginButtonDisabled} type={'primary'} style={styles.button} />
      </IM.View>
      <IM.View style={styles.footer}>
        {<IM.Text style={styles.bottomText}>{CONSTANTS.appName}</IM.Text>}
        <IM.Text style={styles.bottomText}>v{process.env.VERSION}</IM.Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  login: {
    height: 600,
    padding: 35,
    borderRadius: IMLayout.borderRadius * 2,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  button: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  footer: {
    position: 'absolute',
    bottom: 8,
    alignSelf: 'center',
    alignItems: 'center',
  },
  bottomText: {
    color: 'white',
  },
})
export default LoginView
