import { DependencyList } from 'react'

interface Props {
  key: string | string[] | undefined
  cb: (key: string) => void
}

export default function useKeyPress({}: Props, _deps?: DependencyList) {
  return false
}
