import { useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../../../../types'
import PressableIcon from '../../../PressableIcon'
import Cell from './Cell'

export type EditDeleteCellProps = {
  numItems: number
  onDeletePress?: () => void
  onEditPress?: () => void
  widthArray?: number[]
  flexArray?: number[]
  height?: number
}

export default function EditDeleteCell({ numItems, onDeletePress, onEditPress, widthArray, flexArray, height }: EditDeleteCellProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <Cell key={numItems} width={widthArray?.[numItems]} height={height} flex={flexArray?.[numItems]} cellStyle={{ marginLeft: 10 }}>
      <PressableIcon icon={['fal', 'trash']} color={theme.table.icon} size={16} onPress={onDeletePress} pressableRatio={0.8} />
      <PressableIcon icon={['fal', 'pen']} color={theme.table.icon} size={16} onPress={onEditPress} pressableRatio={0.8} />
    </Cell>
  )
}
