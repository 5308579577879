import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { LicenseSelectType } from '../../types/types'

export type LicenseTypeProps = { licenseType: LicenseSelectType } & CardProps

export default function LicenseTypeCard({ licenseType, ...cardProps }: LicenseTypeProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{licenseType.value}</IM.Text>
    </IM.Card>
  )
}
