import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import type { StyleProp, TextStyle, ViewProps, ViewStyle } from 'react-native'
import { StyleSheet } from 'react-native'

import PressableIcon from '../../../PressableIcon'

interface CellProps {
  text?: string
  width?: number
  height?: number
  flex?: number
  rightIcon?: IconProp
  onRightIconPress?: () => void
  children?: React.ReactNode
  borderStyle?: {
    borderColor?: string
    borderWidth?: number
  }
  cellContainerStyle?: StyleProp<ViewStyle>
  cellStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  iconStyle?: StyleProp<ViewStyle>
  iconColor?: string
  iconSize?: number
  cellContainer?: ViewProps
  selectable?: boolean
}

export default function Cell({
  text,
  width,
  height,
  flex,
  cellContainerStyle,
  cellStyle,
  textStyle,
  iconStyle,
  iconColor,
  iconSize,
  borderStyle,
  children,
  onRightIconPress,
  rightIcon,
  selectable,
  cellContainer = {},
  ...props
}: CellProps) {
  const cellElement = useMemo(() => {
    if (children) return children
    if (!text) return <></>

    return (
      <IM.Text style={[textStyle]} {...props} selectable={selectable}>
        {text}
      </IM.Text>
    )
  }, [text, children])

  const borderTopWidth = borderStyle?.borderWidth ?? 0
  const borderRightWidth = borderTopWidth
  const borderColor = borderStyle?.borderColor ?? '#000'

  const composedStyles = useMemo(() => {
    const styles: ViewStyle = {}
    if (width) styles.width = width

    if (height) styles.height = height

    if (flex) styles.flex = flex

    if (!width && !flex && !height) styles.flex = 1

    return styles
  }, [width, height, flex])

  return (
    <IM.View
      {...cellContainer}
      style={[
        {
          borderTopWidth,
          borderRightWidth,
          borderColor,
        },
        composedStyles,
        cellContainerStyle,
        cellContainer.style,
      ]}>
      <IM.View style={[styles.cell, cellStyle]}>
        {cellElement}
        {rightIcon && (
          <PressableIcon
            icon={rightIcon}
            color={iconColor}
            size={iconSize ?? 16}
            style={[iconStyle]}
            onPress={onRightIconPress}
            pressableRatio={0.8}
          />
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  cell: { padding: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
})
