import { IM, IMLayout, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { RouteProp, useRoute } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { OrganizationLicense, User } from '../../apis/apiResponseTypes'
import AddressCard from '../../components/cards/AddressCard'
import OrganizationInfoCard from '../../components/cards/OrganizationInfoCard'
import PressableIcon from '../../components/PressableIcon'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import useDeleteErrorAlert from '../../hooks/useDeleteErrorAlert'
import LicenseCreateModal from '../../modals/LicenseCreateModal'
import OrganizationCreateModal from '../../modals/OrganizationCreateModal'
import UserCreateModal from '../../modals/UserCreateModal'
import { AllOrganizationsStackParamList } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import OrganizationUtils from '../../utils/organizationUtils'
import TimeUtils from '../../utils/TimeUtils'
import { LicenseCreateViewProps } from '../LicenseCreate/LicenseCreateView'
import { OrganizationCreateViewProps } from '../OrganizationCreate/OrganizationCreateView'
import { UserCreateViewProps } from '../UserCreate/UserCreateView'

export interface LicenseTableData {
  licenseKey: string
  licenseType: string
  activationDate: string
  expirationDate: string
}

export default function OrganizationView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const { isAdmin } = useAuthentication()
  const deleteAlert = useDeleteAlert()
  const deleteErrorAlert = useDeleteErrorAlert()
  const { alert } = useAlert()
  const { currentOrganization, licenses: currentLicenses, users: currentUsers, fetchSpecificData } = useData()
  const route = useRoute<RouteProp<AllOrganizationsStackParamList, 'Organization'>>()
  const [selectedOrganization, setSelectedOrganization] = useState(currentOrganization)
  const [licenses, setLicenses] = useState<OrganizationLicense[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [isLoading, setIsLoading] = useState(false)

  // AdminModifyMode is true if the user is an admin and the screen is being loaded throug the AllOrganizations screen
  const isAdminModifyMode =
    isAdmin && route?.params?.organizationId !== undefined && route?.params?.organizationId !== currentOrganization?.organizationId
  // use the organizationId from the route if it exists, otherwise use the current organization
  const selectedOrganizationId = route?.params?.organizationId ?? currentOrganization?.organizationId

  useEffect(() => {
    setIsLoading(true)
    loadData()
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [selectedOrganizationId])

  useEffect(() => {
    loadData().catch(console.error)
  }, [])

  useEffect(() => {
    if (!isAdminModifyMode) {
      setSelectedOrganization(currentOrganization)
      setLicenses(currentLicenses)
      setUsers(currentUsers)
    }
  }, [currentOrganization, currentLicenses, currentUsers])

  async function loadData() {
    if (selectedOrganizationId !== undefined) {
      if (isAdminModifyMode) {
        try {
          const [organizationData, licensesData, usersData] = await Promise.all([
            api.getOrganization({ organizationId: selectedOrganizationId }),
            api.getLicenses({ organizationId: selectedOrganizationId }),
            api.getUsers({ organizationId: selectedOrganizationId }),
          ])

          setSelectedOrganization(organizationData)
          setLicenses(licensesData)
          setUsers(usersData)
        } catch (error) {
          console.log('Error fetching data', error)
          alert(i18n.t('ERROR'), i18n.t('ERROR_LOADING_ORGANIZATION'), [
            {
              onPress: () => {
                return
              },
              text: i18n.t('OK'),
            },
          ])
        }
      } else {
        if (currentOrganization?.organizationId) {
          fetchSpecificData(['licenses', 'users', 'organization'], { organizationId: currentOrganization?.organizationId }).catch(error => {
            console.log('Error fetching data', error)
            alert(i18n.t('ERROR'), i18n.t('ERROR_LOADING_ORGANIZATION'), [
              {
                onPress: () => {
                  return
                },
                text: i18n.t('OK'),
              },
            ])
          })
        }
      }
    }
  }

  const createUserModal = useModalController<UserCreateViewProps>()
  const createLicenseModal = useModalController<LicenseCreateViewProps>()
  const createOrganizationModal = useModalController<OrganizationCreateViewProps>()

  //License table -------------------------------------------------------
  const licenseTableHeader: TableHeaderData<LicenseTableData>[] = useMemo(
    () => [
      { title: i18n.t('LICENSE_KEY'), property: 'licenseKey' },
      { title: i18n.t('LICENSE_TYPE'), property: 'licenseType' },
      { title: i18n.t('ACTIVATION_DATE'), property: 'activationDate', isDate: true },
      { title: i18n.t('EXPIRATION_DATE'), property: 'expirationDate', isDate: true },
    ],
    [i18n]
  )

  const licenseTableData: LicenseTableData[] = useMemo(() => {
    return licenses.map(lic => ({
      licenseKey: lic.licenseKey,
      licenseType: lic.licenseType,
      activationDate: TimeUtils.format(lic.activationDate, language),
      expirationDate: TimeUtils.format(lic.expirationDate, language),
    }))
  }, [licenses, language])

  function onNewLicenseCreated() {
    createLicenseModal.close()
    loadData().catch(console.error)
  }

  function handleNewLicensePress() {
    createLicenseModal.show({})
  }

  function handleLicenseEdit(license: LicenseTableData) {
    const selectedLicense = licenses.find(lic => lic.licenseKey === license.licenseKey)
    createLicenseModal.show({ editId: selectedLicense?.licenseId.toString() })
  }

  //TODO: fix delete
  function handleLicenseDelete(license: LicenseTableData) {
    const selectedLicense = licenses.find(lic => lic.licenseKey === license.licenseKey)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteLicense({ licenseId: Number(selectedLicense?.licenseId) })
            .then(_response => {
              loadData().catch(console.error)
            })
            .catch(error => {
              console.log('Error deleting license', error)
              deleteErrorAlert.show()
            })
        }
      })
      .catch(console.error)
  }

  //Account table
  const accountTableHeader: TableHeaderData<User>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'firstName' },
      { title: i18n.t('LAST_NAME'), property: 'lastName' },
      { title: i18n.t('USERNAME'), property: 'username' },
    ],
    [i18n]
  )

  function handleNewUserPress() {
    createUserModal.show({})
  }

  function handleUserEdit(user: User) {
    createUserModal.show({ editId: user.userId.toString() })
  }

  //TODO: fix delete
  function handleUserDelete(user: User) {
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteUser({ userId: user.userId })
            .then(_response => {
              loadData().catch(console.error)
            })
            .catch(error => {
              console.log('Error deleting license', error)
              deleteErrorAlert.show()
            })
        }
      })
      .catch(console.error)
  }

  const onNewUserCreated = (_user: User) => {
    createUserModal.close()
    loadData().catch(console.error)
  }

  // Organization Information ------------------------------------------------------
  function handleOrganizationEdit() {
    createOrganizationModal.show({ editId: selectedOrganizationId?.toString() })
  }

  const onCreateOrganization = () => {
    createOrganizationModal.close()
    loadData().catch(console.error)
  }

  if (!selectedOrganization) return <></>

  return (
    <>
      {isLoading && (
        <IM.View spacing={'all'} style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={isLoading} />
        </IM.View>
      )}
      {!isLoading && (
        <>
          <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
            <IM.View spacing="all" style={styles.container}>
              <IM.View spacing={'all'} style={IMLayout.flex.row}>
                <IM.View spacing={'right'} style={IMLayout.flex.f1}>
                  <IM.View style={styles.infoTitle}>
                    <IM.Text>{i18n.t('INFORMATION')}</IM.Text>
                    <PressableIcon icon={['fal', 'pen']} size={14} onPress={handleOrganizationEdit} />
                  </IM.View>
                  <OrganizationInfoCard organization={selectedOrganization} />
                </IM.View>
                <IM.View spacing={'left'} style={IMLayout.flex.f1}>
                  <IM.View style={styles.infoTitle}>
                    <IM.Text>{i18n.t('ADDRESS')}</IM.Text>
                    <PressableIcon icon={['fal', 'pen']} size={14} onPress={handleOrganizationEdit} />
                  </IM.View>
                  <AddressCard address={OrganizationUtils.getAddress(selectedOrganization?.addresses)} />
                </IM.View>
              </IM.View>
              <TableWrapper
                tableTitle={i18n.t('LICENSES')}
                tableContainerSpacing={'all'}
                headerData={licenseTableHeader}
                tableData={licenseTableData}
                maxTableHeight={250}
                showNewItemRow={isAdmin}
                onNewItemPress={handleNewLicensePress}
                hasEditAndDelete={isAdmin}
                onEditPress={handleLicenseEdit}
                onDeletePress={handleLicenseDelete}
                flexArray={isAdmin ? [4, 4, 4, 4, 1] : undefined}
              />

              <TableWrapper
                tableTitle={i18n.t('ACCOUNTS')}
                tableContainerSpacing={'all'}
                headerData={accountTableHeader}
                tableData={users}
                maxTableHeight={250}
                showNewItemRow={!isAdminModifyMode}
                onNewItemPress={handleNewUserPress}
                hasEditAndDelete={!isAdminModifyMode}
                onEditPress={handleUserEdit}
                onDeletePress={handleUserDelete}
                flexArray={[5, 5, 5, 1]}
              />
            </IM.View>
          </ScrollView>
          <UserCreateModal controller={createUserModal} onCreateUser={onNewUserCreated} organizationId={selectedOrganizationId} />
          <LicenseCreateModal controller={createLicenseModal} onCreateLicense={onNewLicenseCreated} organizationId={selectedOrganizationId} />
          <OrganizationCreateModal controller={createOrganizationModal} onCreateOrganization={onCreateOrganization} />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  loadingContainer: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  infoTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
