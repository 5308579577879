import isEqual from 'lodash/isEqual'

type Keys<T> = keyof T | (keyof T)[]

function keysToKeyArray<T>(keys: Keys<T>) {
  return Array.isArray(keys) ? keys : [keys]
}

export const objectUtils = {
  compareItemsWithKeys<T extends object>(itemA: Partial<T> | undefined | null, itemB: Partial<T> | undefined | null, keys: Keys<T>) {
    if (!itemA || !itemB || !keys) return false
    const keysArray = keysToKeyArray(keys)
    for (const key of keysArray) {
      if (itemA[key] !== itemB[key]) return false
    }
    return true
  },
  /**
   * Property is valid if it has any value and is not an empty string
   */
  validateProperty(value: unknown) {
    return value !== undefined && value !== null && (typeof value !== 'string' || !!value.trim().length)
  },
  /**
   * Checks if all required fields in item have a value
   */
  validateItemRequiredFields<T extends object>(item: T | undefined, requiredProperties: undefined | Keys<T>[]) {
    if (!item) return false
    if (!requiredProperties?.length) return true

    for (const prop of requiredProperties) {
      if (!prop) continue
      if (Array.isArray(prop)) {
        const foundAny = prop.find(key => this.validateProperty(item[key]))
        if (!foundAny) return false
        continue
      }

      if (!this.validateProperty(item[prop])) return false
    }
    return true
  },
  /**
   * Filles missing indicated property values in item with values from other indicated properties
   */
  crossFill<T extends object>(item: T, ...keys: (keyof T)[]) {
    if (!item || !keys.length) return item
    let foundKeyWithValue: keyof T | undefined
    for (const key of keys) {
      if (item[key]) continue
      if (!foundKeyWithValue) foundKeyWithValue = keys.filter(otherKey => otherKey !== key).find(otherKey => !!item[otherKey])
      if (!foundKeyWithValue) return item
      Object.assign(item, { [key]: item[foundKeyWithValue] })
    }
    return item
  },
  crossFillMulti<T extends object>(item: T, ...keyList: (keyof T)[][]) {
    if (!item || !keyList.length) return item
    for (const keys of keyList) {
      item = this.crossFill(item, ...keys)
    }
    return item
  },
  createUniqueIdFromKeys<T extends object>(item: T | undefined | null, keys: Keys<T>) {
    if (!item || !keys) return ''
    const keysArray = keysToKeyArray(keys)
    return keysArray.map(key => item[key]?.toString() ?? '').join('#')
  },
  createRequestObject<T extends object, TResult = Partial<T>>(item: T, keys: keyof T | (keyof T)[]) {
    if (!Array.isArray(keys)) return { [keys]: item[keys] } as TResult
    return keys.reduce((result, key) => Object.assign(result, { [key]: item[key] }), {}) as TResult
  },
  compareObjects<T extends object>(a: T | undefined | null, b: T | undefined | null) {
    return isEqual(a, b)
  },
  /**
   * Compares the 2 objects and returns the differences
   * @param a object A
   * @param b object B
   * @param compareMode compare mode 'soft' will apply a truthy for comparison. F.ex. an empty string and undefined will not be treated as a difference in soft compare mode
   * @returns
   */
  getDifferenceBetweenObjects(a: object | undefined | null, b: object | undefined | null, compareMode: 'hard' | 'soft' = 'hard') {
    const keys = new Set<string>()
    if (a) Object.keys(a).forEach(key => keys.add(key))
    if (b) Object.keys(b).forEach(key => keys.add(key))

    const differences: { key: string; valueA: unknown; valueB: unknown }[] = []
    const validateValue = (value: unknown) => value !== undefined && value !== null && value !== ''
    keys.forEach(key => {
      const valueA = !!a && key in a ? a[key as keyof typeof a] : undefined
      const valueB = !!b && key in b ? b[key as keyof typeof b] : undefined
      if (valueA !== valueB && (compareMode === 'hard' || !!validateValue(valueA) || !!validateValue(valueB))) {
        differences.push({ key, valueA, valueB })
      }
    })
    return differences
  },
}
