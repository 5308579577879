import { useContext } from 'react'

import DrawerContext from '../DrawerContext'

export default function useDrawer() {
  const context = useContext(DrawerContext)

  if (context === undefined) {
    throw new Error('useDrawer() must be called inside DrawerProvider')
  }

  return context
}
