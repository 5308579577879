import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { Linking, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

type Props = {
  title: string
  value: string
  type?: 'text' | 'link' | 'email' | 'phone'
  style?: StyleProp<ViewStyle>
}

export default function InfoRow({ title, value, type = 'text', style }: Props) {
  const isUnderlined = type === 'link' || type === 'email'
  const pressable = type !== 'text'

  function handleOnPress() {
    const isWeb = Platform.OS === 'web'

    switch (type) {
      case 'link':
        if (isWeb) {
          window.open(value, '_blank')
        } else {
          Linking.openURL(value).catch(err => console.error('Error while opening link:', err))
        }
        break
      case 'email':
        if (isWeb) {
          window.location.href = `mailto:${value}`
        } else {
          Linking.openURL(`mailto:${value}`).catch(err => console.error('Error while opening mailto:', err))
        }
        break
      case 'phone':
        if (isWeb) {
          window.location.href = `tel:${value}`
        } else {
          Linking.openURL(`tel:${value}`).catch(err => console.error('Error while opening tel:', err))
        }
        break
    }
  }

  return (
    <IM.View style={[style, styles.row]} spacing="horizontal">
      <IM.Text style={[IMLayout.flex.f1, styles.text]}>{title}</IM.Text>
      <IM.Text style={[IMLayout.flex.f1, styles.value, isUnderlined && styles.underline]} onPress={pressable ? handleOnPress : undefined} selectable>
        {value}
      </IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 25,
  },
  text: {
    marginRight: 12,
    flexShrink: 1,
  },
  value: {
    fontWeight: 'bold',
  },
  underline: {
    textDecorationLine: 'underline',
  },
})
