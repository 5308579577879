import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { FlatList, PanResponder, StyleSheet, View } from 'react-native'

import { useData } from '../contexts/DataContext'
import { Package } from '../types/types'
import PackageSmallCard from './cards/PackageSmallCard'

const PackageGrid = () => {
  const { packages } = useData()
  const navigation = useNavigation()
  const [currentPage, setCurrentPage] = useState(0)
  const [openPackages, setOpenPackages] = useState<Package[]>([])
  const [pages, setPages] = useState<Package[][]>([])

  const ITEMS_PER_PAGE = 9

  useEffect(() => {
    const filteredPackages = packages.filter(p => p.closed === false)
    setOpenPackages(filteredPackages)

    const newPages: Package[][] = []
    for (let i = 0; i < filteredPackages.length; i += ITEMS_PER_PAGE) {
      newPages.push(filteredPackages.slice(i, i + ITEMS_PER_PAGE))
    }

    setPages(newPages)
  }, [])

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderRelease: (e, gestureState) => {
      const { dx } = gestureState

      if (dx > 50) {
        onPreviousPage()
      } else if (dx < -50) {
        onNextPage()
      }
    },
  })

  const onNextPage = () => {
    setCurrentPage(prev => {
      if (prev < pages.length - 1) {
        return prev + 1
      }
      return prev
    })
  }

  const onPreviousPage = () => {
    setCurrentPage(prev => {
      if (prev > 0) {
        return prev - 1
      }
      return prev
    })
  }

  const handleCardPress = (pkg: Package) => {
    navigation.navigate('PackagesMeasure', { packageTmpId: pkg.tmpId })
  }

  return (
    <View style={styles.gridContainer} {...panResponder.panHandlers}>
      <FlatList
        data={pages[currentPage]}
        numColumns={3}
        keyExtractor={item => item.tmpId.toString()}
        renderItem={({ item }) => <PackageSmallCard pkg={item} style={styles.card} onPress={() => handleCardPress(item)} />}
        contentContainerStyle={styles.gridContent}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  gridContainer: {
    backgroundColor: '#333',
    padding: 10,
    height: 330,
  },
  gridContent: {
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  card: {
    flexBasis: '30%',
    margin: '1%',
    aspectRatio: 1,
  },
})

export default PackageGrid
