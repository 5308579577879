import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { Dispatch, memo, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Animated, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import AnimatedButton from '../components/AnimatedButton'
import ListSpacer from '../components/ListSpacer'
import NoSelectionCard from '../components/NoSelectionCard'
import PressableIcon from '../components/PressableIcon'
import { SearchProvider } from '../components/screen/contexts/SearchContext'
import SectionList from '../components/SectionList'
import SkeletonText from '../components/skeleton/SkeletonText'
import TextInput from '../components/TextInput'
import { ADD_CUSTOMER_BUTTON_ID } from '../constants/ButtonIds'
import CONSTANTS from '../constants/constants'
import { InfiniteLoadingType, ThemeColorExpanded } from '../types'
import { FiniteLoadList, InfiniteLoadList, ListSection } from '../types/types'
import SelectModal from './SelectModal'

interface Props {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const buttonAnimationValue = new Animated.Value(0)
export type ScreenSelectModalProps<T> = (FiniteLoadList | InfiniteLoadList) & {
  noDataMessage: string
  loading: InfiniteLoadingType
  focusSearch?: boolean
  screenTitle: string
  deferredTimeout?: number | undefined
  extraIcon?: ReactNode
  data: T[]
  disableLoadAfterMount?: boolean
  showNoSelectionItem?: boolean | React.ReactElement
  selected?: T | undefined | null
  refresh?: () => void
  renderItem: (renderItem: SectionListRenderItemInfo<T, ListSection<T>>, onPress?: () => void) => JSX.Element
  onChange: (value: T | undefined) => void
  onClose?: () => void
  onSearchChange?: (searchText: string) => void
  keepOpenOnChange?: boolean
  onSave?: (inputValue: string) => void
  onSaveWood?: (inputValue: string, reduction?: number) => void
  showReduction?: boolean
  searchField?: string
}

function ScreenSelectModal<T>({
  data,
  isVisible,
  noDataMessage,
  focusSearch,
  deferredTimeout,
  extraIcon,
  loading,
  disableLoadAfterMount = false,
  screenTitle,
  selected,
  keepOpenOnChange,
  showReduction,
  onSave,
  onSaveWood,
  searchField,
  ...others
}: ScreenSelectModalProps<T> & Props) {
  const { onSearchChange, onChange, onClose, refresh, renderItem, setIsVisible, showNoSelectionItem } = others
  const { colorScheme, theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const [isCreate, setIsCreate] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [reduction, setReduction] = useState('')
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    !disableLoadAfterMount && isVisible && refresh?.()
    !isVisible && onClose?.()
  }, [isVisible])

  const render = (renderObj: SectionListRenderItemInfo<T, ListSection<T>>) => {
    return renderItem(renderObj, () => {
      onChange(renderObj.item)
      if (!keepOpenOnChange) setIsVisible(false)
    })
  }

  const filteredData = useMemo(() => {
    if (!searchText || !searchField) {
      return data
    }
    return data.filter((item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const fieldValue = item[searchField]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return fieldValue && fieldValue.toString().toLowerCase().includes(searchText.toLowerCase())
    })
  }, [data, searchText, searchField])

  const sections: ListSection<T>[] = useMemo(() => {
    const displayData: ListSection<T>[] = []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        data: data,
      })
    }

    return displayData
  }, [data, loading])

  const ListHeaderComponent = useMemo(() => {
    if (!showNoSelectionItem || !filteredData.length) return <ListSpacer />
    if (showNoSelectionItem === true) {
      return (
        <>
          <ListSpacer />
          <NoSelectionCard
            onPress={() => {
              onChange(undefined)
              setIsVisible(false)
            }}
            selected={!selected}
            spacing={['horizontal', 'bottom']}
          />
        </>
      )
    }

    return showNoSelectionItem
  }, [showNoSelectionItem, selected, filteredData])

  const checkIcon = <PressableIcon icon={['fal', 'check']} size={21} onPress={() => setIsVisible(false)} color={IMStyle.palette.white} />

  const handleSave = () => {
    if (!inputValue) return
    if (showReduction) {
      let numberReduction = Number(reduction)
      if (numberReduction) {
        numberReduction = Number(numberReduction.toFixed(0))
      }
      onSaveWood?.(inputValue, numberReduction)
    } else {
      onSave?.(inputValue)
    }
    setInputValue('')
    setIsCreate(false)
  }

  return (
    <SearchProvider>
      <SelectModal
        focusSearch={focusSearch}
        isVisible={isVisible}
        close={() => setIsVisible(false)}
        title={screenTitle}
        deferredTimeout={deferredTimeout}
        extraIcon={extraIcon ?? keepOpenOnChange ? checkIcon : undefined}
        hideGoBackIcon={keepOpenOnChange}
        onSearchChange={onSearchChange}>
        {searchField && (
          <TextInput
            placeholder={i18n.t('SEARCH')}
            value={searchText}
            onChangeText={text => {
              setSearchText(text)
              onSearchChange?.(text)
            }}
            spacing={'all'}
            style={styles.searchInput}
          />
        )}

        {!isCreate && (
          <>
            <SectionList
              {...others}
              sections={sections}
              loading={loading}
              renderItem={render}
              noDataMessage={noDataMessage}
              skeletonElements={CONSTANTS.skeletonCards}
              skeletonComponent={<SkeletonText height={20} width="100%" spacing="bottom" />}
              skeletonTopSpacing
              onRefresh={refresh}
              contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 2 * IMLayout.verticalMargin }}
              ListHeaderComponent={ListHeaderComponent}
              indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
            />
            <AnimatedButton
              style={styles.button}
              id={ADD_CUSTOMER_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'plus']}
              onPress={() => setIsCreate(true)}
            />
          </>
        )}
        {isCreate && (
          <>
            <TextInput placeholder={i18n.t('NAME')} value={inputValue} onChangeText={setInputValue} spacing={'all'} />
            {showReduction && <TextInput placeholder={i18n.t('REDUCTION')} value={reduction} onChangeText={setReduction} spacing={'all'} />}
            <IM.Button title={i18n.t('SAVE')} style={{ backgroundColor: theme.primary }} onPress={handleSave} />
          </>
        )}
      </SelectModal>
    </SearchProvider>
  )
}

const styles = StyleSheet.create({
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  searchInput: {
    marginHorizontal: IMLayout.horizontalMargin,
    marginBottom: IMLayout.verticalMargin,
  },
})

export default memo(ScreenSelectModal) as typeof ScreenSelectModal
