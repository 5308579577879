import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { RefreshControlProps, RefreshControl as RNRefreshControl, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'

export default function RefreshControl(props: RefreshControlProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <RNRefreshControl
      tintColor={theme.text}
      titleColor={theme.text}
      style={styles.elem}
      progressBackgroundColor={theme.background}
      colors={theme.randomColors}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  elem: {
    backgroundColor: 'transparent',
    zIndex: 100,
  },
})
