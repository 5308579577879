import { NavigationProp, NavigationState, ParamListBase, PartialRoute, PartialState, Route } from '@react-navigation/native'
import lodash from 'lodash'
import { Platform } from 'react-native'

import { RootStackParamList, TabNavigationScreen } from '../navigation/types'

type NavigationRoute<ParamList extends ParamListBase, RouteName extends keyof ParamList> = Route<Extract<RouteName, string>, ParamList[RouteName]> & {
  state?: NavigationState | PartialState<NavigationState>
}

type NavigationUtilsType = {
  sortTabs: <T extends string>(
    tabs: TabNavigationScreen<T>[],
    maxElements: number,
    method?: 'navigation' | 'allWithoutFixed'
  ) => TabNavigationScreen<T>[]
  resetIndexBasedOnOrder: <T extends string>(tabs: TabNavigationScreen<T>[]) => TabNavigationScreen<T>[]
  navigationBack: (
    navigation: NavigationProp<ReactNavigation.RootParamList, keyof ReactNavigation.RootParamList>,
    isSmallDevice: boolean,
    onGoBackNavigationOverride?: () => void
  ) => void
  findStack: (state: NavigationState, key: string) => string | undefined
  checkRoutes: (
    routes: NavigationRoute<ParamListBase, string>[] | PartialRoute<Route<string, object | undefined>>[] | undefined,
    key: string
  ) => boolean
}

const navigationUtils: NavigationUtilsType = {
  sortTabs: <T extends string>(tabs: TabNavigationScreen<T>[], maxElements: number, method = 'navigation') => {
    const clone = lodash.cloneDeep(tabs)
    const filtered = clone.filter(n => n)

    filtered.sort((a, b) => {
      if (a.id - b.id === 0) {
        console.error(`Find more that one tab with id ${a.id}`)
      }

      return a.id - b.id
    })

    const res = lodash.partition(filtered, elem => elem.isFixed)
    const fixedElements = res[0]
    const notFixedElements = res[1]
    const shownElements = notFixedElements.slice(0, maxElements - 1)
    const hiddenElements = notFixedElements.slice(maxElements - 1, notFixedElements.length)

    switch (method) {
      case 'navigation': {
        const toRet = shownElements.concat(fixedElements).concat(hiddenElements)
        return navigationUtils.resetIndexBasedOnOrder(toRet)
      }
      case 'allWithoutFixed': {
        return shownElements.concat(hiddenElements)
      }
      default:
        return clone
    }
  },
  resetIndexBasedOnOrder: <T extends string>(tabs: TabNavigationScreen<T>[]) => {
    const clone = lodash.cloneDeep(tabs)
    clone.forEach((elem, index) => (elem.id = index))
    return clone
  },
  navigationBack(
    navigation: NavigationProp<ReactNavigation.RootParamList, keyof ReactNavigation.RootParamList>,
    isSmallDevice: boolean,
    onTabletGoBack?: () => void
  ) {
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      if (navigation.canGoBack()) navigation.goBack()
      else navigation.reset({ index: 0, routes: [{ name: 'BottomTab' }] })
    } else {
      if (onTabletGoBack) {
        onTabletGoBack()
      } else {
        if (navigation.canGoBack()) navigation.goBack()
        else navigation.reset({ index: 0, routes: [{ name: 'BottomTab' }] })
      }
    }
  },
  findStack(state: NavigationState, key: string) {
    let stackNameFound: string | undefined

    const bottomTabName: keyof RootStackParamList = 'BottomTab'
    const bottomTabStack = state.routes.find(el => el.name === bottomTabName)

    if (bottomTabStack) {
      bottomTabStack.state?.routes.forEach(route => {
        const firstLevelStackName = route.name

        if (route.state && navigationUtils.checkRoutes(route.state?.routes, key)) {
          stackNameFound = firstLevelStackName
        }
      })
    }

    return stackNameFound
  },
  checkRoutes(routes: NavigationRoute<ParamListBase, string>[] | PartialRoute<Route<string, object | undefined>>[] | undefined, key: string) {
    let toRet = false

    routes?.forEach(route => {
      if (!toRet) {
        if (route.key === key) {
          toRet = true
        }

        const state = route.state
        if (!toRet && state) {
          toRet = navigationUtils.checkRoutes(state.routes, key)
        }
      }
    })

    return toRet
  },
}

export default navigationUtils
