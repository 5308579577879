import { IM, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import PackageCreateModal, { PackageCreateModalControllerData } from '../../modals/PackageCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Board, Package } from '../../types/types'

export interface PackagesTableData {
  packageId: number
  packageName: string
  woodName: string
  qualityName: string
  customerName: string
  typologyName: string
  measurerName: string
  sawmillName: string
  gradeName: string
  addDate: string
  boards: Board[]
}

export default function PackagesView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const navigation = useNavigation()

  const [busy, setBusy] = useState(false)
  const [packages, setPackages] = useState<Package[]>([])
  const [fromDate, setFromDate] = useState<Date>(() => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  })
  const [toDate, setToDate] = useState<Date>(new Date())
  const deleteAlert = useDeleteAlert()
  const { alert } = useAlert()
  const createPackageModal = useModalController<PackageCreateModalControllerData>()

  const packagesTableHeader: TableHeaderData<PackagesTableData>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'packageName' },
      { title: i18n.t('DATE'), property: 'addDate' },
      { title: i18n.t('CUSTOMER'), property: 'customerName' },
      { title: i18n.t('WOOD'), property: 'woodName' },
      { title: i18n.t('QUALITY'), property: 'qualityName' },
      { title: i18n.t('GRADE'), property: 'gradeName' },
      { title: i18n.t('TYPOLOGY'), property: 'typologyName' },
      { title: i18n.t('MEASURER'), property: 'measurerName' },
      { title: i18n.t('SAWMILL'), property: 'sawmillName' },
    ],
    [i18n]
  )

  const boardsTableHeader: TableHeaderData<Board>[] = useMemo(() => [], [i18n])

  useEffect(() => {
    fetchPackages()
  }, [])

  useEffect(() => {
    fetchPackages()
  }, [fromDate, toDate])

  const packagesTableData: PackagesTableData[] = useMemo(() => {
    return packages.map(pkg => ({
      packageId: pkg.packageId,
      packageName: pkg.packageName ?? '',
      gradeName: pkg.grade?.gradeName ?? '',
      woodName: pkg.wood?.woodName ?? '',
      qualityName: pkg.quality?.qualityName ?? '',
      customerName: pkg.customer?.customerName ?? '',
      typologyName: pkg.typology?.typologyName ?? '',
      measurerName: pkg.measurer?.measurerName ?? '',
      sawmillName: pkg.sawmill?.sawmillName ?? '',
      boards: pkg.boards,
      addDate: pkg.addDate
        ? new Date(pkg.addDate).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
    }))
  }, [packages, language])

  function fetchPackages() {
    setBusy(true)
    api
      .getPackagesFromDates({ startDate: fromDate, endDate: toDate })
      .then(response => {
        setPackages(response)
      })
      .catch(console.error)
      .finally(() => setBusy(false))
  }

  function onNewPackageCreated() {
    createPackageModal.close()
    fetchPackages()
  }

  function handleNewPackagePress() {
    createPackageModal.show({})
  }

  function handlePackageEdit(pkg: PackagesTableData) {
    createPackageModal.show({ editPackageId: pkg.packageId })
  }

  function handlePackageDelete(pkg: PackagesTableData) {
    const selectedPackage = packages.find(p => p.packageId === pkg.packageId)
    if (!selectedPackage?.packageId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deletePackage({ packageId: selectedPackage.packageId })
            .then(() => {
              fetchPackages()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  return (
    <>
      {busy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <IM.View style={styles.datePickerRow}>
            <IM.Text>{i18n.t('FROM_DATE')}:</IM.Text>
            <DateInput
              value={fromDate.toDateString()}
              onChangeDate={date => date && setFromDate(date)}
              minimumDate={new Date(1899, 0, 1)}
              maximumDate={toDate}
              showCalendarButton={true}
            />

            <IM.Text style={{ marginLeft: 10 }}>{i18n.t('TO_DATE')}:</IM.Text>
            <DateInput
              value={toDate.toDateString()}
              onChangeDate={date => date && setToDate(date)}
              minimumDate={fromDate}
              maximumDate={new Date(2199, 0, 1)}
              showCalendarButton={true}
            />
          </IM.View>

          <TableWrapper
            tableContainerSpacing={'all'}
            headerData={packagesTableHeader}
            tableData={packagesTableData}
            subTableHeaderData={boardsTableHeader}
            subTableDataKey="boards"
            hasPaging
            maxTableHeight={730}
            showNewItemRow
            onNewItemPress={handleNewPackagePress}
            hasEditAndDelete
            onEditPress={handlePackageEdit}
            onDeletePress={handlePackageDelete}
            flexArray={[1, 1, 1, 1, 1, 1]}
          />
        </IM.View>
      </ScrollView>
      <PackageCreateModal controller={createPackageModal} onSaveCallback={onNewPackageCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  datePickerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: -20,
    marginLeft: 5,
    marginTop: 5,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
