import { IM, IMStyle } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, TextStyle } from 'react-native'

export interface DefaultBaseTextInputTitleProps {
  title?: string
  details?: string
  fontWeight?: TextStyle['fontWeight']
  required?: boolean
  style?: StyleProp<TextStyle>
}

const DefaultBaseTextInputTitle = memo(function DefaultBaseTextInputTitle({
  title,
  details,
  fontWeight,
  required,
  style,
}: DefaultBaseTextInputTitleProps) {
  return (
    <>
      <IM.Text style={[{ fontWeight: fontWeight ?? IMStyle.typography.fontWeightMedium }, style]}>{`${title ?? ''}${required ? ' *' : ''}`}</IM.Text>
      {details && <IM.Text secondary>{details}</IM.Text>}
    </>
  )
})

export default DefaultBaseTextInputTitle
