import { ModalController, useDimensions } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ModalScreenRef } from '../types'
import Modal from './Modal'
import ModalContentWrapper from './ModalContentWrapper'
import { ModalProps } from './type'

type Props<T> = PropsWithChildren & {
  childRef?: React.RefObject<ModalScreenRef>
  controller: ModalController<T>
  style?: StyleProp<ViewStyle>
} & Pick<ModalProps, 'onModalShow' | 'fullWidth'>

export default function BaseServiceModal<T>({ children, controller, childRef, style, ...props }: Props<T>) {
  const { isSmallDevice } = useDimensions()

  const handleClose = () => {
    if (childRef) return childRef.current?.handleGoBack()

    controller.close()
  }

  return (
    <Modal visible={controller.isShown} onClose={handleClose} style={style} backdropOpacity={isSmallDevice ? 0 : undefined} {...props}>
      <ModalContentWrapper>{children}</ModalContentWrapper>
    </Modal>
  )
}
