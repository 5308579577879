import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import SawmillCreateScreen from '../screens/SawmillCreate/SawmillCreateScreen'
import { SawmillCreateViewProps } from '../screens/SawmillCreate/SawmillCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type SawmillCreateModalProps = {
  controller: ModalController<SawmillCreateViewProps>
} & Pick<SawmillCreateViewProps, 'onCreateSawmill'>

export default function SawmillCreateModal({ controller, onCreateSawmill }: SawmillCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <SawmillCreateScreen ref={ref} onCreateSawmill={onCreateSawmill} controller={controller} />
    </BaseServiceModal>
  )
}
