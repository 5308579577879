import { CardProps, IM } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { Package } from '../../types/types'

export type PackageSmallCardProps = { pkg: Package; onPress?: () => void } & CardProps

export default function PackageSmallCard({ pkg, onPress, ...cardProps }: PackageSmallCardProps) {
  const [maxLength, setMaxLength] = useState(0)

  useEffect(() => {
    if (pkg) {
      const length = pkg.boards.reduce((max, board) => {
        return board.boardLength > max ? board.boardLength : max
      }, 0)
      setMaxLength(length)
    }
  }, [pkg])

  return (
    <IM.Card {...cardProps} style={styles.card}>
      <TouchableOpacity onPress={onPress} style={styles.card}>
        <IM.View style={styles.packageNameContainer}>
          <IM.Text style={styles.packageName}>{pkg.name}</IM.Text>
          <IM.Text style={styles.packageLength}>{maxLength.toString()}</IM.Text>
        </IM.View>
      </TouchableOpacity>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  card: {
    margin: 5,
    width: 100,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
  },
  packageNameContainer: {
    flexDirection: 'column',
  },
  packageName: {
    fontWeight: 'bold',
  },
  packageLength: {
    marginTop: 4,
    textAlign: 'center',
  },
})
