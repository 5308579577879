import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import TABLE_CONSTANTS from '../../constants/TableConstants'
import TableUtils from '../../utils/TableUtils'

export interface EmptyRowProps {
  style?: StyleProp<ViewStyle>
  height?: number
  widthArray?: number[]
  flexArray?: number[]
  dontShowNoDataText?: boolean
}

export default function EmptyRow({ style, height, widthArray, flexArray, dontShowNoDataText }: EmptyRowProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'
  const tableFlex = flexArray ? TableUtils().sum(flexArray) : 1
  const rowHeight = height ?? (dontShowNoDataText ? TABLE_CONSTANTS.defaultRowNoPaddingHeight : 'auto')

  return (
    <IM.View
      style={[
        IMLayout.flex.f1,
        { backgroundColor: theme.table.rowEven, width: tableWidth, flex: tableFlex, height: rowHeight },
        styles.emptyRow,
        style,
      ]}>
      {dontShowNoDataText ? (
        <IM.View style={{ height: rowHeight }} />
      ) : (
        <IM.Text style={{ color: theme.table.text }}>{i18n.t('NO_TABLE_DATA')}</IM.Text>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  emptyRow: { justifyContent: 'center', alignItems: 'center', padding: 10 },
})
