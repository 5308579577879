import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Customer } from '../../apis/apiResponseTypes'

export type CustomerCardProps = { customer: Customer } & CardProps

export default function CustomerCard({ customer, ...cardProps }: CustomerCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{customer.customerName}</IM.Text>
    </IM.Card>
  )
}
