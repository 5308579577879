import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeIconStyle } from '@fortawesome/react-native-fontawesome'
import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'

export interface IconProps {
  icon: IconProp
  size?: number
  color?: string
  iconStyle?: FontAwesomeIconStyle
  style?: StyleProp<ViewStyle>
  spacing?: SpacingProps
}

export default function Icon({ color, size, icon, iconStyle, style, spacing }: IconProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={style} spacing={spacing}>
      <FontAwesomeIcon color={color ? color : theme.text} size={size} icon={icon} style={[styles.icon, iconStyle]} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  icon: {
    // @ts-ignore this is needed for web
    outlineStyle: 'none', // web
  },
})
