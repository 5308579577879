import { useContext } from 'react'

import MenuContext, { MenuContextProps } from '../contexts/MenuContext'
import { TabNavigationScreen } from '../navigation/types'

export default function useMenu<T extends string>(): MenuContextProps<T> {
  const context = useContext(MenuContext)

  if (context === undefined) {
    throw new Error('useMenu() must be called inside MenuProvider')
  }

  return {
    ...context,
    tabs: context.tabs as TabNavigationScreen<T>[],
  }
}
