import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Sawmill } from '../../apis/apiResponseTypes' // Assuming Sawmill type is defined

export type SawmillCardProps = { sawmill: Sawmill } & CardProps

export default function SawmillCard({ sawmill, ...cardProps }: SawmillCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{sawmill.sawmillName}</IM.Text>
    </IM.Card>
  )
}
