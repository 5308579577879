import { useContext } from 'react'

import SearchContext from '../contexts/SearchContext'

export default function useSearch() {
  const context = useContext(SearchContext)

  if (context === undefined) {
    throw new Error('useSearch() must be called inside SearchProvider')
  }

  return context
}
