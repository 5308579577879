import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostLicenseRequest } from '../../apis/apiRequestTypes'
import { OrganizationLicense } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import DateInput from '../../components/DateInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import LicenseTypeSelector from '../../components/selectors/LicenseTypeSelector'
import TextInput from '../../components/TextInput'
import { CREATE_USER_BUTTON_ID } from '../../constants/ButtonIds'
import { useData } from '../../contexts/DataContext'
import { ThemeColorExpanded, UploadStatus } from '../../types'
import OrganizationUtils from '../../utils/organizationUtils'
import TimeUtils from '../../utils/TimeUtils'

const buttonAnimationValue = new Animated.Value(0)

export interface LicenseCreateViewProps {
  organizationId?: number
  editId?: string
  onCreateLicense?: (license: OrganizationLicense) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateLicenseField = 'licenseType' | 'expirationDate'
type RequiredField = { value: string | undefined; field: ValidateLicenseField }

const LicenseCreateView: React.FC<LicenseCreateViewProps & Props> = ({ editId, onCreateLicense, onUploadStatus, organizationId }) => {
  const { i18n, language } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const { currentOrganization } = useData()
  const [license, setLicense] = useState<Partial<PostLicenseRequest>>({})
  const [missingFields, setMissingFields] = useState<ValidateLicenseField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostLicenseRequest>>({})
  const isEditMode = !!editId

  useEffect(() => {
    if (editId) {
      loadEditLicense().catch(console.error)
    }
  }, [editId])

  async function loadEditLicense() {
    setIsLoading(true)
    await api
      .getLicense({ licenseId: Number(editId) })
      .then(response => {
        const editLicense: PostLicenseRequest = {
          licenseKey: response.licenseKey,
          licenseType: OrganizationUtils.convertStringToLicenseType(response.licenseType),
          expirationDate: new Date(response.expirationDate),
          activationDate: new Date(response.activationDate),
          deviceId: response.deviceId,
          organizationId: response.organizationId,
        }
        setLicense(editLicense)
        setInitialValue(editLicense)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostLicenseRequest>) {
    setLicense(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = []
    if (!isEditMode) {
      requiredFields.push({ value: license.licenseType, field: 'licenseType' })
    } else {
      requiredFields.push({ value: license.expirationDate ? TimeUtils.format(license.expirationDate, language) : undefined, field: 'expirationDate' })
    }

    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function haveValuesChanged() {
    return JSON.stringify(license) !== JSON.stringify(initialValue)
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    validateForm()
  }, [license, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const licenseBody: PostLicenseRequest = {
      licenseKey: license.licenseKey ?? 'NEW',
      licenseType: license.licenseType ?? 'PackageAndLot',
      expirationDate: license.expirationDate ?? new Date(),
      activationDate: license.activationDate ?? new Date(),
      deviceId: license.deviceId ?? '',
      organizationId: license.organizationId ?? organizationId ?? currentOrganization?.organizationId ?? 0,
    }

    //UPDATE USER
    if (editId) {
      await api
        .putLicense({
          licenseId: Number(editId),
          ...licenseBody,
        })
        .then(response => {
          //TODO: fix edit
          console.log('edit', response)
          //onCreateLicense?.(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('LICENSE_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    }

    //CREATE LICENSE
    await api
      .postLicense(licenseBody)
      .then(response => {
        onCreateLicense?.(response)
        onUploadStatus('done')
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('LICENSE_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            {isEditMode && (
              <TextInput
                title={i18n.t('LICENSE_KEY')}
                placeholder={i18n.t('LICENSE_KEY')}
                value={license.licenseKey ?? ''}
                onChangeText={_value => {
                  //nothing
                }}
                spacing={'all'}
                editable={false}
              />
            )}
            <LicenseTypeSelector
              required={!isEditMode}
              editable={!isEditMode}
              spacing={'all'}
              onChange={value => handleChange({ licenseType: OrganizationUtils.convertLicenseSelectorToType(value) })}
              selectedLicenseType={OrganizationUtils.convertLicenseTypeToSelector(license.licenseType)}
            />
            {isEditMode && (
              <>
                <DateInput
                  containerStyle={IMLayout.flex.f1}
                  title={i18n.t('ACTIVATION_DATE')}
                  value={license.activationDate?.toISOString()}
                  onChangeDate={_value => {
                    //nothing
                  }}
                  showCalendarButton
                  spacing={'all'}
                  editable={false}
                />
                <DateInput
                  required={isEditMode}
                  containerStyle={IMLayout.flex.f1}
                  title={i18n.t('EXPIRATION_DATE')}
                  value={license.expirationDate?.toISOString()}
                  onChangeDate={value => handleChange({ expirationDate: value })}
                  showCalendarButton
                  spacing={'all'}
                  error={isEditMode && missingFields.includes('expirationDate')}
                  editable={isEditMode}
                />
              </>
            )}
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_USER_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default LicenseCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
