import { useAlert, useLanguage } from '@infominds/react-native-components'

export default function useDeleteErrorAlert(title?: string, description?: string) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  function show() {
    alert.alert(title ?? i18n.t('ERROR'), description ?? i18n.t('ERROR_DELETE_LINE'), [
      {
        text: i18n.t('OK'),
        onPress: () => {
          return
        },
      },
    ])
  }

  return {
    show,
  }
}
