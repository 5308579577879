import { addYears, startOfToday } from 'date-fns'

import { AuthConfig } from '../types/types'

export const RETRY_TIMES = 2 // Keep in mind this is used in an exponential function

export const DEFAULT_CONFIG: Partial<AuthConfig> = {
  isSinglePageApp: false,
  keepLicenseOnLogout: true,
  disableOfflineMode: false,
  disableSilentLogin: false,
  aad: false,
  disableInAppUpdateCheck: false,
  cacheInvalidation: { days: 7 },
  debug: false,
}

export const CACHE_UPDATED_AT_EPOCH = 0
export const DEFAULT_ORGANIZATION_CALIBRATION = -115

const CONSTANTS = {
  toastVisibilityTime: 5000,
  appName: 'IlCubatore',
  maxLastUsedCustomers: 3,
  maxLastUsedCustomersTablet: 6,
  skeletonCards: 30,
  customersChunkSize: 14,
  defaultChuckSize: 25,
  activityChuckSize: 10,
  destinationsChuckSize: 25,
  maxNavBarElements: 4,
  maxTabletNavBarElements: 6,
  landscapeTabletMaxSearchBar: 250,
  portraitTabletMaxSearchBar: 130,
  landscapeTabletMaxFilterBar: 500,
  portraitTabletMaxFilterBar: 350,
  maxPhoneNumbers: 13,
  cardLeftWidth: 6,
  secondButtonBottom: 64,
  searchDeferredTimeout: 400,
  signatureTimeout: 700,
  verticalTextMinHeight: 70,
  margin: 6,
  disabledOpacityLight: 0.5,
  disabledOpacityDark: 0.38,
  maxDataLengthForFastInput: 3,
  imageSizeSmallDevice: 200,
  imageSizeLargeDevice: 200,
  imageSpacing: 4,
  activityNoteChunkSize: 3,
  mapCarouselMaxItemWidth: 600,
  today: startOfToday(),
  maxDate: addYears(new Date(), 10),
  numberOfMaxContractActivitiesForAlert: 20,
  articleNodeListIndentWidth: 20,
  noQualificationId: -1,
  modalWidth: 550,
  modalWidthWeb: 650,
  backgroundUpdateInterval: 30000,
} as const

export default CONSTANTS
