import { Utils } from '@infominds/react-native-components'
import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useRef } from 'react'
import { NativeScrollPoint, Platform, TextInput } from 'react-native'

export type FormContextInputsResult = {
  registerInput: (input: React.RefObject<TextInput>, fixedIndex?: number) => string
  removeInput: (id: string) => void
  setFocusedInput: (id: string) => void
  focusNextInput: (id: string) => void
}

export type RegisteredInput = {
  id: string
  index: number
  isFixed: boolean
  input: React.RefObject<TextInput>
}

export default function useFormContextInputs(): FormContextInputsResult {
  const inputs = useRef<RegisteredInput[]>([])
  const currentFocusedInput = useRef<string | null>(null)
  const contentOffset = useRef<NativeScrollPoint | null>(null)
  const isFocused = useIsFocused()

  useEffect(() => {
    if (Platform.OS !== 'web' || !isFocused) return
    const listener = (e: Event) => {
      if ('keyCode' in e && e.keyCode === 9 /*TAB*/ && inputs.current.length) {
        if (!currentFocusedInput.current) {
          reCalcIndexes(inputs.current, () => focusInput(inputs.current[0].id))
        } else {
          focusNextInput(currentFocusedInput.current)
        }
        e.preventDefault()
      }
    }
    window.addEventListener('keydown', listener)

    return () => window.removeEventListener('keydown', listener)
  }, [isFocused])

  function registerInput(input: React.RefObject<TextInput>, fixedIndex?: number) {
    const id = Utils.getUid()
    inputs.current.push({ id, index: fixedIndex || 0, input, isFixed: !!fixedIndex })
    return id
  }

  function removeInput(id: string) {
    inputs.current = inputs.current.filter(q => q.id !== id)
  }

  function calcIndexOf(input: RegisteredInput, onDone: () => void) {
    if (!input) {
      onDone()
      return
    }
    if (input.isFixed) {
      onDone()
      return
    }
    input.input.current?.measureInWindow((x, y) => {
      input.index = calcIndexByPos({ x, y }, contentOffset.current)
      onDone()
    })
  }

  function reCalcIndexes(inputsToReCalc: RegisteredInput[], onDone: () => void) {
    calcIndexOf(inputsToReCalc[0], () => {
      if (inputsToReCalc.length > 0) {
        reCalcIndexes(inputsToReCalc.slice(1), onDone)
      } else {
        inputs.current.sort((a, b) => a.index - b.index)
        onDone()
      }
    })
  }

  function focusNextInput(id: string) {
    reCalcIndexes(inputs.current, () => {
      const nextIndex = inputs.current.findIndex(q => q.id === id) + 1
      if (nextIndex >= inputs.current.length) return
      focusInput(inputs.current[nextIndex].id)
    })
  }

  function focusInput(id: string) {
    const inputToFocus = inputs.current.find(q => q.id === id)
    if (!inputToFocus) return
    inputToFocus.input.current?.focus()
  }

  function setFocusedInput(id: string) {
    const focusedInput = inputs.current.find(q => q.id === id)
    if (!focusedInput) return
    currentFocusedInput.current = focusedInput.id
  }

  return {
    registerInput,
    removeInput,
    setFocusedInput,
    focusNextInput,
  }
}

function calcIndexByPos(position: NativeScrollPoint, offset?: NativeScrollPoint | null) {
  const roundedX = position.x + (offset?.x ?? 0)
  // round to multiples of 10 to prevent minor differences making a difference.
  const roundedY = Math.round((position.y + (offset?.y ?? 0)) / 10) * 10

  return roundedY * 1000000 + roundedX
}
