import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import { SETTINGS_BORDER_RADIUS } from './screen/constants/constants'

// import Text from './Text'

type Props = {
  title: string
  style?: StyleProp<ViewStyle>
  spacing?: SpacingProps
}

export default function SettingsRowContainer({ title, style, spacing, children }: PropsWithChildren<Props>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  // Provide default values in case theme.settings or theme.settings.badge is undefined
  const badgeBackground = theme?.settings?.badge?.background ?? 'defaultColor'

  return (
    <IM.View style={style} spacing={spacing}>
      <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginBottom: IMLayout.verticalMargin }}>
        <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{title.toUpperCase()}</IM.Text>
      </IM.View>
      <IM.View style={{ backgroundColor: badgeBackground, borderRadius: SETTINGS_BORDER_RADIUS }} spacing="all">
        {children}
      </IM.View>
    </IM.View>
  )
}
