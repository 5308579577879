import React, { useState } from 'react'

import { Package } from '../types/types'

type PackageCreationContextType = {
  pkg: Partial<Package>
  setPkg: React.Dispatch<React.SetStateAction<Partial<Package>>>
  isFormValid: boolean
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>
}

export const PackageCreationContext = React.createContext<PackageCreationContextType | null>(null)

export function PackageCreationContextProvider({ children }: { children: React.ReactNode }) {
  const [pkg, setPkg] = React.useState<Partial<Package>>({})
  const [isFormValid, setIsFormValid] = useState(false)

  return <PackageCreationContext.Provider value={{ pkg, setPkg, isFormValid, setIsFormValid }}>{children}</PackageCreationContext.Provider>
}

export function usePackageCreation() {
  const context = React.useContext(PackageCreationContext)
  if (!context) {
    throw new Error('usePackageCreationContext must be used within a PackageCreationContextProvider')
  }
  return context
}
