import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import BaseServiceModal from '../../modals/BaseServiceModal'
import { ModalScreenRef, PackagesSettings } from '../../types'
import RoundingView from './RoundingView'

export type RoundingModalProps = {
  controller: ModalController
  packageSettings: PackagesSettings
  onPackageSettingsChange: (updatedSettings: PackagesSettings) => void
}

export default function RoundingModal({ controller, packageSettings, onPackageSettingsChange }: RoundingModalProps) {
  const ref = createRef<ModalScreenRef>()

  const handleCloseModal = (updatedSettings: PackagesSettings) => {
    onPackageSettingsChange(updatedSettings)
    controller.close()
  }

  return (
    <BaseServiceModal
      controller={controller}
      childRef={ref}
      onModalShow={() => {
        console.log('modal show')
      }}>
      <RoundingView onCloseModal={handleCloseModal} packageSettings={packageSettings} />
    </BaseServiceModal>
  )
}
