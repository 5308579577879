import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Screen } from '../../components/screen/Screen'
import BaseHeader from '../BaseHeader'
import AllOrganizationsView from './AllOrganizationsView'

export default function AllOrganizationsScreen() {
  const { i18n } = useLanguage()

  return (
    <Screen>
      <Screen.Header>
        <BaseHeader title={i18n.t('ALL_ORGANIZATIONS')} />
      </Screen.Header>
      <Screen.Content>
        <AllOrganizationsView />
      </Screen.Content>
    </Screen>
  )
}
