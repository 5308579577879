import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Origin } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import OriginCard from '../cards/OriginCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Origin | undefined) => void
  required?: boolean
  selectedOriginId?: number
}

export default function OriginSelector({ onChange, selectedOriginId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { origins, organizationId, setOrigins } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(origins, search, ['originName', 'originId', 'originCode'])

  const render = ({ item }: SectionListRenderItemInfo<Origin, ListSection<Origin>>, onPress?: () => void) => {
    return <OriginCard origin={item} onPress={onPress} selected={item.originId === selectedOriginId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Origin | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveOrigin(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveOrigin = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postOrigin({ originName: inputValue, organizationId: organizationId })
      const res = await api.getOrigins({ organizationId: organizationId })
      setOrigins(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedOrigin = useMemo(() => origins?.find(elem => elem.originId === selectedOriginId), [origins, selectedOriginId])

  return (
    <SelectInput
      id={'originId'}
      data={filteredData}
      value={selectedOrigin}
      loading={false}
      refresh={undefined}
      title={i18n.t('ORIGIN')}
      screenTitle={i18n.t('ORIGIN')}
      noDataMessage={i18n.t('NO_ORIGIN')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.originName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      searchField="originName"
      {...props}
    />
  )
}
