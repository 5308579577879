import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { useData } from '../../contexts/DataContext'
import useLayout from '../../hooks/useLayout'
import useMenu from '../../hooks/useMenu'
import { BottomTabParamList, RootStackParamList } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import navigationUtils from '../../utils/navigationUtils'
import OrganizationBadge from '../OrganizationBadge'
import Text from '../Text'
import { DrawerProvider } from './DrawerContext'
import useDrawer from './hooks/useDrawer'
import HoverView from './HooverView'
import PressableIcon from './PressableIcon'

const SETTINGS = {
  iconSize: 22,
}

const Drawer = createDrawerNavigator()
interface TabNavigatorProps {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export function DrawerNavigator<RouteName extends keyof BottomTabParamList>({ initialRouteName, screenOptions }: TabNavigatorProps) {
  const { tabs, maxDynamicElements } = useMenu<RouteName>()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { i18n } = useLanguage()

  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements])

  if (tabs.length === 0) {
    if (Platform.OS !== 'web') {
      return <IM.LoadingSpinner isVisible />
    } else {
      return <></>
    }
  }

  return (
    <DrawerProvider>
      <Drawer.Navigator
        initialRouteName={initialRouteName}
        drawerContent={TabBarProvider}
        screenOptions={{
          drawerType: 'front',
          headerShown: false,
          drawerStyle: {
            width: undefined,
            backgroundColor: theme.drawerNavigator.background,
            borderRightColor: theme.drawerNavigator.background,
          },
          ...screenOptions,
        }}>
        {sortedTabs.map(tab => (
          <Drawer.Screen
            key={`BottomTabScreen${tab.name}`}
            name={tab.name}
            component={tab.component}
            options={{
              ...(tab.options as DrawerContentComponentProps),
              title: i18n.t(tab.title),
              drawerIcon: iconProps => TabBarIcon(iconProps, tab.icon),
            }}
          />
        ))}
      </Drawer.Navigator>
    </DrawerProvider>
  )
}

export function TabBarProvider(props: DrawerContentComponentProps) {
  return <TabBar {...props} />
}

function TabBar(tabProps: DrawerContentComponentProps) {
  const { isLargeDevice } = useLayout(true)
  const { open, setOpen } = useDrawer()
  const { currentOrganization } = useData()
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation()
  const prevDeviceSize = useRef<'small' | 'large'>(isLargeDevice ? 'large' : 'small')

  useEffect(() => {
    if (!isLargeDevice && prevDeviceSize.current === 'large') {
      navigation.dispatch(DrawerActions.closeDrawer())
      prevDeviceSize.current = 'small'
    }

    if (isLargeDevice && prevDeviceSize.current === 'small') {
      prevDeviceSize.current = 'large'
    }
  }, [isLargeDevice])

  const name = useMemo(() => currentOrganization && currentOrganization.organizationName, [currentOrganization])

  return (
    <>
      <IM.View style={[styles.tabBarRoot, IMStyle.layout.shadow]}>
        <IM.View
          style={[styles.main, styles.tabBarContent, { backgroundColor: theme.drawerNavigator.bar }, (open || !isLargeDevice) && styles.tabBarOpen]}>
          <IM.View style={styles.tabContainer}>
            {currentOrganization && (
              <>
                <IM.View style={[styles.firstElement, styles.tabBarEmployeeBadge]}>
                  <OrganizationBadge
                    size={38}
                    showName={false}
                    bannerUrl={currentOrganization.bannerUrl}
                    name={name ?? ''}
                    color={name === undefined ? theme.drawerNavigator.bar : undefined}
                  />
                </IM.View>

                <IM.View style={IMStyle.layout.flex.f1}>
                  {tabProps.state.routes.map((s, index) => {
                    return (
                      <TabContent
                        key={`BottomTab${index}`}
                        routeKey={s.key}
                        index={index}
                        tabProps={tabProps}
                        end={s.name === 'SettingsStack'}
                        onlyText={false}
                        open={open}
                      />
                    )
                  })}
                </IM.View>

                {isLargeDevice && (
                  <PressableIcon
                    icon={['fal', open ? 'angles-left' : 'angles-right']}
                    size={15}
                    color="#ffffff"
                    onPress={() => {
                      const revert = !open
                      setOpen(revert)
                    }}
                  />
                )}
              </>
            )}
          </IM.View>
        </IM.View>
        {((isLargeDevice && open) || !isLargeDevice) && (
          <IM.View
            style={[
              styles.drawerOpen,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                paddingBottom: isLargeDevice ? 44 : 8,
                backgroundColor: theme.drawerNavigator.bar,
              },
            ]}>
            <IM.View style={styles.employeeContainer}>
              {currentOrganization && (
                <IM.View style={[styles.firstElement, styles.drawerEmployee]}>
                  <IM.View style={[IMLayout.flex.row]}>
                    <IM.View style={[styles.employeeName, IMLayout.flex.f1]}>
                      {!!currentOrganization.organizationName && (
                        <Text style={styles.drawerEmployeeFirst}>{currentOrganization.organizationName}</Text>
                      )}
                    </IM.View>
                    {!isLargeDevice && (
                      <PressableIcon
                        icon={['fal', 'times']}
                        color={IMStyle.palette.white}
                        onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
                        size={26}
                      />
                    )}
                  </IM.View>
                </IM.View>
              )}
            </IM.View>
            {tabProps.state.routes.map((s, index) => {
              return (
                <TabContent
                  key={`BottomTab${index}`}
                  routeKey={s.key}
                  index={index}
                  tabProps={tabProps}
                  end={s.name === 'SettingsStack'}
                  onlyText
                  open={(isLargeDevice && open) || !isLargeDevice}
                />
              )
            })}
          </IM.View>
        )}
      </IM.View>
    </>
  )
}

export function TabBarIcon(iconProps: { focused: boolean; color: string; size: number }, tabIcon?: IconProp, customIconSize?: number) {
  if (!tabIcon) return undefined
  return <IM.Icon size={customIconSize ?? iconProps.size} icon={tabIcon} color={iconProps.color} />
}

function TabContent(props: {
  routeKey: string
  index: number
  tabProps: DrawerContentComponentProps
  end: boolean
  onlyText: boolean
  open: boolean
}) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const { state, descriptors, navigation } = props.tabProps
  const route = state.routes.find(r => r.key === props.routeKey)
  const descriptor = descriptors[props.routeKey]
  const isFocused = state.index === props.index
  const label = descriptor?.options?.title || route?.name || ''

  const onPress = () => {
    const event = navigation.emit({
      type: 'drawerItemPress',
      target: props.routeKey,
      canPreventDefault: true,
    })

    const bottomTabName: keyof RootStackParamList = 'BottomTab'
    if (Platform.OS === 'web') {
      if (route?.state) {
        if (route?.state.stale) {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: bottomTabName,
                params: {
                  screen: route?.name,
                },
              },
            ],
          })
        } else {
          navigation.navigate(bottomTabName, { screen: route?.name, params: { screen: route?.state.routeNames?.at(0) } })
        }
      }
    }

    if (!isFocused && !event.defaultPrevented && route) {
      navigation.navigate(route.name, route.params)
    }
  }

  return (
    <IM.View style={props.end && styles.tabContentRoot}>
      <TouchableOpacity key={`BottomTab${props.routeKey}`} onPress={onPress} style={styles.tab}>
        {isFocused && (
          <>
            {!props.onlyText && (
              <IM.View style={[styles.iconContainer, { backgroundColor: theme.tabNavigator.focused.iconBackground }]}>
                {!!descriptor?.options.drawerIcon &&
                  descriptor?.options.drawerIcon({
                    focused: isFocused,
                    color: theme.drawerNavigator.icon.focused,
                    size: SETTINGS.iconSize,
                  })}
              </IM.View>
            )}
          </>
        )}
        {!isFocused && !props.onlyText ? (
          <HoverView style={styles.iconContainer}>
            {!!descriptor?.options.drawerIcon &&
              descriptor?.options.drawerIcon({
                focused: isFocused,
                color: theme.drawerNavigator.icon.unFocused ?? '',
                size: SETTINGS.iconSize,
              })}
          </HoverView>
        ) : (
          <>
            {props.open && props.onlyText && (
              // eslint-disable-next-line react-native/no-inline-styles
              <HoverView style={[styles.iconContainer, { alignItems: 'flex-start' }]}>
                <Text style={[styles.text, styles.tabContentText, isFocused && { color: theme.primary }]}>{label}</Text>
              </HoverView>
            )}
          </>
        )}
      </TouchableOpacity>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  firstElement: {
    height: 70,
  },
  tabBarEmployeeBadge: {
    alignItems: 'center',
    marginTop: 6,
  },
  tabBarContent: {
    width: 60,
  },
  tabBarOpen: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabContentText: {
    color: '#ffffff',
    marginLeft: 15,
  },
  tabContentRoot: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  main: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    borderRadius: 12,
  },
  iconContainer: {
    height: 45,
    marginBottom: 8,
    aspectRatio: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabContainer: {
    flexDirection: 'column',
    padding: 8,
    flex: 1,
  },
  tab: {
    justifyContent: 'center',
  },
  tabBarRoot: { flex: 1, margin: 10, flexDirection: 'row', borderRadius: 12 },
  text: {
    alignSelf: 'center',
  },
  drawerOpen: {
    padding: 8,
    width: 200,
    opacity: 0.8,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  drawerEmployee: { marginTop: 6 },
  drawerEmployeeFirst: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular },
  employeeName: {
    marginLeft: 15,
    marginTop: 1,
  },
  employeeContainer: {
    height: 76,
  },
})
