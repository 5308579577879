import { IM, useDimensions } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { LogBox, Platform, StyleSheet } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'

import WebConfig from './app.config'
import { languageResources } from './assets/languages/LanguageResources'
import Navigator from './navigation/Navigator'

import './InitStructures'

import InfomindsAnalytics from '@infominds/react-native-analytics'
import BootSplash from 'react-native-bootsplash'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { RecoilRoot } from 'recoil'

import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { AnimationProvider } from './contexts/AnimationContext'
import { AuthenticationProvider } from './contexts/AuthenticationContext'
import { BluetoothContextProvider } from './contexts/BluetoothContext'
import { DataContextProvider } from './contexts/DataContext'
import { SettingsContextProvider } from './contexts/SettingsContext'
import { themeExpansion, themeWebExpansion } from './InitStructures'

export default function App() {
  const { isSmallDevice } = useDimensions()

  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
      BootSplash.hide().catch(console.error)
    }
  }, [])

  return (
    <AnimationProvider>
      <SafeAreaProvider>
        <RecoilRoot>
          <GestureHandlerRootView style={styles.container}>
            <IM.ThemeProvider theme={!isSmallDevice && Platform.OS === 'web' ? themeWebExpansion : themeExpansion}>
              <IM.LanguageProvider resources={languageResources}>
                <IM.AlertProvider useNewModalComponent>
                  <DataContextProvider>
                    <AuthenticationProvider>
                      {({ isLoggedIn }) => (
                        <BluetoothContextProvider>
                          <SettingsContextProvider>
                            <Navigator basePath={WebConfig.BASE_PATH.includes('/') ? WebConfig.BASE_PATH : '/'} isLoggedIn={isLoggedIn ?? false} />
                          </SettingsContextProvider>
                        </BluetoothContextProvider>
                      )}
                    </AuthenticationProvider>
                  </DataContextProvider>
                </IM.AlertProvider>
              </IM.LanguageProvider>
            </IM.ThemeProvider>
          </GestureHandlerRootView>
        </RecoilRoot>
      </SafeAreaProvider>
    </AnimationProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
