import React, { useState } from 'react'

import { Lot } from '../types/types'

type LotCreationContextType = {
  lot: Partial<Lot>
  setLot: React.Dispatch<React.SetStateAction<Partial<Lot>>>
  isFormValid: boolean
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>
}

export const LotCreationContext = React.createContext<LotCreationContextType | null>(null)

export function LotCreationContextProvider({ children }: { children: React.ReactNode }) {
  const [lot, setLot] = React.useState<Partial<Lot>>({})
  const [isFormValid, setIsFormValid] = useState(false)

  return <LotCreationContext.Provider value={{ lot, setLot, isFormValid, setIsFormValid }}>{children}</LotCreationContext.Provider>
}

export function useLotCreation() {
  const context = React.useContext(LotCreationContext)
  if (!context) {
    throw new Error('useLotCreationContext must be used within a LotCreationContextProvider')
  }
  return context
}
