import { IM, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet, Switch } from 'react-native'

import DropdownArrowDown from '../../components/DropdownArrowDown'
import DropdownArrowUp from '../../components/DropdownArrowUp'
import DropdownCheck from '../../components/DropdownCheck'
import NumberInput from '../../components/NumberInput'
import { Screen } from '../../components/screen/Screen'
import SettingsRow from '../../components/SettingsRow'
import SettingsRowContainer from '../../components/SettingsRowContainer'
import { PackagesSettings } from '../../types'

type RoundingViewProps = {
  onCloseModal: (updatedSettings: PackagesSettings) => void
  packageSettings: PackagesSettings
}

export default function RoundingView({ onCloseModal, packageSettings }: RoundingViewProps) {
  const { i18n } = useLanguage()
  const [localSettings, setLocalSettings] = useState<PackagesSettings>(packageSettings)
  const [open, setOpen] = useState(false)
  const [dropdownValue, setDropdownValue] = useState(localSettings.rounding.lengthIncreaseBy)

  useEffect(() => {
    switch (dropdownValue) {
      case 0:
        setLocalSettings(prevSettings => ({
          ...prevSettings,
          rounding: {
            ...prevSettings.rounding,
            lengthRoundingDown: 0,
            lengthIncreaseBy: dropdownValue,
          },
        }))
        break
      case 5:
        setLocalSettings(prevSettings => ({
          ...prevSettings,
          rounding: {
            ...prevSettings.rounding,
            lengthRoundingDown: 3,
            lengthIncreaseBy: dropdownValue,
          },
        }))
        break
      case 10:
        setLocalSettings(prevSettings => ({
          ...prevSettings,
          rounding: {
            ...prevSettings.rounding,
            lengthRoundingDown: 8,
            lengthIncreaseBy: dropdownValue,
          },
        }))
        break
    }
  }, [dropdownValue])

  const handleEnableRoundingChange = (value: boolean) => {
    setLocalSettings(prevSettings => ({
      ...prevSettings,
      enableRounding: value,
    }))
  }

  const handleWidthRoundingDownChange = (value: number | undefined) => {
    setLocalSettings(prevSettings => ({
      ...prevSettings,
      rounding: {
        ...prevSettings.rounding,
        widthRoundingDown: value ?? prevSettings.rounding.widthRoundingDown,
      },
    }))
  }

  const handleLengthRoundingDownChange = (value: number | undefined) => {
    setLocalSettings(prevSettings => ({
      ...prevSettings,
      rounding: {
        ...prevSettings.rounding,
        lengthRoundingDown: value ?? prevSettings.rounding.lengthRoundingDown,
      },
    }))
  }

  return (
    <>
      <Screen>
        <Screen.Header goBack={() => onCloseModal(localSettings)} title={i18n.t('ROUNDING')} />
        <Screen.Content>
          <IM.View style={styles.content}>
            <SettingsRow title={i18n.t('ENABLE_ROUNDING')}>
              <Switch value={localSettings.enableRounding} onValueChange={handleEnableRoundingChange} />
            </SettingsRow>
            {localSettings.enableRounding && (
              <SettingsRowContainer title={i18n.t('WIDTH')} spacing="vertical">
                <SettingsRow title={i18n.t('ROUNDING_DOWN')}>
                  <IM.View style={styles.label}>
                    <NumberInput disableFastInputs value={localSettings.rounding.widthRoundingDown} onValueChanged={handleWidthRoundingDownChange} />
                  </IM.View>
                </SettingsRow>
              </SettingsRowContainer>
            )}
            {localSettings.enableRounding && (
              <SettingsRowContainer title={i18n.t('LENGTH')} spacing="vertical">
                <SettingsRow title={i18n.t('INCREASE_BY')}>
                  <IM.View style={styles.label}>
                    <IM.Dropdown
                      closeAfterSelecting
                      value={dropdownValue}
                      open={open}
                      setOpen={setOpen}
                      items={[
                        { label: '0', value: 0 },
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                      ]}
                      listMode="SCROLLVIEW"
                      containerStyle={styles.dropdown}
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{ minHeight: 42 }}
                      TickIconComponent={DropdownCheck}
                      ArrowDownIconComponent={DropdownArrowDown}
                      ArrowUpIconComponent={DropdownArrowUp}
                      setValue={setDropdownValue}
                    />
                  </IM.View>
                </SettingsRow>
                <SettingsRow title={i18n.t('ROUNDING_DOWN')}>
                  <IM.View style={styles.label}>
                    <NumberInput
                      disableFastInputs
                      value={localSettings.rounding.lengthRoundingDown}
                      onValueChanged={handleLengthRoundingDownChange}
                    />
                  </IM.View>
                </SettingsRow>
              </SettingsRowContainer>
            )}
          </IM.View>
        </Screen.Content>
      </Screen>
    </>
  )
}

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
  },
  dropdown: { width: 100 },
  label: { flex: 1, maxWidth: 100 },
})
