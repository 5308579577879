import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

type GroupSpacerProps = {
  style?: StyleProp<ViewStyle>
}

export default function GroupSpacer({ style }: GroupSpacerProps) {
  return <IM.View style={[styles.view, style]} />
}

const styles = StyleSheet.create({
  view: { height: IMLayout.verticalMargin * 3 },
})
