import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'

export default function DotsCard() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[styles.pageCard, { backgroundColor: theme.background }]}>
      <IM.Text style={{ color: theme.table.text }}>{'...'}</IM.Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  pageCard: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 12,
  },
})
