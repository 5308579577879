import { IM, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { createRef } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { useAuthentication } from '../contexts/AuthenticationContext'
import { ModalScreenRef, ThemeColorExpanded } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type LogoutModalProps = {
  controller: ModalController
}

export default function LogoutModal({ controller }: LogoutModalProps) {
  const ref = createRef<ModalScreenRef>()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const { logout } = useAuthentication()

  const handleCloseModal = () => {
    controller.close()
  }

  const handleLogout = () => {
    logout().catch(error => {
      console.error('logout error', error)
    })
  }

  return (
    <BaseServiceModal
      fullWidth={Platform.OS === 'web'}
      controller={controller}
      childRef={ref}
      style={[styles.customModalStyle, { backgroundColor: theme.background }]}>
      <IM.View style={styles.container}>
        <IM.Icon icon={['fal', 'triangle-exclamation']} size={72} color={theme.general.warn} />
        <IM.Text style={styles.text}>{i18n.t('LOGOUT_DATA_LOSS')}</IM.Text>
        <IM.View style={styles.buttonContainer}>
          <IM.Button title={i18n.t('CANCEL')} style={styles.button} onPress={handleCloseModal} />
          <IM.Button title={i18n.t('OK')} style={styles.button} onPress={handleLogout} />
        </IM.View>
      </IM.View>
    </BaseServiceModal>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  text: {
    marginTop: 20,
    fontSize: 18,
    textAlign: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    flex: 1,
    marginHorizontal: 10,
  },

  customModalStyle: {
    height: '70%',
    width: '80%',
  },
})
