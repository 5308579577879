import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import QualityCreateScreen from '../screens/QualityCreate/QualityCreateScreen'
import { QualityCreateViewProps } from '../screens/QualityCreate/QualityCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type QualityCreateModalProps = {
  controller: ModalController<QualityCreateViewProps>
} & Pick<QualityCreateViewProps, 'onCreateQuality'>

export default function QualityCreateModal({ controller, onCreateQuality }: QualityCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <QualityCreateScreen ref={ref} onCreateQuality={onCreateQuality} controller={controller} />
    </BaseServiceModal>
  )
}
