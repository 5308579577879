import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostWoodRequest } from '../../apis/apiRequestTypes'
import { Wood } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import GroupSpacer from '../../components/GroupSpacer'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_WOOD_BUTTON_ID } from '../../constants/ButtonIds'
import { ThemeColorExpanded, UploadStatus } from '../../types'

const buttonAnimationValue = new Animated.Value(0)

export interface WoodCreateViewProps {
  editId?: string
  onCreateWood?: (wood: Wood) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

const WoodCreateView: React.FC<WoodCreateViewProps & Props> = ({ editId, onCreateWood, onUploadStatus }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const [wood, setWood] = useState<Partial<PostWoodRequest>>({})
  const [missingFields, setMissingFields] = useState<string[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostWoodRequest>>({})

  useEffect(() => {
    if (editId) {
      loadEditWood().catch(console.error)
    }
  }, [editId])

  async function loadEditWood() {
    setIsLoading(true)
    await api
      .getWood({ woodId: Number(editId) })
      .then(response => {
        const editWood = {
          woodCode: response.woodCode,
          woodName: response.woodName,
          reduction: response.reduction,
          descriptions: {
            additionalProp1: response.descriptions?.additionalProp1 ?? '',
            additionalProp2: response.descriptions?.additionalProp2 ?? '',
            additionalProp3: response.descriptions?.additionalProp3 ?? '',
          },
          height: response.height,
          length: response.length,
          width: response.width,
          unit: response.unit,
          um: response.um,
          barcode: response.barcode,
          validFrom: response.validFrom,
          validTo: response.validTo,
        }
        setWood(editWood)
        setInitialValue(editWood)
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostWoodRequest>) {
    setWood(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields = [{ value: wood.woodName, field: 'woodName' }]
    const notFilledFields = requiredFields.filter(field => !field.value).map(field => field.field)
    setMissingFields(notFilledFields)
    return notFilledFields.length === 0
  }

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate().finally(() => {
      setIsLoading(false)
    })
  }

  const handleCreate = async () => {
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [{ text: i18n.t('OK'), style: 'default' }])
      return
    }

    const woodBody: Wood = {
      woodId: wood.woodId ?? 0,
      organizationId: wood.organizationId ?? 0,
      woodCode: wood.woodCode ?? '',
      woodName: wood.woodName ?? '',
      reduction: wood.reduction ?? 0,
      descriptions: {
        additionalProp1: wood.descriptions?.additionalProp1 ?? '',
        additionalProp2: wood.descriptions?.additionalProp2 ?? '',
        additionalProp3: wood.descriptions?.additionalProp3 ?? '',
      },
      height: wood.height ?? 0,
      length: wood.length ?? 0,
      width: wood.width ?? 0,
      unit: wood.unit ?? 0,
      um: wood.um ?? '',
      barcode: wood.barcode ?? '',
      validFrom: wood.validFrom ?? new Date().toISOString(),
      validTo: wood.validTo ?? new Date().toISOString(),
    }

    if (editId) {
      await api
        .putWood(woodBody)
        .then(response => onUploadStatus('done'))
        .catch(error => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
      return
    }

    await api
      .postWood(woodBody)
      .then(() => onUploadStatus('done'))
      .catch(error => alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [{ text: i18n.t('OK'), style: 'default' }]))
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={wood.woodName ?? ''}
              onChangeText={value => handleChange({ woodName: value })}
              spacing={'all'}
              error={missingFields.includes('woodName')}
            />
            <TextInput
              title={i18n.t('CODE')}
              placeholder={i18n.t('CODE')}
              value={wood.woodCode ?? ''}
              onChangeText={value => handleChange({ woodCode: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('REDUCTION')}
              placeholder={i18n.t('REDUCTION')}
              value={wood.reduction?.toString() ?? ''}
              onChangeText={value => handleChange({ reduction: Number(value) })}
              spacing={'all'}
            />
            <GroupSpacer />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_WOOD_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default WoodCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
