export const BLE_DISCOVERED_PERIPHERAL = 'BleManagerDiscoverPeripheral'
export const BLE_DID_UPDATE_VALUE_FOR_CHARACTERISTIC = 'BleManagerDidUpdateValueForCharacteristic'
export const BLE_DISCONNECT_PERIPHERAL = 'BleManagerDisconnectPeripheral'

export const LEICA_SERVICE_UUID = '3ab10100-f831-4395-b29d-570977d5bf94'
export const LEICA_CHARACTERISTIC_UUID = '3ab10101-f831-4395-b29d-570977d5bf94'

export const MONOLITH_SERVICE_UUID = '0000fff0-0000-1000-8000-00805f9b34fb'
export const MONOLITH_CHARACTERISTIC_UUID = '0000fff1-0000-1000-8000-00805f9b34fb'

export const TAJFUN_SERVICE_UUID = '49535343-fe7d-4ae5-8fa9-9fafd205e455'
export const TAJFUN_CHARACTERISTIC_UUID = '49535343-8841-43f4-a8d4-ecbe34729bb3'

export const PRINTER_SERVICE_UUID = 'e7810a71-73ae-499d-8c15-faa9aef0c3f2'
export const PRINTER_CHARACTERISTIC_UUID = 'bef8d6c9-9c21-4c9e-b632-bd58c1009f9f'
