import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import OriginCreateScreen from '../screens/OriginCreate/OriginCreateScreen'
import { OriginCreateViewProps } from '../screens/OriginCreate/OriginCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type OriginCreateModalProps = {
  controller: ModalController<OriginCreateViewProps>
} & Pick<OriginCreateViewProps, 'onCreateOrigin'>

export default function OriginCreateModal({ controller, onCreateOrigin }: OriginCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <OriginCreateScreen ref={ref} onCreateOrigin={onCreateOrigin} controller={controller} />
    </BaseServiceModal>
  )
}
