import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { Organization } from '../../apis/apiResponseTypes'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ModalScreenProps, ModalScreenRef } from '../../types'
import OrganizationCreateView, { OrganizationCreateViewProps } from './OrganizationCreateView'

const OrganizationInfoEdtiScreen = (
  {
    controller,
    onCreateOrganization,
  }: ModalScreenProps<OrganizationCreateViewProps> & { onCreateOrganization?: (organization: Organization) => void },
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const editMode = controller?.data?.editId !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={editMode ? i18n.t('EDIT_ORGANIZATION') : i18n.t('NEW_ORGANIZATION')}
      />
      <Screen.Content>
        <FormProvider>
          <OrganizationCreateView onCreateOrganization={onCreateOrganization} onUploadStatus={setStatus} editId={controller?.data?.editId} />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(OrganizationInfoEdtiScreen)
