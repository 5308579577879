import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { User } from '../../apis/apiResponseTypes'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ModalScreenProps, ModalScreenRef } from '../../types'
import UserCreateView, { UserCreateViewProps } from './UserCreateView'

const UserCreateScreen = (
  {
    controller,
    onCreateUser,
    organizationId,
  }: ModalScreenProps<UserCreateViewProps> & { onCreateUser?: (user: User) => void; organizationId?: number },
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const editMode = controller?.data?.editId !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={editMode ? i18n.t('EDIT_USER') : i18n.t('NEW_USER')}
      />
      <Screen.Content>
        <FormProvider>
          <UserCreateView onCreateUser={onCreateUser} onUploadStatus={setStatus} editId={controller?.data?.editId} organizationId={organizationId} />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(UserCreateScreen)
