import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import UserCreateScreen from '../screens/UserCreate/UserCreateScreen'
import { UserCreateViewProps } from '../screens/UserCreate/UserCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type UserCreateModalProps = {
  controller: ModalController<UserCreateViewProps>
} & Pick<UserCreateViewProps, 'onCreateUser' | 'organizationId'>

export default function UserCreateModal({ controller, onCreateUser, organizationId }: UserCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <UserCreateScreen ref={ref} onCreateUser={onCreateUser} controller={controller} organizationId={organizationId} />
    </BaseServiceModal>
  )
}
