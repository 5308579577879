import { useData } from '../contexts/DataContext'
import { Package } from '../types/types'

export const usePackages = () => {
  const { packages, setPackages } = useData()

  const addPackage = (pkg: Package) => {
    try {
      setPackages([...packages, pkg])
    } catch (error) {
      console.error('Failed to save packages to AsyncStorage', error)
    }
  }

  const updatePackage = (pkg: Package) => {
    setPackages(prev => [...prev.map(p => (p.tmpId === pkg.tmpId ? pkg : p))])
  }

  const clearPackages = () => {
    try {
      const openPackages = packages.filter(pkg => !pkg.closed)
      setPackages(openPackages)
    } catch (error) {
      console.error('Failed to clear packages from AsyncStorage', error)
    }
  }

  const deletePackage = (id: string) => {
    try {
      // const updatedPackages = packages.filter(pkg => pkg.tmpId !== id)
      setPackages(prev => [...prev.filter(pkg => pkg.tmpId !== id)])
    } catch (error) {
      console.error('Failed to delete package from AsyncStorage', error)
    }
  }

  const getPackageById = (id: string): Package | undefined => {
    try {
      return packages.find(pkg => pkg.tmpId === id) || undefined
    } catch (error) {
      console.error('Failed to get package by id', error)
      return undefined
    }
  }

  return { addPackage, clearPackages, getPackageById, updatePackage, deletePackage }
}
