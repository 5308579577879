import { NativeEventEmitter, NativeModule, NativeModules } from 'react-native'
import BleManager, { BleScanCallbackType, BleScanMatchMode, BleScanMode, BleScanPhyMode, Peripheral } from 'react-native-ble-manager'
import { PERMISSIONS, requestMultiple } from 'react-native-permissions'

import { ConfiguredDevice } from '../types'

const RECONNECTION_TIMEOUT = 60000
let bleManagerInstance: typeof BleManager | null = null

const BleManagerModule = NativeModules.BleManager as NativeModule
const bleManagerEmitter = new NativeEventEmitter(BleManagerModule)

export const startBluetooth = async (requestPermissions: () => Promise<void>, startBleManager: () => Promise<void>) => {
  await requestPermissions()
  await startBleManager()
}

export const startBleManager = async () => {
  try {
    if (!bleManagerInstance) {
      bleManagerInstance = BleManager
      await bleManagerInstance.start({ showAlert: false }).then(() => {
        console.info('BleManager started successfully.')
      })
    }
  } catch (error) {
    console.error('Error starting BleManager:', error)
  }
}

export const requestPermissions = async () => {
  try {
    await requestMultiple([
      PERMISSIONS.ANDROID.BLUETOOTH_CONNECT,
      PERMISSIONS.ANDROID.BLUETOOTH_SCAN,
      PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION,
      PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION,
    ])
  } catch (error) {
    console.error('Error requesting permissions:', error)
  }
}

export const scanForDevices = async (configuredDevices: ConfiguredDevice[]): Promise<Peripheral[]> => {
  return new Promise((resolve, reject) => {
    try {
      const scannedDevices: Peripheral[] = []
      const onDiscoverPeripheral = (peripheral: Peripheral) => {
        try {
          if (scannedDevices.some(device => device.id === peripheral.id)) {
            return
          }

          if (peripheral.name && configuredDevices.some(device => peripheral.name?.toLowerCase().includes(device.name.toLowerCase()))) {
            return scannedDevices.push(peripheral)
          }
        } catch (error) {
          console.error('Error processing peripheral:', error)
        }
        return
      }

      bleManagerEmitter.addListener('BleManagerDiscoverPeripheral', onDiscoverPeripheral)
      bleManagerEmitter.addListener('BleManagerStopScan', () => {
        console.info('Scan complete', new Date())
        bleManagerEmitter.removeAllListeners('BleManagerDiscoverPeripheral')
        resolve(scannedDevices)
      })

      BleManager.scan([], 5, false, {
        legacy: false,
        scanMode: BleScanMode.Balanced,
        matchMode: BleScanMatchMode.Sticky,
        callbackType: BleScanCallbackType.AllMatches,
        phy: BleScanPhyMode.ALL_SUPPORTED,
      })
        .then(() => {
          console.info('Scan started', new Date())
        })
        .catch(error => {
          reject(error)
        })
    } catch (error) {
      console.error('Error scanning for peripherals:', error)
      reject(error)
    }
  })
}

export const getConnectedDevices = async (): Promise<Peripheral[]> => {
  try {
    const peripherals = await BleManager.getConnectedPeripherals([])
    return peripherals
  } catch (error) {
    console.error('Error checking printer connection', error)
    return []
  }
}

export function handleDisconnect(data: unknown, peripheralId: string) {
  console.info('Disconnected from:', data)

  if (data && typeof data === 'object' && 'peripheral' in data && data.peripheral === peripheralId) {
    attemptReconnection(peripheralId).catch(error => {
      console.error('reconnection error', error)
    })
  }
}

export const attemptReconnection = async (peripheralId: string) => {
  const startTime = Date.now()
  let hasTimedOut = false

  const reconnect = async () => {
    if (hasTimedOut) return

    if (Date.now() - startTime > RECONNECTION_TIMEOUT) {
      hasTimedOut = true
      console.info('Reconnection attempt timed out.')
      return
    }

    try {
      await BleManager.connect(peripheralId)
      const peripheralInfo = await BleManager.retrieveServices(peripheralId)
      console.info('Peripheral info:', JSON.stringify(peripheralInfo, undefined, 2))
    } catch (error) {
      console.error('Error during reconnection attempt:', error)
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(reconnect, 1000)
    }
  }

  await reconnect()
}
