import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useRef } from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { useData } from '../../contexts/DataContext'
import useLayout from '../../hooks/useLayout'
import useMenu from '../../hooks/useMenu'
import { BottomTabParamList, RootStackParamList, TabNavigationScreen } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import navigationUtils from '../../utils/navigationUtils'
import OrganizationBadge from '../OrganizationBadge'
import Text from '../Text'
import useDrawer from './hooks/useDrawer'
import useDrawerRoute from './hooks/useDrawerRoute'
import HoverView from './HooverView'
import PressableIcon from './PressableIcon'

const Drawer = createDrawerNavigator()

const SETTINGS = {
  iconSize: 22,
}

interface TabNavigatorProps {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export function DrawerNavigator<RouteName extends keyof BottomTabParamList>({ initialRouteName, screenOptions }: TabNavigatorProps) {
  const { tabs, maxDynamicElements } = useMenu<RouteName>()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { i18n } = useLanguage()
  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements])

  if (tabs.length === 0) {
    if (Platform.OS !== 'web') {
      return <IM.LoadingSpinner isVisible />
    } else {
      return <></>
    }
  }

  return (
    <Drawer.Navigator
      initialRouteName={initialRouteName}
      drawerContent={DrawerContent}
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        drawerStyle: {
          width: 'auto',
          backgroundColor: theme.drawerNavigator.background,
          borderRightColor: theme.drawerNavigator.background,
        },
        ...screenOptions,
      }}>
      {sortedTabs.map(tab => {
        return (
          <Drawer.Screen
            key={`BottomTabScreen${tab.name}`}
            name={tab.name}
            component={tab.component}
            options={{
              ...(tab.options as DrawerContentComponentProps),
              title: i18n.t(tab.title),
            }}
          />
        )
      })}
    </Drawer.Navigator>
  )
}

function DrawerContent() {
  return <></>
}

export type TabBarWebProps = {
  defaultRoute?: keyof BottomTabParamList
}

export function TabBarWeb({ defaultRoute }: TabBarWebProps) {
  const { tabs, maxDynamicElements } = useMenu<keyof BottomTabParamList>()
  const navigation = useNavigation()
  const { isLargeDevice } = useLayout(true)
  const { open, setOpen } = useDrawer()
  const { currentOrganization } = useData()
  const { theme } = useTheme<ThemeColorExpanded>()
  const prevDeviceSize = useRef<'small' | 'large'>(isLargeDevice ? 'large' : 'small')
  const { route } = useDrawerRoute('DrawerNavigatorStack', bottomTabName, defaultRoute)
  const name = useMemo(() => currentOrganization && currentOrganization.organizationName, [currentOrganization])

  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements])

  useEffect(() => {
    if (!isLargeDevice && prevDeviceSize.current === 'large') {
      setOpen(false)
      prevDeviceSize.current = 'small'
    }

    if (isLargeDevice && prevDeviceSize.current === 'small') {
      prevDeviceSize.current = 'large'
    }
  }, [isLargeDevice])

  if (tabs.length === 0) {
    return <></>
  }

  return (
    <>
      <IM.View style={[styles.tabBarRoot, IMStyle.layout.shadow]}>
        <IM.View
          style={[styles.main, styles.tabBarContent, { backgroundColor: theme.drawerNavigator.bar }, (open || !isLargeDevice) && styles.tabBarOpen]}>
          <IM.View style={styles.tabContainer}>
            {currentOrganization && (
              <>
                <IM.View style={[styles.firstElement, styles.tabBarEmployeeBadge]}>
                  <OrganizationBadge
                    size={38}
                    showName={false}
                    bannerUrl={currentOrganization.bannerUrl}
                    name={name ?? ''}
                    color={name === undefined ? theme.drawerNavigator.bar : undefined}
                  />
                </IM.View>

                <IM.View style={IMStyle.layout.flex.f1}>
                  {sortedTabs.map((tab, index) => {
                    return (
                      <TabContent
                        key={`BottomTab${index}`}
                        routeKey={tab.id.toString()}
                        tabProps={tab}
                        end={tab.name === 'SettingsStack'}
                        onlyText={false}
                        open={open}
                        isFocused={route === tab.name}
                      />
                    )
                  })}
                </IM.View>

                {isLargeDevice && (
                  <PressableIcon
                    icon={['fal', open ? 'angles-left' : 'angles-right']}
                    size={15}
                    color="#ffffff"
                    onPress={() => {
                      setOpen(prev => !prev)
                    }}
                  />
                )}
              </>
            )}
          </IM.View>
        </IM.View>
        {((isLargeDevice && open) || !isLargeDevice) && (
          <IM.View
            style={[
              styles.drawerOpen,
              // eslint-disable-next-line react-native/no-inline-styles
              {
                paddingBottom: isLargeDevice ? 44 : 8,
                backgroundColor: theme.drawerNavigator.bar,
              },
            ]}>
            <IM.View style={styles.employeeContainer}>
              {currentOrganization && (
                <IM.View style={[styles.firstElement, styles.drawerOrganization]}>
                  <IM.View style={[IMLayout.flex.row]}>
                    <IM.View style={[styles.organizationName, IMLayout.flex.f1]}>
                      {!!currentOrganization.organizationName && (
                        <Text style={styles.drawerOrganizationName}>{currentOrganization.organizationName}</Text>
                      )}
                    </IM.View>
                    {!isLargeDevice && (
                      <PressableIcon
                        icon={['fal', 'times']}
                        color={IMStyle.palette.white}
                        onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
                        size={26}
                      />
                    )}
                  </IM.View>
                </IM.View>
              )}
            </IM.View>
            {sortedTabs.map((tab, index) => {
              return (
                <TabContent
                  key={`BottomTab${index}`}
                  routeKey={tab.id.toString()}
                  tabProps={tab}
                  end={tab.name === 'SettingsStack'}
                  onlyText
                  open={(isLargeDevice && open) || !isLargeDevice}
                  isFocused={route === tab.name}
                />
              )
            })}
          </IM.View>
        )}
      </IM.View>
    </>
  )
}

const bottomTabName: keyof RootStackParamList = 'BottomTab'
function TabContent({
  routeKey,
  tabProps,
  isFocused,
  onlyText,
  end,
  open,
}: {
  routeKey: string
  tabProps: TabNavigationScreen<keyof BottomTabParamList>
  end: boolean
  onlyText: boolean
  open: boolean
  isFocused: boolean
}) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation()
  const { i18n } = useLanguage()
  const label = tabProps.title ? i18n.t(tabProps.title) : ''

  const onPress = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: bottomTabName,
          params: {
            screen: tabProps.name,
          },
        },
      ],
    })
  }

  return (
    <IM.View style={end && styles.tabContentRoot}>
      <TouchableOpacity key={`BottomTab${routeKey}`} onPress={onPress} style={styles.tab}>
        {isFocused && (
          <>
            {!onlyText && (
              <IM.View style={[styles.iconContainer, { backgroundColor: theme.tabNavigator.focused.iconBackground }]}>
                <IM.Icon icon={tabProps.icon} size={SETTINGS.iconSize} color={theme.drawerNavigator.icon.focused} />
              </IM.View>
            )}
          </>
        )}
        {!isFocused && !onlyText ? (
          <HoverView style={styles.iconContainer}>
            <IM.Icon icon={tabProps.icon} size={SETTINGS.iconSize} color={theme.drawerNavigator.icon.unFocused} />
          </HoverView>
        ) : (
          <>
            {open && onlyText && (
              // eslint-disable-next-line react-native/no-inline-styles
              <HoverView style={[styles.iconContainer, { alignItems: 'flex-start' }]}>
                <Text style={[styles.text, styles.tabContentText, isFocused && { color: theme.primary }]}>{label}</Text>
              </HoverView>
            )}
          </>
        )}
      </TouchableOpacity>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  firstElement: {
    height: 70,
  },
  tabBarEmployeeBadge: {
    alignItems: 'center',
    marginTop: 6,
  },
  tabBarContent: {
    width: 60,
  },
  tabBarOpen: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabContentText: {
    color: '#ffffff',
    marginLeft: 15,
  },
  tabContentRoot: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  main: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    borderRadius: 12,
  },
  iconContainer: {
    height: 45,
    marginBottom: 8,
    aspectRatio: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabContainer: {
    flexDirection: 'column',
    padding: 8,
    flex: 1,
  },
  tab: {
    justifyContent: 'center',
  },
  tabBarRoot: { flex: 1, margin: 10, flexDirection: 'row', borderRadius: 12 },
  text: {
    alignSelf: 'center',
  },
  drawerOpen: {
    padding: 8,
    width: 200,
    opacity: 0.8,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  drawerOrganization: { marginTop: 6 },
  drawerOrganizationName: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular },
  organizationName: {
    marginLeft: 15,
    //marginTop: 1,
    justifyContent: 'center',
  },
  employeeContainer: {
    height: 76,
  },
})
