import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import lodash from 'lodash'
import React, {
  Children,
  ForwardedRef,
  memo,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Keyboard,
  Platform,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  ViewStyle,
} from 'react-native'
import { formatWithMask, Mask } from 'react-native-mask-input'

import useFormInput from '../../hooks/form/useFormInput'
import { Border, TextInputRef, ThemeColorExpanded } from '../../types'
import Pressable from '../Pressable'
import Text from '../Text'
import BaseTextInputLeftIcon from './BaseTextInputLeftIcon'
import BaseTextInputRightIcon from './BaseTextInputRightIcon'
import BaseTextInputTitle from './BaseTextInputTitle'
import useBaseTextInput from './hooks/useBaseTextInput'

type ManagedType = 'email' | 'url' | 'number' | 'decimal' | 'phone' | 'time' | 'date' | 'postal-code' | 'town' | 'address' | 'province' | undefined

export type BaseTextInputProps = RNTextInputProps & {
  type?: ManagedType
  spacing?: SpacingProps
  disableBorderRadius?: Border | Border[]
  onPress?: () => void
  onWidth?: (width: number) => void
  onHeight?: (height: number) => void
  mask?: Mask
  maskAutoComplete?: boolean
  unity?: string
  containerStyle?: StyleProp<ViewStyle>
  required?: boolean
  textStyle?: StyleProp<TextStyle>
  /**
   * Limit the maximum height of the input. Android & iOS only
   */
  maxHeight?: number
  tabIndex?: number
}

export function handleBorderStyle(borders: Border | Border[], topStyle: StyleProp<TextStyle>, bottomStyle: StyleProp<TextStyle>) {
  const toRet: StyleProp<TextStyle>[] = []

  if (lodash.isArray(borders)) {
    borders?.find(elem => elem === 'top') && toRet.push(topStyle)
    borders?.find(elem => elem === 'bottom') && toRet.push(bottomStyle)
  } else {
    switch (borders) {
      case 'bottom': {
        toRet.push(bottomStyle)
        break
      }
      case 'top': {
        toRet.push(topStyle)
        break
      }
    }
  }

  return toRet
}

function getTypeProps(type: ManagedType): Pick<RNTextInputProps, 'inputMode' | 'keyboardType' | 'secureTextEntry' | 'autoComplete'> {
  switch (type) {
    case 'email': {
      return {
        inputMode: 'email',
        keyboardType: 'email-address',
        autoComplete: 'email',
      }
    }
    case 'url': {
      return { inputMode: 'url', keyboardType: 'url' }
    }
    case 'decimal': {
      return {
        inputMode: 'decimal',
        keyboardType: 'decimal-pad',
      }
    }
    case 'number': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
      }
    }
    case 'phone': {
      return {
        inputMode: 'tel',
        keyboardType: 'phone-pad',
        autoComplete: 'tel',
      }
    }
    case 'date': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
        autoComplete: 'birthdate-full',
      }
    }
    case 'time': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
      }
    }
    case 'address': {
      return {
        inputMode: 'text',
        autoComplete: 'postal-address',
      }
    }
    case 'postal-code': {
      return {
        inputMode: 'numeric',
        autoComplete: 'postal-code',
      }
    }
    case 'province': {
      return {
        inputMode: 'text',
      }
    }
    case 'town': {
      return {
        inputMode: 'text',
        autoComplete: 'postal-address-locality',
      }
    }
    case undefined: {
      return {}
    }
  }
}

const BaseTextInput = memo(
  React.forwardRef(
    (
      {
        children,
        spacing,
        type,
        disableBorderRadius,
        mask,
        maskAutoComplete,
        unity,
        containerStyle,
        maxHeight,
        tabIndex,
        placeholder,
        ...props
      }: PropsWithChildren<BaseTextInputProps>,
      ref: ForwardedRef<TextInputRef>
    ) => {
      useImperativeHandle(ref, () => innerRef.current as TextInput)
      const { onPress, onBlur, onWidth, onHeight } = props

      const { i18n } = useLanguage()
      const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
      const { backgroundColor, borderColor, loading, editable, onClickEffect } = useBaseTextInput()

      const [scrollHeight, setScrollHeight] = useState<number | undefined>(undefined)

      const { inputRef: innerRef, onKeyPress, setInputFocused } = useFormInput(editable, tabIndex)

      const { masked } = formatWithMask({
        text: props.value,
        mask: mask,
        maskAutoComplete: maskAutoComplete,
      })

      const components = useMemo(() => {
        const childArray = Children.toArray(children)
        return {
          title: childArray.find(q => (q as ReactElement).type === BaseTextInputTitle),
          rightIcon: childArray.filter(q => (q as ReactElement).type === BaseTextInputRightIcon),
          leftIcon: childArray.filter(q => (q as ReactElement).type === BaseTextInputLeftIcon),
        }
      }, [children])

      useEffect(() => {
        if (Platform.OS === 'web') return
        const sub = Keyboard.addListener('keyboardDidHide', () => innerRef.current?.blur())
        return () => sub?.remove?.()
      }, [])

      const memValue = useRef<string | undefined>(props.value)

      useEffect(() => {
        if (Platform.OS !== 'web' || !props.multiline) return
        // update the scrollHeight every time a character was deleted to trigger a recalculation of the height
        // This also fixes the possibility of an app-crash if the whole content is deleted at once
        if ((props.value && memValue.current && props.value.length < memValue.current.length) || !props.value) {
          setScrollHeight(undefined)
        }
        memValue.current = props.value
      }, [props.value])

      return (
        <IM.View spacing={spacing} style={[containerStyle]}>
          {components.title}
          <>
            <IM.View
              style={[styles.container]}
              onLayout={ev => {
                onWidth?.(ev.nativeEvent.layout.width)
                onHeight?.(ev.nativeEvent.layout.height)
              }}>
              {components.leftIcon.length !== 0 && components.leftIcon}
              <Pressable
                containerStyle={[IMLayout.flex.f1]}
                style={[styles.pressable]}
                disabled={!editable || !onPress}
                onPress={() => {
                  setTimeout(() => {
                    setInputFocused()
                    onPress?.()
                  }, 100)
                }}>
                <IM.View
                  style={[
                    styles.inputView,
                    components.rightIcon.length !== 0 && components.leftIcon.length !== 0
                      ? undefined
                      : components.rightIcon.length !== 0
                        ? styles.borderInputRightIcon
                        : components.leftIcon.length !== 0
                          ? styles.borderInputLeftIcon
                          : styles.border,
                    disableBorderRadius && handleBorderStyle(disableBorderRadius, styles.disableTopBorder, styles.disableBottomBorder),
                    {
                      height: scrollHeight ? scrollHeight + 2 : undefined, // add 2 for border (bottom, top) otherwise the view will display a scroll-bar in web
                      borderColor,
                      backgroundColor,
                    },
                    props.style,
                  ]}>
                  <RNTextInput
                    {...props}
                    {...getTypeProps(type)}
                    ref={innerRef}
                    value={masked}
                    style={[
                      styles.input,
                      Platform.OS === 'android' && styles.inputHorizontal,
                      {
                        height: scrollHeight ? scrollHeight : undefined,
                        color: theme.text,
                        maxHeight: Platform.select({ android: maxHeight, ios: maxHeight }),
                      },
                      props.multiline && styles.multilinePaddingTop,
                      props.textStyle,
                    ]}
                    onFocus={() => {
                      onClickEffect(true)
                      setInputFocused()
                    }}
                    onBlur={e => {
                      onBlur?.(e)
                      onClickEffect(false)
                    }}
                    autoCapitalize="none"
                    editable={onPress !== undefined ? false : loading === false && editable}
                    placeholder={placeholder ?? (loading ? i18n.t('LOADING_PLACEHOLDER') : undefined)}
                    placeholderTextColor={props.placeholderTextColor ?? theme.textPlaceholder}
                    pointerEvents={onPress !== undefined ? 'none' : 'auto'}
                    keyboardAppearance={colorScheme}
                    scrollEnabled={Platform.OS !== 'web' && !!props.multiline && (!!props.numberOfLines || !!maxHeight)}
                    cursorColor={theme.general.info}
                    onContentSizeChange={event => Platform.OS === 'web' && props.multiline && setScrollHeight(event.nativeEvent.contentSize.height)}
                    onKeyPress={e => {
                      if (Platform.OS !== 'web') return

                      if (e.nativeEvent.key === 'Enter' && !!onPress) {
                        onPress()
                        e.preventDefault()
                      } else {
                        onKeyPress(e)
                      }
                    }}
                  />
                </IM.View>
              </Pressable>
              {!!unity && (
                <Pressable
                  style={[
                    styles.unity,
                    {
                      backgroundColor,
                      borderColor,
                    },
                  ]}>
                  <Text secondary>{unity}</Text>
                </Pressable>
              )}
              {components.rightIcon.length !== 0 && components.rightIcon}
            </IM.View>
          </>
        </IM.View>
      )
    }
  )
)

const BaseTextInputNamespace = Object.assign(BaseTextInput, {
  RightIcon: BaseTextInputRightIcon,
  LeftIcon: BaseTextInputLeftIcon,
  Title: BaseTextInputTitle,
})

export { BaseTextInputNamespace as BaseTextInput }

const padding = Platform.OS === 'android' ? 7 : 12

const styles = StyleSheet.create({
  border: {
    borderRadius: IMLayout.borderRadius,
  },
  borderInputRightIcon: {
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  borderInputLeftIcon: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
  },
  container: { flexDirection: 'row' },
  disableBottomBorder: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  disableTopBorder: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  inputView: {
    borderWidth: 1,
  },
  input: {
    padding: padding,
  },
  inputHorizontal: {
    paddingHorizontal: 12,
  },
  multilinePaddingTop: { paddingTop: padding },
  pressable: { padding: 0 },
  unity: {
    borderRadius: 0,
    flex: 1,
    paddingRight: 10,
    paddingLeft: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    marginLeft: -1,
    borderLeftWidth: 0,
  },
})

export const baseTextInputStyles = StyleSheet.create({
  icon: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  rightIcon: {
    borderLeftWidth: 0,
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    marginLeft: -2,
  },
  leftIcon: {
    borderRightWidth: 0,
    marginRight: -2,
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
})
