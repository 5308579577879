import { IM } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import TableUtils from '../../utils/TableUtils'
import DotsCard from './DotsCard'
import PageCard from './PageCard'
import SkipPageCard from './SkipPageCard'

export type PagingProps = { currentPage: number; totalPages: number; handlePageChange: (page: number) => void; maxVisiblePages: number }

export default function Paging({ currentPage, totalPages, handlePageChange, maxVisiblePages }: PagingProps) {
  const pages = useMemo(() => TableUtils().calculateVisiblePages(currentPage, totalPages, maxVisiblePages), [totalPages, currentPage])

  return (
    <IM.View style={styles.pagingContainer} spacing={['top']}>
      <IM.View style={styles.pages}>
        <SkipPageCard currentPage={currentPage} direction="prev" handlePageChange={handlePageChange} totalPages={totalPages} skipAll />
        <SkipPageCard currentPage={currentPage} direction="prev" handlePageChange={handlePageChange} totalPages={totalPages} />
      </IM.View>
      <IM.View style={styles.pages}>
        {pages.map((page, index) =>
          page === '...' ? (
            <DotsCard key={`dots-${index}`} />
          ) : (
            <PageCard key={`page-${page}`} page={page as number} handlePageChange={handlePageChange} currentPage={currentPage} />
          )
        )}
      </IM.View>
      <IM.View style={styles.pages}>
        <SkipPageCard currentPage={currentPage} direction="next" handlePageChange={handlePageChange} totalPages={totalPages} />
        <SkipPageCard currentPage={currentPage} direction="next" handlePageChange={handlePageChange} totalPages={totalPages} skipAll />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  pagingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pages: {
    flexDirection: 'row',
    gap: 5,
  },
})
