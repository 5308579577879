import { useAlert, useLanguage } from '@infominds/react-native-components'

export default function useDeleteAlert(title?: string, description?: string) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  function show() {
    return new Promise<'delete' | 'cancel'>(resolve => {
      alert.alert(title ?? i18n.t('DELETE'), description ?? i18n.t('SURE_TO_DELETE'), [
        {
          text: i18n.t('YES'),
          onPress: () => resolve('delete'),
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => resolve('cancel'),
          style: 'cancel',
        },
      ])
    })
  }

  return {
    show,
  }
}
