import { useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useImperativeHandle } from 'react'

import { OrganizationLicense } from '../../apis/apiResponseTypes'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ModalScreenProps, ModalScreenRef } from '../../types'
import LicenseCreateView, { LicenseCreateViewProps } from './LicenseCreateView'

const LicenseCreateScreen = (
  {
    controller,
    onCreateLicense,
    organizationId,
  }: ModalScreenProps<LicenseCreateViewProps> & { onCreateLicense?: (license: OrganizationLicense) => void; organizationId?: number },
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined
  const editMode = controller?.data?.editId !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={() => handleGoBack()}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={editMode ? i18n.t('EDIT_LICENSE') : i18n.t('NEW_LICENSE')}
      />
      <Screen.Content>
        <FormProvider>
          <LicenseCreateView
            organizationId={organizationId}
            onCreateLicense={onCreateLicense}
            onUploadStatus={setStatus}
            editId={controller?.data?.editId}
          />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(LicenseCreateScreen)
