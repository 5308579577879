import { ModalController, useDimensions } from '@infominds/react-native-components'
import { StackActions, useFocusEffect, useNavigation } from '@react-navigation/native'
import { useCallback, useMemo, useRef, useState } from 'react'

import { UploadStatus } from '../types'
import navigationUtils from '../utils/navigationUtils'
import { useBeforeUnload } from './useBeforeUnload'
import usePopStateModal from './usePopStateModal'
import useUnsavedChangesAlert from './useUnsavedChangesAlert'

interface Props<T> {
  title: string
  description: string
  controller?: ModalController<T>
  onDiscard?: () => void
  onGoBack?: () => void
}

export default function useEditOrCreateScreenBackManager<T>({ title, description, controller, onDiscard, onGoBack }: Props<T>) {
  const navigation = useNavigation()
  const { isSmallDevice } = useDimensions()

  const prevStatus = useRef<UploadStatus>('done')
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('done')

  const unsavedChanges = useMemo(() => uploadStatus === 'waiting' || uploadStatus === 'mandatoryMissing', [uploadStatus])
  const alert = useUnsavedChangesAlert(title, description)

  useBeforeUnload(unsavedChanges)
  usePopStateModal<T>(unsavedChanges, title, description, controller)

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation
        .getParent()
        ?.getParent()
        // @ts-ignore don't know type
        ?.addListener('tabPress', (e: unknown) => handleTabPress(e, unsavedChanges))

      return unsubscribe
    }, [navigation, unsavedChanges])
  )

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation
        .getParent()
        // @ts-ignore don't know type
        ?.addListener('tabPress', (e: unknown) => handleTabPress(e, unsavedChanges))

      return unsubscribe
    }, [navigation, unsavedChanges])
  )

  const handleTabPress = (e: unknown, unsaved: boolean) => {
    if (unsaved) {
      // @ts-ignore due to previous error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      e.preventDefault()
      showAlert(() => {
        navigation.dispatch(StackActions.popToTop())
      })
    }
  }

  const handleGoBack = useCallback(
    (ignoreUnsavedChanges?: boolean) => {
      if (unsavedChanges && !ignoreUnsavedChanges) {
        showAlert(() => {
          onDiscard?.()
          close()
        })
      } else {
        close()
      }
    },
    [unsavedChanges, isSmallDevice]
  )

  const handleForceClose = useCallback(() => {
    close()
  }, [isSmallDevice])

  const handleChangeStatus = useCallback(
    (newStatus: UploadStatus) => {
      prevStatus.current = uploadStatus
      setUploadStatus(newStatus)
    },
    [prevStatus, uploadStatus]
  )

  function close() {
    navigationUtils.navigationBack(
      // @ts-ignore: todo
      navigation,
      !controller,
      controller === undefined
        ? undefined
        : () => {
            controller?.close()
            onGoBack?.()
          }
    )
  }

  const showAlert = (onPressCallback: () => void) => {
    alert
      .show()
      .then(result => result === 'discard' && onPressCallback())
      .catch(console.error)
  }

  return {
    status: uploadStatus,
    handleGoBack,
    handleForceClose,
    setStatus: handleChangeStatus,
  }
}
