import React, { memo, PropsWithChildren } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import Pressable, { PressableProps } from './Pressable'
import TextWithIcon, { TextWithIconProps } from './TextWithIcon'

export type PressableTextIconProps = Omit<PressableProps, 'style' | 'id'> &
  Pick<TextWithIconProps, 'secondary' | 'icon' | 'alignIcon' | 'iconSize' | 'iconColor' | 'style' | 'color' | 'numberOfLines' | 'viewStyle'> & {
    pressableStyle?: StyleProp<ViewStyle>
  }

const PressableTextIcon = memo(function PressableTextIcon({
  children,
  secondary,
  icon,
  alignIcon,
  iconSize,
  iconColor,
  color,
  style,
  pressableStyle,
  viewStyle,
  ...pressableProps
}: PropsWithChildren<PressableTextIconProps>) {
  return (
    <Pressable {...pressableProps} style={pressableStyle}>
      <TextWithIcon
        secondary={secondary}
        icon={icon}
        alignIcon={alignIcon}
        iconSize={iconSize}
        color={color}
        iconColor={iconColor}
        viewStyle={viewStyle}
        style={style}>
        {children}
      </TextWithIcon>
    </Pressable>
  )
})

export default PressableTextIcon
