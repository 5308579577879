import { useDimensions, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native'
import RNModal from 'react-native-modal'

import CONSTANTS from '../constants/constants'
import appUtils from '../utils/appUtils'
import { ModalProps } from './type'

export default function Modal({ children, backdropOpacity, visible, style, fullWidth, onClose, ...props }: ModalProps) {
  const { colorScheme } = useTheme()
  const { height, width } = useWindowDimensions()
  const { isSmallDevice } = useDimensions()

  const modalWidth = useMemo(() => {
    if (fullWidth === true || (fullWidth === undefined && isSmallDevice)) return '100%'

    return CONSTANTS.modalWidth
  }, [fullWidth, isSmallDevice])

  return (
    <RNModal
      isVisible={visible}
      backdropOpacity={backdropOpacity ?? appUtils.getModalOpacity(colorScheme)}
      propagateSwipe
      statusBarTranslucent
      swipeDirection={[]}
      style={[styles.modalContainer, { width: modalWidth }]}
      deviceHeight={height}
      deviceWidth={width}
      animationIn={!isSmallDevice && !!fullWidth ? 'slideInUp' : 'slideInRight'}
      animationOut={!isSmallDevice && !!fullWidth ? 'slideOutDown' : 'slideOutRight'}
      hideModalContentWhileAnimating
      onBackButtonPress={onClose}
      onBackdropPress={onClose}
      {...props}>
      <View style={[styles.modal, style, { borderRadius: Platform.select({ ios: 10, android: 10 }) }]}>{children}</View>
    </RNModal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    height: '100%',
    margin: 0,
  },
  modal: {
    backgroundColor: 'white',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
})
