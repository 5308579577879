import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection, LotType, lotTypeValues } from '../../types/types'
import LotTypeCard from '../cards/LotTypeCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: LotType | undefined) => void
  required?: boolean
  selectedLotType?: LotType
}

export default function LotTypeSelector({ onChange, selectedLotType, ...props }: Props) {
  const { i18n } = useLanguage()

  const [data] = useState<LotType[]>(lotTypeValues)

  const render = ({ item }: SectionListRenderItemInfo<LotType, ListSection<LotType>>, onPress?: () => void) => {
    return <LotTypeCard lotType={item} onPress={onPress} selected={item === selectedLotType} spacing={['all']} />
  }

  const handleOnChange = (newValue: LotType | undefined) => {
    onChange(newValue)
  }

  return (
    <SelectInput
      id={'value'}
      data={data}
      value={selectedLotType}
      loading={false}
      refresh={undefined}
      title={i18n.t('LOT_TYPE')}
      screenTitle={i18n.t('LOT_TYPE')}
      noDataMessage={i18n.t('NO_LOT_TYPE')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => i18n.t(item.label)}
      deferredTimeout={0}
      disableLoadAfterMount
      autoSelectIfUnique
      {...props}
    />
  )
}
