import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import MeasurerCreateScreen from '../screens/MeasurerCreate/MeasurerCreateScreen'
import { MeasurerCreateViewProps } from '../screens/MeasurerCreate/MeasurerCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type MeasurerCreateModalProps = {
  controller: ModalController<MeasurerCreateViewProps>
} & Pick<MeasurerCreateViewProps, 'onCreateMeasurer'>

export default function MeasurerCreateModal({ controller, onCreateMeasurer }: MeasurerCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <MeasurerCreateScreen ref={ref} onCreateMeasurer={onCreateMeasurer} controller={controller} />
    </BaseServiceModal>
  )
}
