import { IM, IMStyle } from '@infominds/react-native-components'
import React, { memo, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import CONSTANTS from '../constants/constants'

interface Props {
  text: string
  width: number
}

const VerticalText = memo(function VerticalText({ text, width }: Props) {
  const first = useRef(true)
  const [height, setHeight] = useState(0)
  const [textHeight, setTextHeight] = useState(0)

  return (
    <IM.View
      style={[
        styles.container,
        { width, minHeight: textHeight < CONSTANTS.verticalTextMinHeight ? textHeight + 10 : CONSTANTS.verticalTextMinHeight },
      ]}
      onLayout={e => setHeight(e.nativeEvent.layout.height)}>
      <IM.Text
        style={[styles.text, styles.textTransform, { width: height }]}
        numberOfLines={1}
        onTextLayout={e => {
          if (first.current) {
            setTextHeight(e.nativeEvent.lines[0].width)
            first.current = false
          }
        }}>
        {text}
      </IM.Text>
    </IM.View>
  )
})

export default VerticalText

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: IMStyle.palette.white,
    fontSize: IMStyle.typography.fontSizeRegular,
    textAlign: 'center',
  },
  textTransform: {
    transform: [{ rotate: '270deg' }],
  },
})
