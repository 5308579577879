import { IM, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useMemo } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Organization } from '../../apis/apiResponseTypes'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import OrganizationCreateModal from '../../modals/OrganizationCreateModal'
import { ThemeColorExpanded } from '../../types'
import TimeUtils from '../../utils/TimeUtils'
import { OrganizationCreateViewProps } from '../OrganizationCreate/OrganizationCreateView'

export interface OrganizationTableData {
  organizationId: number
  organizationName: string
  vatNumber: string
  addDate: string
  expirationDate: string
}

export default function AllOrganizationsView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const navigation = useNavigation()

  const { isAdmin } = useAuthentication()
  const { allOrganizations, fetchSpecificData, currentOrganization } = useData()
  const deleteAlert = useDeleteAlert()
  const { alert } = useAlert()
  const createOrganizationModal = useModalController<OrganizationCreateViewProps>()

  const allOrganizationsTableHeader: TableHeaderData<OrganizationTableData>[] = useMemo(
    () => [
      { title: i18n.t('ORGANIZATION_ID'), property: 'organizationId' },
      { title: i18n.t('NAME'), property: 'organizationName' },
      { title: i18n.t('VAT_NUMBER'), property: 'vatNumber' },
      { title: i18n.t('DATE'), property: 'addDate', isDate: true },
      { title: i18n.t('EXPIRATION_DATE'), property: 'expirationDate', isDate: true },
    ],
    [i18n]
  )

  const allOrganizationsTableData: OrganizationTableData[] = useMemo(() => {
    return allOrganizations.map(org => ({
      organizationId: org.organizationId,
      organizationName: org.organizationName,
      vatNumber: org.vatNumber,
      addDate: TimeUtils.format(org.addDate, language),
      expirationDate: TimeUtils.format(org.expirationDate, language),
    }))
  }, [allOrganizations, language])

  function onNewOrganizationCreated(organization: Organization) {
    createOrganizationModal.close()
    if (currentOrganization?.organizationId) {
      fetchSpecificData(['organizations'], { organizationId: currentOrganization?.organizationId, isAdmin }).catch(console.error)
      navigation.navigate('BottomTab', {
        screen: 'AllOrganizationsStack',
        params: { screen: 'Organization', params: { organizationId: Number(organization.organizationId) } },
      })
    }
  }

  function handleNewOrganizationPress() {
    createOrganizationModal.show({})
  }

  function handleOrganizationEdit(organization: OrganizationTableData) {
    navigation.navigate('BottomTab', {
      screen: 'AllOrganizationsStack',
      params: { screen: 'Organization', params: { organizationId: Number(organization.organizationId) } },
    })
  }

  //TODO: fix delete
  function handleOrganizationDelete(organization: OrganizationTableData) {
    const selectedOrganization = allOrganizations.find(org => org.organizationId === organization.organizationId)
    if (!selectedOrganization?.organizationId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteOrganization(selectedOrganization?.organizationId)
            .then(() => {
              if (currentOrganization?.organizationId) {
                fetchSpecificData(['organizations'], { organizationId: currentOrganization?.organizationId, isAdmin }).catch(console.error)
              }
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  if (!isAdmin) return <></>

  return (
    <>
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <TableWrapper
            tableContainerSpacing={'all'}
            headerData={allOrganizationsTableHeader}
            tableData={allOrganizationsTableData}
            hasPaging
            maxTableHeight={730}
            showNewItemRow
            onNewItemPress={handleNewOrganizationPress}
            hasEditAndDelete
            onEditPress={handleOrganizationEdit}
            onDeletePress={handleOrganizationDelete}
            flexArray={[2, 6, 4, 3, 3, 1]}
          />
        </IM.View>
      </ScrollView>
      <OrganizationCreateModal controller={createOrganizationModal} onCreateOrganization={onNewOrganizationCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
})
