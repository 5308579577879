import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, Utils } from '@infominds/react-native-components'
import React from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, SectionList as RNSectionList, SectionListProps } from 'react-native'

import '../constants/EventKeys'

import CONSTANTS from '../constants/constants'
import { InfiniteLoadingType } from '../types'
import { FiniteLoadList, InfiniteLoadList, ListSection } from '../types/types'
import NoEntry from './NoEntry'
import RefreshControl from './RefreshControl'
import SkeletonCard from './skeleton/SkeletonCard'

type Props<T> = (FiniteLoadList | InfiniteLoadList) & {
  sections: ListSection<T>[]
  loading: InfiniteLoadingType
  noDataIcon?: IconProp
  noDataMessage: string
  errorMessage?: string
  noDataSection?: string
  loadingSection?: string
  skeletonElements?: number
  skeletonComponent?: React.ReactNode
  skeletonTopSpacing?: boolean
  closeEndThreshold?: number
  hideSectionTitle?: boolean
  ListHeaderComponent?: React.ReactElement | null
  hideButtonId?: string
} & Pick<
    SectionListProps<T, ListSection<T>>,
    'keyboardShouldPersistTaps' | 'contentContainerStyle' | 'renderItem' | 'keyExtractor' | 'onScroll' | 'onRefresh' | 'indicatorStyle'
  >

export default function SectionList<T>({
  sections,
  loading,
  noDataIcon = ['fal', 'binary-slash'],
  noDataSection,
  loadingSection,
  noDataMessage,
  skeletonElements = CONSTANTS.skeletonCards,
  skeletonComponent = <SkeletonCard size="small" />,
  skeletonTopSpacing,
  allDataLoaded,
  errorMessage,
  hideSectionTitle,
  keyboardShouldPersistTaps = 'handled',
  contentContainerStyle,
  closeEndThreshold,
  onRefresh,
  ...others
}: Props<T>) {
  const { keyExtractor, onScroll, onLoadMore } = others

  const showSkeleton = loading === 'reloading'

  const renderSection = (title?: string) => {
    if (!title) return <></>

    return (
      <IM.View spacing="all">
        <IM.Text>{title}</IM.Text>
      </IM.View>
    )
  }

  const renderNoData = () => {
    return (
      <>
        {(loading === false || loading === 'catched') && (
          <>
            {!hideSectionTitle && renderSection(noDataSection)}
            <NoEntry description={noDataMessage} icon={noDataIcon} error={loading === 'catched'} errorMessage={errorMessage} />
          </>
        )}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <>
        {showSkeleton && (
          <>
            {!hideSectionTitle && renderSection(loadingSection)}
            <IM.View spacing={['bottom', 'horizontal']}>
              {Array(skeletonElements)
                .fill(0)
                .map((_, index) => {
                  return (
                    <IM.View spacing={[skeletonTopSpacing && index === 0 ? 'top' : 'none', 'bottom']} key={`SkeletonDataPassword-${index}`}>
                      {skeletonComponent}
                    </IM.View>
                  )
                })}
            </IM.View>
          </>
        )}
        {loading === 'loadMore' && !allDataLoaded && <IM.View spacing={['bottom', 'horizontal']}>{skeletonComponent}</IM.View>}
      </>
    )
  }

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()

    onScroll?.(e)
    if (closeEndThreshold === undefined) return
  }

  return (
    <RNSectionList
      initialNumToRender={Platform.OS === 'web' ? Utils.sum(sections, item => item.data.length) : undefined} // bug in react-native shows only first 10 items in web (the error was in AccessGroupSelector)
      scrollEnabled={!showSkeleton}
      sections={sections}
      renderSectionHeader={({ section }) => renderSection(section.title)}
      ListFooterComponent={renderFooter}
      ListEmptyComponent={renderNoData}
      keyExtractor={keyExtractor}
      stickySectionHeadersEnabled={false}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      refreshControl={onRefresh ? <RefreshControl refreshing={false} onRefresh={onRefresh} /> : undefined}
      contentContainerStyle={[{ marginHorizontal: IMLayout.horizontalMargin }, contentContainerStyle]}
      onEndReachedThreshold={0.5}
      onEndReached={onLoadMore}
      onScroll={handleScroll}
      {...others}
    />
  )
}
