import { useDidUpdate } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'

import { STORAGE_KEYS } from '../constants/keys'
import { useData } from '../contexts/DataContext'
import { BluetoothDevice, LastConnectedInfo } from '../types'

export const useConnectedDevices = () => {
  const { connectedDevices, setConnectedDevices } = useData()
  const [lastConnectedInfo, setLastConnectedInfo] = useState<LastConnectedInfo | undefined>(undefined)
  const [lastConnectedInfoOk, setLastConnectedInfoOk] = useState(false)

  useEffect(() => {
    const loadLastConnectedInfo = async () => {
      const lastConnectedInfoString = await AsyncStorage.getItem(STORAGE_KEYS.LAST_CONNECTED_INFO)
      if (lastConnectedInfoString) {
        setLastConnectedInfo(JSON.parse(lastConnectedInfoString) as LastConnectedInfo)
      }
    }
    loadLastConnectedInfo()
      .catch(console.error)
      .finally(() => setLastConnectedInfoOk(true))
  }, [])

  useDidUpdate(() => {
    if (!lastConnectedInfoOk) return
    const saveLastConnectedInfo = async () => {
      if (lastConnectedInfo) {
        await AsyncStorage.setItem(STORAGE_KEYS.LAST_CONNECTED_INFO, JSON.stringify(lastConnectedInfo))
      } else {
        await AsyncStorage.removeItem(STORAGE_KEYS.LAST_CONNECTED_INFO)
      }
    }
    saveLastConnectedInfo().catch(console.error)
  }, [lastConnectedInfo])

  const addDevice = (device: BluetoothDevice) => {
    try {
      setConnectedDevices([...connectedDevices, device])
    } catch (error) {
      console.error('Failed to save packages to AsyncStorage', error)
    }
  }

  const updateDevice = (device: BluetoothDevice) => {
    const deviceToUpdate = connectedDevices.find(p => p.id === device.id)
    if (deviceToUpdate) {
      Object.assign(deviceToUpdate, device)
    }
    setConnectedDevices(connectedDevices)
  }

  const deleteDevice = (id: string) => {
    try {
      const updatedDevices = connectedDevices.filter(d => d.id !== id)
      setConnectedDevices(updatedDevices)
    } catch (error) {
      console.error('Failed to delete package from AsyncStorage', error)
    }
  }

  const setUseWithoutDevice = () => {
    setLastConnectedInfo({
      lastConnectedMeter: undefined,
      lastConnectedCaliber: undefined,
      continueWithoutDevice: true,
    })
  }

  return { addDevice, deleteDevice, updateDevice, lastConnectedInfo, setLastConnectedInfo, setUseWithoutDevice, lastConnectedInfoOk }
}
