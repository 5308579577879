import { NavigationState, PartialState, RouteProp, useNavigationState } from '@react-navigation/native'
import { useEffect, useMemo, useState } from 'react'

import useAsyncStorage from '../../../hooks/useAsyncStorage'
import { BottomTabParamList, RootStackParamList } from '../../../navigation/types'

type NavigationRoute<
  ParamList extends RootStackParamList = RootStackParamList,
  RouteName extends keyof ParamList = keyof RootStackParamList,
> = RouteProp<ParamList, RouteName> & {
  state?: NavigationState | PartialState<NavigationState>
}

export default function useDrawerRoute(storageKey: string, bottomTabName: string, defaultRoute?: keyof BottomTabParamList) {
  const state = useNavigationState(s => s)
  const { save, load, remove } = useAsyncStorage<{ bottomTab: string; stackName: string }>(storageKey)
  const bottomTabRoute = useMemo(() => state?.routes?.find(r => r.name === bottomTabName) as NavigationRoute | undefined, [state?.routes])
  const [lastRoute, setLastRoute] = useState<null | string>(null)
  const route = useMemo(() => (bottomTabRoute ? getStackName(bottomTabRoute) : lastRoute) ?? defaultRoute, [bottomTabRoute, lastRoute])

  useEffect(() => {
    const stack = state?.routes?.find(r => r.name !== bottomTabName)

    // if the stack is already loaded, save the current bottom tab route
    if (bottomTabRoute) {
      const screen = getStackName(bottomTabRoute)
      if (stack?.name && screen) save({ bottomTab: screen, stackName: stack?.name }).catch(console.error)
      else remove().catch(console.error)
      return
    }

    // otherwise load the last route and compare them. if they are equal then assume the last route as the current one
    load()
      .then(data => {
        if (!data || stack?.name !== data.stackName) return
        setLastRoute(data.bottomTab)
      })
      .catch(console.error)
  }, [state?.routes])

  return { route }
}

function getStackName(currentRoute: NavigationRoute) {
  if (currentRoute?.params && 'screen' in currentRoute.params && currentRoute?.params.screen) return String(currentRoute?.params.screen)
  if (currentRoute.state) {
    const firstRouteName = currentRoute.state?.routes?.at(0)?.name
    if (firstRouteName) return firstRouteName
  }
  return currentRoute?.name
}
