import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react'

import { TabNavigationScreen } from '../navigation/types'

export interface MenuContextProps<T extends string = string> {
  tabs: TabNavigationScreen<T>[]
  maxDynamicElements: number
  previousRoute: string | undefined
  onRouteChange: (prevRoute: T | undefined) => void
  tabBarHidden: boolean
  setTabBarHidden: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

interface Props<T extends string> {
  children: ReactNode
  bottomTabs: TabNavigationScreen<T>[]
  maxDynamicElements: number
  ignoreStorage?: boolean
}

export const MenuProvider = <T extends string>({ children, bottomTabs, maxDynamicElements }: Props<T>) => {
  const [tabs, setTabs] = useState<TabNavigationScreen<T>[]>(bottomTabs)
  const [prevRoute, setPrevRoute] = useState<T | undefined>(undefined)
  const [tabBarHidden, setTabBarHidden] = useState(false)

  useEffect(() => {
    setTabs(bottomTabs)
  }, [bottomTabs])

  const handleRouteChange = useCallback((newPrevRoute: T | undefined) => {
    setPrevRoute(newPrevRoute)
  }, [])

  return (
    <MenuContext.Provider
      value={
        {
          tabs,
          maxDynamicElements,
          previousRoute: prevRoute,
          onRouteChange: handleRouteChange,
          setTabBarHidden,
          tabBarHidden,
        } as unknown as MenuContextProps<string>
      }>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuContext
