import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Typology } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import TypologyCard from '../cards/TypologyCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Typology | undefined) => void
  required?: boolean
  selectedTypologyId?: number
}

export default function WoodSelector({ onChange, selectedTypologyId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { typologies, organizationId, setTypologies } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(typologies, search, ['typologyName', 'typologyId', 'typologyCode'])

  const render = ({ item }: SectionListRenderItemInfo<Typology, ListSection<Typology>>, onPress?: () => void) => {
    return <TypologyCard typology={item} onPress={onPress} selected={item.typologyId === selectedTypologyId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Typology | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveTypology(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveTypology = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postTypology({ typologyName: inputValue, organizationId: organizationId })
      const res = await api.getTypologies({ organizationId: organizationId })
      setTypologies(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedTypology = useMemo(() => typologies?.find(elem => elem.typologyId === selectedTypologyId), [typologies, selectedTypologyId])

  return (
    <SelectInput
      id={'typologyId'}
      data={filteredData}
      value={selectedTypology}
      loading={false}
      refresh={undefined}
      title={i18n.t('TYPOLOGY')}
      screenTitle={i18n.t('TYPOLOGY')}
      noDataMessage={i18n.t('NO_TYPOLOGY')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.typologyName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      {...props}
    />
  )
}
