import { IM, IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren, useContext, useMemo } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, ScrollView, ScrollViewProps } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import FormContext from '../contexts/FormContext'
import { InfiniteLoadingType } from '../types'
import KeyboardAwareIOS from './KeyboardAwareIOS'
import ListSpacer from './ListSpacer'
import SkeletonScrollView from './skeleton/SkeletonScrollView'

interface Props {
  loading?: InfiniteLoadingType
  disableHideKeyboardOnScroll?: boolean
  listSpacer?: boolean
  scrollDisabled?: boolean
}

export default function ScrollViewForm({
  children,
  disableHideKeyboardOnScroll,
  loading = false,
  listSpacer = true,
  scrollDisabled,
  ...others
}: Props & PropsWithChildren<Omit<ScrollViewProps, 'style'>>) {
  const { colorScheme } = useTheme()
  const { bottom } = useSafeAreaInsets()
  const { isSmallDevice } = useDimensions()
  const formContext = useContext(FormContext)
  const paddingBottom = useMemo(
    () => (Platform.OS === 'ios' && !isSmallDevice ? bottom + 2 * IMLayout.horizontalMargin : 2 * IMLayout.horizontalMargin),
    [isSmallDevice]
  )

  const handleOnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (formContext) formContext.contentOffset.current = event.nativeEvent.contentOffset
    !disableHideKeyboardOnScroll && Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(event)
  }

  return (
    <KeyboardAwareIOS>
      <ScrollView
        style={[IMLayout.flex.f1]}
        keyboardShouldPersistTaps={disableHideKeyboardOnScroll ? 'never' : 'handled'}
        scrollEnabled={loading !== 'reloading' && !scrollDisabled}
        onScroll={handleOnScroll}
        scrollEventThrottle={1000}
        indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}
        {...others}>
        {listSpacer && <ListSpacer />}
        {loading === false ? (
          <IM.View style={{ paddingHorizontal: IMLayout.horizontalMargin, paddingBottom: paddingBottom }} onStartShouldSetResponder={() => true}>
            {children}
          </IM.View>
        ) : (
          <SkeletonScrollView />
        )}
      </ScrollView>
    </KeyboardAwareIOS>
  )
}
