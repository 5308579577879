import BleManager from 'react-native-ble-manager'

import { Customer, Grade, Quality, Typology, Wood } from '../apis/apiResponseTypes'
import { PRINTER_CHARACTERISTIC_UUID, PRINTER_SERVICE_UUID } from '../constants/Bluetooth'
import { Lot, Package } from '../types/types'

const ESC = 0x1b
const LINE_FEED = 0x0a

export const stringToByteArray = (str: string): number[] => {
  return Array.from(str).map(char => char.charCodeAt(0))
}

export const padString = (str: string, length: number): string => {
  return str.padEnd(length, ' ')
}

export const formatLine = (col1: string, col2: string, col3: string): string => {
  return padString(col1, 12) + padString(col2, 12) + padString(col3, 12)
}

export const formatPrintData = (
  columns: [string, string, string],
  items: { [key: string]: string }[],
  additionalData?: { [key: string]: string },
  footerData?: { [key: string]: string }
): number[] => {
  const byteArray: number[] = []

  byteArray.push(ESC, 0x21, 0x10)
  byteArray.push(LINE_FEED)

  if (additionalData) {
    Object.entries(additionalData).forEach(([key, value]) => {
      byteArray.push(...stringToByteArray(`${key}: ${value}`))
      byteArray.push(LINE_FEED)
    })
  }

  byteArray.push(LINE_FEED)
  byteArray.push(...stringToByteArray(formatLine(...columns)))
  byteArray.push(LINE_FEED)

  items.forEach(item => {
    const line = formatLine(item[columns[0]], item[columns[1]], item[columns[2]])
    byteArray.push(...stringToByteArray(line))
    byteArray.push(LINE_FEED)
  })

  if (footerData) {
    Object.entries(footerData).forEach(([key, value]) => {
      byteArray.push(...stringToByteArray(`${key}: ${value}`))
      byteArray.push(LINE_FEED)
    })
  }

  byteArray.push(LINE_FEED)
  byteArray.push(LINE_FEED, LINE_FEED)

  return byteArray
}

export const writeToPrinter = async (address: string, data: number[]) => {
  try {
    await BleManager.write(address, PRINTER_SERVICE_UUID, PRINTER_CHARACTERISTIC_UUID, data)
    console.log('Data written to printer')
  } catch (error) {
    console.error('Failed to write data to printer', error)
  }
}

export const printPackage = async (
  address: string,
  pkg: Package,
  customer?: Customer,
  wood?: Wood,
  typology?: Typology,
  grade?: Grade,
  quality?: Quality
) => {
  const additionalData = {
    'Package Name': pkg.name ?? '',
    Customer: customer?.customerName ?? '',
    Wood: wood?.woodName ?? '',
    Typology: typology?.typologyName ?? '',
    Grade: grade?.gradeName ?? '',
    Quality: quality?.qualityName ?? '',
  }

  const footerData = {
    'Number of Boards': pkg.boards.length.toString(),
    Volume: pkg.volume.toString() + ' cubic meters',
  }

  const data = formatPrintData(
    ['Tavola', 'Lunghezza', 'Larghezza'],
    pkg.boards.map(board => ({
      Tavola: board.boardNumber.toString(),
      Lunghezza: board.boardLength.toString(),
      Larghezza: board.boardWidth.toString(),
    })),
    additionalData,
    footerData
  )

  await writeToPrinter(address, data)
}

export const printLot = async (address: string, lot: Lot, customer?: Customer, wood?: Wood, quality?: Quality) => {
  const additionalData = {
    Customer: customer?.customerName ?? '',
    Wood: wood?.woodName ?? '',
    Quality: quality?.qualityName ?? '',
  }

  const footerData = {
    'Number of Trunks': lot.trunks.length.toString(),
    Volume: lot.volume.toString() + ' cubic meters',
  }

  const data = formatPrintData(
    ['Tronco', 'Lunghezza', 'Diametro'],
    lot.trunks.map(trunk => ({
      Tronco: trunk.trunkNumber.toString(),
      Lunghezza: trunk.length.toString(),
      Diametro: trunk.diameter_1.toString(),
    })),
    additionalData,
    footerData
  )

  await writeToPrinter(address, data)
}
