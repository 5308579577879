import { useContext } from 'react'

import BaseTextInputContext from '../contexts/BaseTextInputContext'

export default function useBaseTextInput() {
  const context = useContext(BaseTextInputContext)

  if (context === undefined) {
    throw new Error('useBaseTextInput() must be called inside BaseTextInputProvider')
  }

  return context
}
