import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Typology } from '../../apis/apiResponseTypes' // Assuming Typology type is defined

export type TypologyCardProps = { typology: Typology } & CardProps

export default function TypologyCard({ typology, ...cardProps }: TypologyCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{typology.typologyName}</IM.Text>
    </IM.Card>
  )
}
