import { IM, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import Pressable from '../../components/Pressable'
import PressableIcon from '../../components/PressableIcon'
import TextWithIcon from '../../components/TextWithIcon'
import { useData } from '../../contexts/DataContext'
import LogoutModal from '../../modals/LogoutModal'
import { ThemeColorExpanded } from '../../types'
import BaseHeader from '../BaseHeader'

const SettingsHeader: React.FC<{ setIsBusy: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setIsBusy }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const controller = useModalController()
  const { organizationId, fetchData } = useData()

  const handleLogout = () => {
    controller.show()
  }

  const handleDownloadData = () => {
    const fetching = async () => {
      if (!organizationId) return
      setIsBusy(true)
      await fetchData({ organizationId: organizationId })
      setIsBusy(false)
    }

    fetching().catch(error => {
      console.error('fetching error', error)
    })
  }

  const rightComponent = (
    <>
      <IM.View style={styles.iconRow}>
        <PressableIcon icon={['fal', 'cloud-arrow-down']} size={25} color={theme.header.main.text.primary} onPress={() => handleDownloadData()} />
        <Pressable onPress={() => handleLogout()}>
          <TextWithIcon alignIcon="right" icon={['fal', 'person-to-door']} iconSize={22} color={theme.header.main.error}>
            {i18n.t('LOGOUT')}
          </TextWithIcon>
        </Pressable>
        <LogoutModal controller={controller} />
      </IM.View>
    </>
  )

  return <BaseHeader title={i18n.t('SETTINGS')} rightComponent={rightComponent} />
}

const styles = StyleSheet.create({
  iconRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})

export default SettingsHeader
