import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, Layout, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import { BottomTabBarProps, BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React from 'react'
import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import useMenu from '../../hooks/useMenu'
import { BottomTabParamList, RootStackParamList } from '../../navigation/types'
import navigationUtils from '../../utils/navigationUtils'

const BottomTab = createBottomTabNavigator()

const SETTINGS = {
  iconSize: 22,
}

interface TabNavigatorProps {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: BottomTabNavigationOptions
}

export default function TabNavigator<RouteName extends keyof BottomTabParamList>({ initialRouteName, screenOptions }: TabNavigatorProps) {
  const { tabs, maxDynamicElements } = useMenu<RouteName>()
  const { i18n } = useLanguage()

  if (tabs.length === 0) {
    if (Platform.OS !== 'web') {
      return <IM.LoadingSpinner isVisible />
    } else {
      return <></>
    }
  }

  return (
    <BottomTab.Navigator initialRouteName={initialRouteName} tabBar={TabBarProvider} screenOptions={{ headerShown: false, ...screenOptions }}>
      {navigationUtils.sortTabs(tabs, maxDynamicElements).map(tab => {
        return (
          <BottomTab.Screen
            key={`BottomTabScreen${tab.name}`}
            name={tab.name}
            component={tab.component}
            listeners={tab.listeners}
            options={{
              ...tab.options,
              title: i18n.t(tab.title),

              tabBarIcon: iconProps => TabBarIcon(iconProps, tab.icon, tab.iconSize),
            }}
          />
        )
      })}
    </BottomTab.Navigator>
  )
}

function TabBarProvider(tabProps: BottomTabBarProps) {
  return <TabBar {...tabProps} />
}

function TabBar(tabProps: BottomTabBarProps) {
  const { theme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { maxDynamicElements } = useMenu()
  const bottomInsets =
    tabProps.insets.bottom === 0 ? 8 : Platform.OS === 'android' && !isSmallDevice ? tabProps.insets.bottom + 8 : tabProps.insets.bottom
  const justifyContent = Layout.isSmallDevice ? 'space-evenly' : 'center'

  const menu = useMenu()

  if (menu.tabBarHidden) return <IM.View style={[styles.tabBarHiddenView, { borderColor: theme.input.border, height: bottomInsets + 1 }]} />

  return (
    <View
      style={
        !menu.tabBarHidden && [
          styles.main,
          styles.tabContainer,
          {
            paddingBottom: bottomInsets,
            backgroundColor: theme.tabNavigator.background,
            justifyContent: justifyContent,
          },
          IMLayout.shadow,
          Platform.OS === 'android' && { ...styles.androidBorder, elevation: 0, borderColor: theme.input.border },
        ]
      }>
      {tabProps.state.routes.map((s, index) => {
        if (index < maxDynamicElements) {
          return <TabContent key={`BottomTab${s.key}`} routeKey={s.key} index={index} tabProps={tabProps} />
        } else {
          return null
        }
      })}
    </View>
  )
}

function TabBarIcon(iconProps: { focused: boolean; color: string; size: number }, tabIcon?: IconProp, customIconSize?: number) {
  if (!tabIcon) return undefined
  return <IM.Icon size={customIconSize ?? iconProps.size} icon={tabIcon} color={iconProps.color} />
}

function TabContent(props: { routeKey: string; index: number; tabProps: BottomTabBarProps }) {
  const { theme } = useTheme()

  const { state, descriptors, navigation } = props.tabProps
  const route = state.routes.find(r => r.key === props.routeKey)
  const descriptor = descriptors[props.routeKey]
  const isFocused = state.index === props.index
  const label = descriptor?.options?.title || route?.name || ''

  const onPress = () => {
    const event = navigation.emit({
      type: 'tabPress',
      target: props.routeKey,
      canPreventDefault: true,
    })

    const bottomTabName: keyof RootStackParamList = 'BottomTab'
    if (Platform.OS === 'web' && route?.name !== 'MoreEmpty') {
      if (route?.state) {
        const firstRoute = route?.state.routeNames?.at(0)

        if (firstRoute === undefined) {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: bottomTabName,
                params: {
                  screen: route?.name,
                },
              },
            ],
          })
        } else {
          navigation.navigate(bottomTabName, { screen: route?.name })
        }
      }
    }

    if (!isFocused && !event.defaultPrevented && route) {
      navigation.navigate(route.name, route.params)
    }
  }

  return (
    <TouchableOpacity
      key={`Tab${props.routeKey}`}
      testID={descriptor?.options?.tabBarTestID}
      onPress={onPress}
      //hitSlop={{ top: 24, bottom: 100 }}
      style={[
        styles.tab,
        Layout.isSmallDevice ? styles.tabSmallDevice : styles.tabLargeDevice,
        {
          backgroundColor: isFocused ? theme.tabNavigator.focused.background : undefined,
        },
      ]}>
      {isFocused && (
        <>
          <View style={[styles.iconContainer, { backgroundColor: theme.tabNavigator.focused.iconBackground }]}>
            {!!descriptor?.options?.tabBarIcon &&
              descriptor?.options?.tabBarIcon({
                focused: isFocused,
                color: theme.tabNavigator.focused.icon ?? '',
                size: SETTINGS.iconSize,
              })}
          </View>
          <Text style={[styles.text, { color: theme.tabNavigator.focused.text }]}>{label}</Text>
        </>
      )}
      {!isFocused && (
        <View style={styles.iconContainer}>
          {!!descriptor?.options?.tabBarIcon &&
            descriptor?.options?.tabBarIcon({
              focused: isFocused,
              color: theme.tabNavigator.unFocused.icon ?? '',
              size: SETTINGS.iconSize,
            })}
        </View>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  tabBarHiddenView: {
    borderTopWidth: 1,
    width: '100%',
  },
  main: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tabContainer: {
    flexDirection: 'row',
    padding: 8,
  },
  tab: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 100,
  },
  tabSmallDevice: {},
  tabLargeDevice: {
    marginHorizontal: 25,
  },
  iconContainer: {
    height: 42,
    aspectRatio: 1,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    alignSelf: 'center',
    paddingRight: 18,
    paddingLeft: 8,
  },
  androidBorder: { borderTopWidth: 1 },
})
