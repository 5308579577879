import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { Platform, StyleSheet } from 'react-native'

import HeaderWithPressable from './HeaderWithPressable'

interface Props {
  title: string
  description?: string
  icon?: IconProp
  onPress?: () => void
  extraIcon?: ReactNode
  sync?: boolean
}

export default function HeaderWithIcons({ title, icon, description, onPress, sync = false }: Props) {
  return (
    <IM.View style={styles.container}>
      <HeaderWithPressable title={title} icon={icon} description={description} onPress={onPress} />

      {sync && Platform.OS !== 'web'}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
})
