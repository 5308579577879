/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext, ReactNode, useContext } from 'react'
import { Peripheral } from 'react-native-ble-manager'

import { LastConnectedInfo } from '../types'

type BluetoothProviderProps = {
  children: ReactNode
}

type BluetoothContextType = {
  startScan: () => void
  scannedDevices: Peripheral[]
  isConnected: boolean
  setIsConnected: (isConnected: boolean) => void
  onLogin: () => void
  isScanning: boolean
  lastConnectedInfo: LastConnectedInfo | undefined
  lastConnectedInfoOk: boolean
  setLastConnectedInfo: React.Dispatch<React.SetStateAction<LastConnectedInfo | undefined>>
}

// empty initializasion of BluetoothContext for web
const BluetoothContext = createContext<BluetoothContextType | null>({
  startScan: () => {},
  scannedDevices: [],
  isConnected: false,
  setIsConnected: () => {},
  onLogin: () => {},
  isScanning: false,
  lastConnectedInfo: undefined,
  lastConnectedInfoOk: false,
  setLastConnectedInfo: () => {},
})

export function BluetoothContextProvider({ children }: BluetoothProviderProps) {
  return <>{children}</>
}

export const useBluetooth = () => {
  const context = useContext(BluetoothContext)
  if (!context) {
    throw new Error('useBluetooth must be used within a BluetoothProvider')
  }
  return context
}
