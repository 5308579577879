import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { useBluetooth } from '../../contexts/BluetoothContext'
import BaseServiceModal from '../../modals/BaseServiceModal'
import { ModalScreenRef } from '../../types'
import DevicesView from './DevicesView'

export type DevicesModalProps = {
  controller: ModalController
}

export default function DevicesModal({ controller }: DevicesModalProps) {
  const ref = createRef<ModalScreenRef>()
  const { setLastConnectedInfo } = useBluetooth()

  const handleConnectedDevices = () => {
    controller.close()
    setLastConnectedInfo({
      lastConnectedMeter: undefined,
      lastConnectedCaliber: undefined,
      continueWithoutDevice: true,
    })
  }

  const handleContinueWithoutDevice = () => {
    handleConnectedDevices()
  }

  return (
    <BaseServiceModal
      controller={controller}
      childRef={ref}
      onModalShow={function (): void {
        console.info('onModalShow')
      }}>
      <DevicesView
        onContinueWithoutDevice={() => {
          handleContinueWithoutDevice()
        }}
        onCloseModal={() => {
          controller.close()
        }}
      />
    </BaseServiceModal>
  )
}
