import { IM, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'

interface Props {
  style?: StyleProp<ViewStyle>
}

export default function HoverView({ style, children }: Props & PropsWithChildren) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const [isHover, setIsHover] = useState(false)

  return (
    <IM.View
      style={[isHover && { backgroundColor: theme.drawerNavigator.hover }, style]}
      //@ts-ignore working in web
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}>
      {children}
    </IM.View>
  )
}
