import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'
import { CustomerCreateViewProps } from '../screens/CustomerCreate/CustomerCreateView'
import CustomerCreateScreen from '../screens/CustomerCreate/CustomerCreateScreen'

export type CustomerCreateModalProps = {
  controller: ModalController<CustomerCreateViewProps>
} & Pick<CustomerCreateViewProps, 'onCreateCustomer'>

export default function CustomerCreateModal({ controller, onCreateCustomer }: CustomerCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <CustomerCreateScreen ref={ref} onCreateCustomer={onCreateCustomer} controller={controller} />
    </BaseServiceModal>
  )
}