import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Measurer } from '../../apis/apiResponseTypes'

export type MeasurerCardProps = { measurer: Measurer } & CardProps

export default function MeasurerCard({ measurer, ...cardProps }: MeasurerCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{measurer.measurerName}</IM.Text>
    </IM.Card>
  )
}
