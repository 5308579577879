import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import WoodCreateScreen from '../screens/WoodCreate/WoodCreateScreen'
import { WoodCreateViewProps } from '../screens/WoodCreate/WoodCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type WoodCreateModalProps = {
  controller: ModalController<WoodCreateViewProps>
} & Pick<WoodCreateViewProps, 'onCreateWood'>

export default function WoodCreateModal({ controller, onCreateWood }: WoodCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <WoodCreateScreen ref={ref} onCreateWood={onCreateWood} controller={controller} />
    </BaseServiceModal>
  )
}
