import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Screen } from '../../components/screen/Screen'
import BaseHeader from '../BaseHeader'
import LotsView from './LotsView'

export default function LotsScreen() {
  const { i18n } = useLanguage()

  return (
    <Screen>
      <Screen.Header>
        <BaseHeader title={i18n.t('LOTS')} />
      </Screen.Header>
      <Screen.Content>
        <LotsView />
      </Screen.Content>
    </Screen>
  )
}
