import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'
import PressableIcon from './PressableIcon'
import TextInput from './TextInput'

export type SearchFieldProps = { value: string | undefined; handleSearch: (text: string | undefined) => void }

export default function SearchField({ value, handleSearch }: SearchFieldProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const [searchOpen, setSearchOpen] = useState(false)
  const searchFieldRef = useRef<any>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // keep the search open if the value is not empty
      if (value) {
        return
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target)) {
        setSearchOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [value])

  const onSearch = (text: string) => {
    handleSearch(text.trim() === '' ? undefined : text)
  }

  const onSearchIconPress = () => {
    setSearchOpen(true)
  }

  return (
    <IM.View style={[IMLayout.flex.row, styles.container]} ref={searchFieldRef}>
      {!searchOpen && <PressableIcon icon={['fal', 'search']} size={14} onPress={onSearchIconPress} />}
      {searchOpen && (
        <TextInput
          autoFocus
          placeholder={i18n.t('SEARCH')}
          value={value}
          onChangeText={onSearch}
          focusBorderColor={theme.table.header.background}
          style={[styles.searchInput]}
          alwaysShowClearTextIcon
          textStyle={styles.searchInput}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  searchInput: { maxHeight: 38 },
  container: { alignItems: 'center', paddingVertical: 5, maxHeight: 46 },
})
