import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import OrganizationCreateScreen from '../screens/OrganizationCreate/OrganizationCreateScreen'
import { OrganizationCreateViewProps } from '../screens/OrganizationCreate/OrganizationCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type OrganizationCreateModalProps = {
  controller: ModalController<OrganizationCreateViewProps>
} & Pick<OrganizationCreateViewProps, 'onCreateOrganization'>

export default function OrganizationCreateModal({ controller, onCreateOrganization }: OrganizationCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <OrganizationCreateScreen ref={ref} onCreateOrganization={onCreateOrganization} controller={controller} />
    </BaseServiceModal>
  )
}
