import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'

import { useBluetooth } from '../../contexts/BluetoothContext'
import { useData } from '../../contexts/DataContext'
import { useConnectedDevices } from '../../hooks/useConnectedDevices'
import { BluetoothDevice } from '../../types'

export type DeviceCardProps = {
  device: BluetoothDevice
  onPress: () => void
  style?: ViewStyle
}

const DeviceCard = ({ device, onPress, style }: DeviceCardProps) => {
  const { addDevice, updateDevice } = useConnectedDevices()
  const { setLastConnectedInfo, connectToDevice } = useBluetooth()
  const { connectedDevices, setConnectedDevices } = useData()

  const handlePress = () => {
    const connect = async () => {
      await connectToDevice(device)
      setConnectedDevices([...connectedDevices, device])
      onPress()
      if (connectedDevices.find(d => d.id === device.id)) {
        device.lastConnectionDate = Date.now()
        updateDevice(device)
      } else {
        addDevice(device)
      }

      if (device.deviceType === 'caliber') {
        setLastConnectedInfo(prevState => {
          if (!prevState) return { lastConnectedCaliber: device, lastConnectedMeter: undefined, continueWithoutDevice: false }
          return { ...prevState, lastConnectedCaliber: device, continueWithoutDevice: false }
        })
      } else if (device.deviceType === 'meter') {
        setLastConnectedInfo(prevState => {
          if (!prevState) return { lastConnectedMeter: device, lastConnectedCaliber: undefined, continueWithoutDevice: false }
          return { ...prevState, lastConnectedMeter: device, continueWithoutDevice: false }
        })
      }
    }

    connect().catch(error => {
      console.error('Failed to connect to device', error)
    })
  }

  return (
    <IM.CardBasic style={[styles.card, style]} spacing="bottom" onPress={handlePress}>
      <View>{device.name && <Text style={styles.text}>{device.name}</Text>}</View>
    </IM.CardBasic>
  )
}

export default DeviceCard

const styles = StyleSheet.create({
  card: {
    padding: 10,
    backgroundColor: 'white',
  },
  text: {
    fontSize: 16,
    color: 'black',
  },
})
