import { useTheme } from '@infominds/react-native-components'
import * as React from 'react'
import { KeyboardAvoidingView, Platform, SafeAreaView, StyleSheet } from 'react-native'

import { ThemeProviderType } from '../../contexts/ThemeContext'
import LoginView, { LoginViewProps } from './LoginView'

export type LoginScreenProps = ThemeProviderType &
  LoginViewProps & {
    keyboardAvoiding?: boolean
    disableStatusBar?: boolean
  }

export default function LoginScreen() {
  const { theme } = useTheme()

  // needed to reset this value
  // if (Platform.OS === 'android' && disableStatusBar === false) StatusBar.setTranslucent(false)

  return (
    <SafeAreaView style={[styles.safeAreaView, { backgroundColor: theme.primary }]}>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.keyboardAvoidingView}>
        {
          /* {!disableStatusBar && (
                <StatusBar
                  translucent={false}
                  backgroundColor={themeContext?.theme.background}
                  barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'}
                />
              )}*/
          <LoginView />
        }
      </KeyboardAvoidingView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
})
