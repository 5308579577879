import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Wood } from '../../apis/apiResponseTypes'

export type WoodCardProps = { wood: Wood; showReduction?: boolean } & CardProps

export default function WoodCard({ wood, showReduction, ...cardProps }: WoodCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.View style={styles.card}>
        <IM.Text>{wood.woodName}</IM.Text>
        {showReduction && <IM.Text style={styles.reduction}>{wood.reduction.toString() + '%'}</IM.Text>}
      </IM.View>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  reduction: {
    marginLeft: 5,
  },
  card: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
