import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, SpacingProps, TextProps, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

export type TextWithIconProps = {
  icon?: IconProp
  alignIcon?: 'left' | 'right' | 'top'
  viewStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
  iconSize?: number
  iconColor?: string
  color?: string
  spacing?: SpacingProps
} & TextProps

const TextWithIcon = memo(function TextWithIcon({
  icon,
  viewStyle,
  iconStyle,
  iconColor,
  alignIcon = 'left',
  iconSize = 15,
  style,
  color,
  spacing,
  ...textProps
}: TextWithIconProps) {
  const { theme } = useTheme()

  const renderIcon = () => {
    if (icon) {
      return (
        <IM.Icon
          color={textProps.secondary || textProps.disabled ? theme.textDetail : color ?? iconColor ?? theme.text}
          style={[styles.icon, alignIcon === 'left' ? styles.iconLeft : alignIcon === 'right' ? styles.iconRight : {}, iconStyle]}
          icon={icon}
          size={iconSize}
        />
      )
    }
    return null
  }

  return (
    <IM.View style={[styles.container, viewStyle, alignIcon === 'top' && styles.containerColumn]} spacing={spacing}>
      {alignIcon === 'top' && renderIcon()}
      <IM.Text
        secondary={textProps.disabled}
        style={[styles.text, { color: textProps.primary ? undefined : color }, style, alignIcon === 'top' && styles.textTop]}
        {...textProps}
      />
      {['left', 'right'].includes(alignIcon) && renderIcon()}
    </IM.View>
  )
})

export default TextWithIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  containerColumn: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    justifyContent: 'center',
  },
  iconLeft: {
    marginRight: 7,
  },
  iconRight: {
    marginLeft: 7,
  },
  text: {
    textAlignVertical: Platform.OS === 'web' ? 'auto' : 'center',
    flexShrink: 1,
  },
  textTop: {
    marginTop: 5,
  },
})
