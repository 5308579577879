import { useAlert, useLanguage } from '@infominds/react-native-components'

export default function useUnsavedChangesAlert(title?: string, description?: string) {
  const { i18n } = useLanguage()
  const alert = useAlert()

  function show() {
    return new Promise<'discard' | 'cancel'>(resolve => {
      alert.alert(title ?? i18n.t('UNSAVED_CHANGES_TITLE'), description ?? i18n.t('DISCARD_UNSAVED_CHANGES'), [
        {
          text: i18n.t('DISCARD'),
          onPress: () => resolve('discard'),
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => resolve('cancel'),
          style: 'cancel',
        },
      ])
    })
  }

  return {
    show,
  }
}
