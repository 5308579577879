import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, LayoutChangeEvent, StyleSheet, type StyleProp, type ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import EmptyRow from './components/rows/EmptyRow'
import NewItemRow from './components/rows/NewItemRow'
import Row from './components/rows/Row'
import TableHeader from './components/rows/TableHeader'
import { Paging, SortProperty, TableHeaderData, TableHeaderIconData } from './types/types'
import TableUtils from './utils/TableUtils'

interface TableProps<T, S> {
  headerData: TableHeaderData<T>[]
  tableData: T[]
  subTableHeaderData?: TableHeaderData<S>[]
  subTableDataKey?: keyof T
  hasEditAndDelete?: boolean
  onDeletePress?: (item: T) => void
  onEditPress?: (item: T) => void
  showEmptyRow?: boolean
  showNewItemRow?: boolean
  onNewItemPress?: () => void
  maxHeight?: number
  style?: StyleProp<ViewStyle>
  widthArray?: number[]
  flexArray?: number[]
  paging?: Paging
}

export default function Table<T, S>({
  headerData,
  tableData,
  subTableHeaderData,
  subTableDataKey,
  style,
  maxHeight,
  showEmptyRow = true,
  flexArray,
  widthArray,
  showNewItemRow,
  onNewItemPress,
  hasEditAndDelete,
  onDeletePress,
  onEditPress,
  paging,
}: TableProps<T, S>) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { language } = useLanguage()
  const [newItemRowHeight, setNewItemRowHeight] = useState(0)
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())

  const flatListRef = useRef<FlatList>(null)

  //get the height of the new item row
  const handleNewItemRowLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout
    setNewItemRowHeight(height)
  }

  //calculate the max height of the scrollview
  const scrollViewMaxHeight = showNewItemRow && maxHeight ? maxHeight - newItemRowHeight : maxHeight

  const tableWidth = widthArray ? TableUtils().sum(widthArray) : '100%'

  const [sortProperty, setSortProperty] = useState<SortProperty<T>>({
    property: headerData[0].property,
    direction: 'asc',
  })

  const data: string[][] = useMemo(() => {
    const sortedData = TableUtils<T>().sortTableData(tableData, headerData, sortProperty, language)
    const convertedData = TableUtils<T>().convertTableDataToStringArray(sortedData, headerData)
    if (paging) {
      return convertedData.slice(paging.skip, paging.take)
    }
    return convertedData
  }, [tableData, headerData, sortProperty, paging])

  useEffect(() => {
    if (!paging || !flatListRef.current) return

    //scroll to the top when the page changes
    flatListRef.current.scrollToOffset({ offset: 0, animated: true })
  }, [paging])

  const header: TableHeaderIconData<T>[] = useMemo(() => {
    const newHeaderData: TableHeaderIconData<T>[] = [...headerData]
    return newHeaderData.map(item => {
      const sortIconData = TableUtils<T>().getSortIconData(sortProperty, item.property, theme)
      return {
        ...item,
        rightIcon: sortIconData.icon,
        rightIconPress: () => setSortProperty({ property: item.property, direction: sortIconData.direction }),
        rightIconColor: sortIconData.color,
      }
    })
  }, [headerData, sortProperty])

  function onEdit(idx: number) {
    if (!onEditPress) return
    const item: T = TableUtils<T>().getItemFromIndex(idx, tableData, headerData, sortProperty, language)
    onEditPress(item)
  }

  function onDelete(idx: number) {
    if (!onDeletePress) return
    const item: T = TableUtils<T>().getItemFromIndex(idx, tableData, headerData, sortProperty, language)
    onDeletePress(item)
  }

  function toggleRowExpansion(index: number) {
    const mainRowItem: T = TableUtils<T>().getItemFromIndex(index, tableData, headerData, sortProperty, language)
    if (!subTableDataKey) return
    console.log('toggleRowExpansion', mainRowItem[subTableDataKey] as S[])
    setExpandedRows(prev => {
      const newSet = new Set(prev)
      if (newSet.has(index)) {
        newSet.delete(index)
      } else {
        newSet.add(index)
      }
      return newSet
    })
  }

  function renderItem({ item, index }: { item: string[]; index: number }) {
    const mainRowItem: T = TableUtils<T>().getItemFromIndex(index, tableData, headerData, sortProperty, language)
    const isExpanded = expandedRows.has(index)

    return (
      <>
        <Row
          key={index}
          data={item}
          widthArray={widthArray}
          selectable
          flexArray={flexArray}
          rowStyle={[{ backgroundColor: TableUtils().getRowColorFromIndex(index, theme) }, styles.rows]}
          textStyle={{ color: theme.table.text }}
          onPress={() => toggleRowExpansion(index)}
          onDeletePress={() => {
            onDelete(index)
          }}
          onEditPress={() => {
            onEdit(index)
          }}
          hasEditAndDelete={hasEditAndDelete}
        />
        {isExpanded && subTableHeaderData && subTableDataKey && Array.isArray(mainRowItem[subTableDataKey]) && (
          <Table
            headerData={subTableHeaderData}
            tableData={mainRowItem[subTableDataKey] as S[]}
            style={[{ marginLeft: 20 }]}
            showEmptyRow={false}
            showNewItemRow={false}
            widthArray={widthArray}
            flexArray={flexArray}
          />
        )}
      </>
    )
  }

  return (
    <IM.View style={[IMLayout.shadow, styles.container, style, { width: tableWidth, maxHeight: maxHeight }]}>
      <FlatList
        ref={flatListRef}
        style={[{ maxHeight: scrollViewMaxHeight }]}
        data={data}
        renderItem={renderItem}
        ListHeaderComponent={
          <TableHeader headerData={header} style={styles.rows} flexArray={flexArray} widthArray={widthArray} hasEditAndDelete={hasEditAndDelete} />
        }
        stickyHeaderIndices={[0]}
        ListEmptyComponent={showEmptyRow && data.length === 0 ? <EmptyRow /> : null}
        initialScrollIndex={0}
      />
      {showNewItemRow && <NewItemRow onNewItemPress={onNewItemPress} onLayout={handleNewItemRowLayout} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: IMLayout.borderRadius,
    overflow: 'hidden',
  },
  rows: { paddingHorizontal: 10 },
})
