import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { SCREEN_CONSTANTS } from './screen/constants/constants'

type ListSpacerProps = {
  style?: StyleProp<ViewStyle>
  height?: number
}

export default function ListSpacer({ style, height }: ListSpacerProps) {
  return <IM.View style={[styles.view, !!height && { height }, style]} />
}

const styles = StyleSheet.create({
  view: { height: SCREEN_CONSTANTS.headerRadius },
})
