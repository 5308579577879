import { PathConfig, PathConfigMap } from '@react-navigation/native'

import {
  AllOrganizationsStackParamList,
  AttributesStackParamList,
  LotsStackParamList,
  OrganizationStackParamList,
  PackagesStackParamList,
  SettingsStackParamList,
} from './types'

type ConfigType = {
  initialRouteName?: keyof ReactNavigation.RootParamList | undefined
  screens: PathConfigMap<ReactNavigation.RootParamList>
}

// Lots
const LotsStackConfig: PathConfig<LotsStackParamList> = {
  path: 'lots',
  screens: {
    Lots: '',
    LotCreate: 'create',
  },
}

// Packages
const PackagesStackConfig: PathConfig<PackagesStackParamList> = {
  path: 'packages',
  screens: {
    Packages: '',
    PackageCreate: 'create',
  },
}

// All Organizations
const AllOrganizationsStackConfig: PathConfig<AllOrganizationsStackParamList> = {
  path: 'allOrganizations',
  screens: {
    AllOrganizations: '',
    Organization: 'organization/:organizationId',
  },
}

// Organization
const OrganizationStackConfig: PathConfig<OrganizationStackParamList> = {
  path: 'organization',
  screens: {
    Organization: '',
  },
}

// Attributes
const AttributesStackConfig: PathConfig<AttributesStackParamList> = {
  path: 'attributes',
  screens: {
    Attributes: '',
  },
}

// Settings
const SettingsStackConfig: PathConfig<SettingsStackParamList> = {
  path: 'settings',
  screens: {
    Settings: 'settings',
  },
}

export const GetNavigationConfig = (basePath: string): ConfigType => ({
  screens: {
    BottomTab: {
      path: basePath,
      screens: {
        LotsStack: LotsStackConfig,
        PackagesStack: PackagesStackConfig,
        AllOrganizationsStack: AllOrganizationsStackConfig,
        OrganizationStack: OrganizationStackConfig,
        AttributesStack: AttributesStackConfig,
        SettingsStack: SettingsStackConfig,
      },
    },
    Login: `${basePath}login`,
    Devices: `${basePath}devices`,
    PackagesMeasure: `${basePath}packagesMeasure/:packageTmpId`,
    LotsMeasure: `${basePath}lotsMeasure/:lotTmpId`,
  },
})
