import { CardProps, IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { Address } from '../../types'
import InfoRow from '../InfoRow'

export type AddressCardProps = { address: Address } & CardProps

export default function AddressCard({ address, ...cardProps }: AddressCardProps) {
  const { i18n } = useLanguage()
  return (
    <IM.Card {...cardProps}>
      <InfoRow title={`${i18n.t('ADDRESS_LINE')} 1`} value={address.line1} />
      <InfoRow title={`${i18n.t('ADDRESS_LINE')} 2`} value={address.line2} />
      <InfoRow title={i18n.t('ADDRESS_ZIP')} value={address.zip} />
      <InfoRow title={i18n.t('ADDRESS_CITY')} value={address.city} />
      <InfoRow title={i18n.t('ADDRESS_STATE')} value={address.state} />
      <InfoRow title={i18n.t('ADDRESS_COUNTRY')} value={address.country} />
    </IM.Card>
  )
}
