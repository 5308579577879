import { IM, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'
import { SceneMap, TabBar, TabView } from 'react-native-tab-view'

import api from '../../apis/apiCalls'
import { Customer, Grade, Measurer, Origin, Quality, Sawmill, Typology, Wood } from '../../apis/apiResponseTypes'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import TableUtils from '../../components/table/utils/TableUtils'
import { useAuthentication } from '../../contexts/AuthenticationContext'
import { useData } from '../../contexts/DataContext'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import CustomerCreateModal from '../../modals/CustomerCreateModal'
import GradeCreateModal from '../../modals/GradeCreateModal'
import MeasurerCreateModal from '../../modals/MeasurerCreateModal'
import OriginCreateModal from '../../modals/OriginCreateModal'
import QualityCreateModal from '../../modals/QualityCreateModal'
import SawmillCreateModal from '../../modals/SawmillCreateModal'
import TypologyCreateModal from '../../modals/TypologyCreateModal'
import WoodCreateModal from '../../modals/WoodCreateModal'
import { ThemeColorExpanded } from '../../types'
import { CustomerCreateViewProps } from '../CustomerCreate/CustomerCreateView'
import { GradeCreateViewProps } from '../GradeCreate/GradeCreateView'
import { MeasurerCreateViewProps } from '../MeasurerCreate/MeasurerCreateView'
import { OriginCreateViewProps } from '../OriginCreate/OriginCreateView'
import { QualityCreateViewProps } from '../QualityCreate/QualityCreateView'
import { SawmillCreateViewProps } from '../SawmillCreate/SawmillCreateView'
import { TypologyCreateViewProps } from '../TypologyCreate/TypologyCreateView'
import { WoodCreateViewProps } from '../WoodCreate/WoodCreateView'

//TODO: make screen functional for different organizations not only current
export default function AttributesView() {
  const { colorScheme, theme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const { isAdmin } = useAuthentication()
  const deleteAlert = useDeleteAlert()
  const {
    customers,
    organizationId,
    setCustomers,
    typologies,
    setTypologies,
    woods,
    setWoods,
    grades,
    setGrades,
    qualities,
    setQualities,
    origins,
    setOrigins,
    sawmills,
    setSawmills,
    measurers,
    setMeasurers,
  } = useData()

  const createCustomerModal = useModalController<CustomerCreateViewProps>()
  const createTypologyModal = useModalController<TypologyCreateViewProps>()
  const createWoodModal = useModalController<WoodCreateViewProps>()
  const createGradeModal = useModalController<GradeCreateViewProps>()
  const createQualityModal = useModalController<QualityCreateViewProps>()
  const createOriginModal = useModalController<OriginCreateViewProps>()
  const createSawmillModal = useModalController<SawmillCreateViewProps>()
  const createMeasurerModal = useModalController<MeasurerCreateViewProps>()

  const [customerSearchValue, setCustomerSearchValue] = useState<string | undefined>(undefined)
  const [routes] = useState([
    { key: '0', title: i18n.t('CUSTOMERS') },
    { key: '1', title: i18n.t('TYPOLOGIES') },
    { key: '2', title: i18n.t('WOODS') },
    { key: '3', title: i18n.t('GRADES') },
    { key: '4', title: i18n.t('QUALITIES') },
    { key: '5', title: i18n.t('ORIGINS') },
    { key: '6', title: i18n.t('SAWMILLS') },
    { key: '7', title: i18n.t('MEASURERS') },
  ])

  const [index, setIndex] = useState(0)

  function handleNewCustomerPress() {
    createCustomerModal.show({})
  }

  function handleNewTypologyPress() {
    createTypologyModal.show({})
  }

  function handleNewWoodPress() {
    createWoodModal.show({})
  }

  function handleNewGradePress() {
    createGradeModal.show({})
  }

  function handleNewQualityPress() {
    createQualityModal.show({})
  }

  function handleNewOriginPress() {
    createOriginModal.show({})
  }

  function handleNewSawmillPress() {
    createSawmillModal.show({})
  }

  function handleNewMeasurerPress() {
    createMeasurerModal.show({})
  }

  function handleCustomerEdit(customer: Customer) {
    const selectedCustomer = customers.find(c => c.customerId === customer.customerId)
    createCustomerModal.show({ editId: selectedCustomer?.customerId.toString() })
  }

  function handleTypologyEdit(typology: Typology) {
    const selectedTypology = typologies.find(t => t.typologyId === typology.typologyId)
    createTypologyModal.show({ editId: selectedTypology?.typologyId.toString() })
  }

  function handleWoodEdit(wood: Wood) {
    const selectedWood = woods.find(w => w.woodId === wood.woodId)
    createWoodModal.show({ editId: selectedWood?.woodId.toString() })
  }

  function handleGradeEdit(grade: Grade) {
    const selectedGrade = grades.find(g => g.gradeId === grade.gradeId)
    createGradeModal.show({ editId: selectedGrade?.gradeId.toString() })
  }

  function handleQualityEdit(quality: Quality) {
    const selectedQuality = qualities.find(q => q.qualityId === quality.qualityId)
    createQualityModal.show({ editId: selectedQuality?.qualityId.toString() })
  }

  function handleOriginEdit(origin: Origin) {
    const selectedOrigin = origins.find(o => o.originId === origin.originId)
    createOriginModal.show({ editId: selectedOrigin?.originId.toString() })
  }

  function handleSawmillEdit(sawmill: Sawmill) {
    const selectedSawmill = sawmills.find(s => s.sawmillId === sawmill.sawmillId)
    createSawmillModal.show({ editId: selectedSawmill?.sawmillId.toString() })
  }

  function handleMeasurerEdit(measurer: Measurer) {
    const selectedMeasurer = measurers.find(m => m.measurerId === measurer.measurerId)
    createMeasurerModal.show({ editId: selectedMeasurer?.measurerId.toString() })
  }

  const loadData = async () => {
    if (!organizationId) return
    const [customersData, typologiesData, woodsData, gradesData, qualitiesData, originsData, sawmillsData, measurersData] = await Promise.all([
      api.getCustomers({ organizationId: organizationId }),
      api.getTypologies({ organizationId: organizationId }),
      api.getWoods({ organizationId: organizationId }),
      api.getGrades({ organizationId: organizationId }),
      api.getQualities({ organizationId: organizationId }),
      api.getOrigins({ organizationId: organizationId }),
      api.getSawmills({ organizationId: organizationId }),
      api.getMeasurers({ organizationId: organizationId }),
    ])

    setCustomers(customersData)
    setTypologies(typologiesData)
    setWoods(woodsData)
    setGrades(gradesData)
    setQualities(qualitiesData)
    setOrigins(originsData)
    setSawmills(sawmillsData)
    setMeasurers(measurersData)
  }

  const onNewCustomerCreated = (_customer: Customer) => {
    createCustomerModal.close()
    loadData().catch(console.error)
  }

  const onNewTypologyCreated = (_typology: Typology) => {
    createTypologyModal.close()
    loadData().catch(console.error)
  }

  const onNewWoodCreated = (_wood: Wood) => {
    createWoodModal.close()
    loadData().catch(console.error)
  }

  const onNewGradeCreated = (_grade: Grade) => {
    createGradeModal.close()
    loadData().catch(console.error)
  }

  const onNewQualityCreated = (_quality: Quality) => {
    createQualityModal.close()
    loadData().catch(console.error)
  }

  const onNewOriginCreated = (_origin: Origin) => {
    createOriginModal.close()
    loadData().catch(console.error)
  }

  const onNewSawmillCreated = (_sawmill: Sawmill) => {
    createSawmillModal.close()
    loadData().catch(console.error)
  }

  const onNewMeasurerCreated = (_measurer: Measurer) => {
    createMeasurerModal.close()
    loadData().catch(console.error)
  }

  function handleCustomerDelete(customer: Customer) {
    const selectedCustomer = customers.find(c => c.customerId === customer.customerId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteCustomer({ customerId: Number(selectedCustomer?.customerId) })
            .then(response => {
              console.log('delete Customer', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleTypologyDelete(typology: Typology) {
    const selectedTypology = typologies.find(t => t.typologyId === typology.typologyId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteTypology({ typologyId: Number(selectedTypology?.typologyId) })
            .then(response => {
              console.log('Deleted Typology', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleWoodDelete(wood: Wood) {
    const selectedWood = woods.find(w => w.woodId === wood.woodId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteWood({ woodId: Number(selectedWood?.woodId) })
            .then(response => {
              console.log('Deleted Wood', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleGradeDelete(grade: Grade) {
    const selectedGrade = grades.find(g => g.gradeId === grade.gradeId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteGrade({ gradeId: Number(selectedGrade?.gradeId) })
            .then(response => {
              console.log('Deleted Grade', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleQualityDelete(quality: Quality) {
    const selectedQuality = qualities.find(q => q.qualityId === quality.qualityId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteQuality({ qualityId: Number(selectedQuality?.qualityId) })
            .then(response => {
              console.log('Deleted Quality', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleOriginDelete(origin: Origin) {
    const selectedOrigin = origins.find(o => o.originId === origin.originId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteOrigin({ originId: Number(selectedOrigin?.originId) })
            .then(response => {
              console.log('Deleted Origin', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleSawmillDelete(sawmill: Sawmill) {
    const selectedSawmill = sawmills.find(s => s.sawmillId === sawmill.sawmillId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteSawmill({ sawmillId: Number(selectedSawmill?.sawmillId) })
            .then(response => {
              console.log('Deleted Sawmill', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  function handleMeasurerDelete(measurer: Measurer) {
    const selectedMeasurer = measurers.find(m => m.measurerId === measurer.measurerId)
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteMeasurer({ measurerId: Number(selectedMeasurer?.measurerId) })
            .then(response => {
              console.log('Deleted Measurer', response)
              loadData().catch(console.error)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
  }

  const customersTableHeader: TableHeaderData<Customer>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'customerName' },
      { title: i18n.t('ORGANIZATION'), property: 'organizationId' },
      { title: i18n.t('EMAIL'), property: 'email' },
      { title: i18n.t('PHONE_NUMBER'), property: 'phoneNumber' },
      { title: i18n.t('ADDRESS'), property: 'address' },
      { title: i18n.t('PROVINCE'), property: 'province' },
      { title: i18n.t('COUNTRY'), property: 'country' },
    ],
    [i18n]
  )

  const typologiesTableHeader: TableHeaderData<Typology>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'typologyName' },
      { title: i18n.t('CODE'), property: 'typologyCode' },
    ],
    [i18n]
  )

  const woodsTableHeader: TableHeaderData<Wood>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'woodName' },
      { title: i18n.t('CODE'), property: 'woodCode' },
    ],
    [i18n]
  )

  const gradesTableHeader: TableHeaderData<Grade>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'gradeName' },
      { title: i18n.t('CODE'), property: 'gradeCode' },
    ],
    [i18n]
  )

  const qualitiesTableHeader: TableHeaderData<Quality>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'qualityName' },
      { title: i18n.t('CODE'), property: 'qualityCode' },
    ],
    [i18n]
  )

  const originsTableHeader: TableHeaderData<Origin>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'originName' },
      { title: i18n.t('CODE'), property: 'originCode' },
    ],
    [i18n]
  )

  const sawmillsTableHeader: TableHeaderData<Sawmill>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'sawmillName' },
      { title: i18n.t('CODE'), property: 'sawmillCode' },
    ],
    [i18n]
  )

  const measurersTableHeader: TableHeaderData<Measurer>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'measurerName' },
      { title: i18n.t('CODE'), property: 'measurerCode' },
    ],
    [i18n]
  )

  const typologiesTableData: Typology[] = useMemo(() => {
    const typologiesData = typologies

    return TableUtils<Typology>().filterData(typologiesData, customerSearchValue)
  }, [typologies, language, customerSearchValue])

  const customersTableData: Customer[] = useMemo(() => {
    const customersData = customers

    return TableUtils<Customer>().filterData(customersData, customerSearchValue)
  }, [customers, language, customerSearchValue])

  const woodsTableData: Wood[] = useMemo(() => {
    const woodsData = woods

    return TableUtils<Wood>().filterData(woodsData, customerSearchValue)
  }, [woods, language, customerSearchValue])

  const gradesTableData: Grade[] = useMemo(() => {
    const gradesData = grades

    return TableUtils<Grade>().filterData(gradesData, customerSearchValue)
  }, [grades, language, customerSearchValue])

  const qualitiesTableData: Quality[] = useMemo(() => {
    const qualitiesData = qualities

    return TableUtils<Quality>().filterData(qualitiesData, customerSearchValue)
  }, [qualities, language, customerSearchValue])

  const originsTableData: Origin[] = useMemo(() => {
    const originsData = origins

    return TableUtils<Origin>().filterData(originsData, customerSearchValue)
  }, [origins, language, customerSearchValue])

  const sawmillsTableData: Sawmill[] = useMemo(() => {
    const sawmillsData = sawmills

    return TableUtils<Sawmill>().filterData(sawmillsData, customerSearchValue)
  }, [sawmills, language, customerSearchValue])

  const measurersTableData: Measurer[] = useMemo(() => {
    const measurersData = measurers

    return TableUtils<Measurer>().filterData(measurersData, customerSearchValue)
  }, [measurers, language, customerSearchValue])

  const renderCustomersTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('CUSTOMERS')}
        tableContainerSpacing={'all'}
        headerData={customersTableHeader}
        tableData={customersTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        hasPaging
        rowsPerPage={15}
        onNewItemPress={handleNewCustomerPress}
        hasEditAndDelete={true}
        onEditPress={handleCustomerEdit}
        onDeletePress={handleCustomerDelete}
      />
    </IM.View>
  )

  const renderTypologiesTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('TYPOLOGIES')}
        tableContainerSpacing={'all'}
        headerData={typologiesTableHeader}
        tableData={typologiesTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewTypologyPress}
        hasEditAndDelete={true}
        onEditPress={handleTypologyEdit}
        onDeletePress={handleTypologyDelete}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )

  const renderWoodsTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('WOODS')}
        tableContainerSpacing={'all'}
        headerData={woodsTableHeader}
        tableData={woodsTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewWoodPress}
        hasEditAndDelete={true}
        onEditPress={handleWoodEdit}
        onDeletePress={handleWoodDelete}
        hasPaging
        rowsPerPage={15}
        flexArray={[4, 4, 1]}
      />
    </IM.View>
  )

  const renderGradesTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('GRADES')}
        tableContainerSpacing={'all'}
        headerData={gradesTableHeader}
        tableData={gradesTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewGradePress}
        hasEditAndDelete={true}
        onEditPress={handleGradeEdit}
        onDeletePress={handleGradeDelete}
        flexArray={[4, 4, 1]}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )

  const renderQualitiesTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('QUALITIES')}
        tableContainerSpacing={'all'}
        headerData={qualitiesTableHeader}
        tableData={qualitiesTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewQualityPress}
        hasEditAndDelete={true}
        onEditPress={handleQualityEdit}
        onDeletePress={handleQualityDelete}
        flexArray={[4, 4, 1]}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )

  const renderOriginsTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('ORIGINS')}
        tableContainerSpacing={'all'}
        headerData={originsTableHeader}
        tableData={originsTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewOriginPress}
        hasEditAndDelete={true}
        onEditPress={handleOriginEdit}
        onDeletePress={handleOriginDelete}
        flexArray={[4, 4, 1]}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )

  const renderSawmillsTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('SAWMILLS')}
        tableContainerSpacing={'all'}
        headerData={sawmillsTableHeader}
        tableData={sawmillsTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewSawmillPress}
        hasEditAndDelete={true}
        onEditPress={handleSawmillEdit}
        onDeletePress={handleSawmillDelete}
        flexArray={[4, 4, 1]}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )

  const renderMeasurersTable = () => (
    <IM.View spacing="all" style={styles.container}>
      <TableWrapper
        tableTitle={i18n.t('MEASURERS')}
        tableContainerSpacing={'all'}
        headerData={measurersTableHeader}
        tableData={measurersTableData}
        maxTableHeight={750}
        showNewItemRow={true}
        onNewItemPress={handleNewMeasurerPress}
        hasEditAndDelete={true}
        onEditPress={handleMeasurerEdit}
        onDeletePress={handleMeasurerDelete}
        flexArray={[4, 4, 1]}
        hasPaging
        rowsPerPage={15}
      />
    </IM.View>
  )
  const renderScene = SceneMap({
    '0': renderCustomersTable,
    '1': renderTypologiesTable,
    '2': renderWoodsTable,
    '3': renderGradesTable,
    '4': renderQualitiesTable,
    '5': renderOriginsTable,
    '6': renderSawmillsTable,
    '7': renderMeasurersTable,
  })

  const renderTabBar = (props: any) => (
    <TabBar {...props} indicatorStyle={{ backgroundColor: 'white' }} style={{ backgroundColor: theme.primary }} labelStyle={{ color: 'white' }} />
  )

  return (
    <>
      <TabView navigationState={{ index, routes }} renderScene={renderScene} onIndexChange={setIndex} renderTabBar={renderTabBar} />
      <CustomerCreateModal controller={createCustomerModal} onCreateCustomer={onNewCustomerCreated} />
      <TypologyCreateModal controller={createTypologyModal} onCreateTypology={onNewTypologyCreated} />
      <WoodCreateModal controller={createWoodModal} onCreateWood={onNewWoodCreated} />
      <GradeCreateModal controller={createGradeModal} onCreateGrade={onNewGradeCreated} />
      <QualityCreateModal controller={createQualityModal} onCreateQuality={onNewQualityCreated} />
      <OriginCreateModal controller={createOriginModal} onCreateOrigin={onNewOriginCreated} />
      <SawmillCreateModal controller={createSawmillModal} onCreateSawmill={onNewSawmillCreated} />
      <MeasurerCreateModal controller={createMeasurerModal} onCreateMeasurer={onNewMeasurerCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
})
