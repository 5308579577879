import { useDimensions } from '@infominds/react-native-components'
import React, { createContext, memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { ScreenHeight, ScreenStatus } from '../../../types'
import { SCREEN_CONSTANTS } from '../constants/constants'

interface ScreenContextProps {
  detail: ScreenStatus
  height: ScreenHeight
  // view: ScreenViewType | undefined
  setDetailOpen: (value: boolean) => void
  toggleDetailOpen: () => void
  setDetailHeight: (val: number) => void
}

interface Props {
  detailHeight: number
  forceDetailOpen: boolean
}

const ScreenContext = createContext<ScreenContextProps | undefined>(undefined)

export const ScreenProvider = memo(function ScreenProvider({ children, forceDetailOpen, detailHeight }: PropsWithChildren<Props>) {
  const { isSmallDevice } = useDimensions()
  const [state, setState] = useState<ScreenStatus>({ open: forceDetailOpen })
  const [height, setHeight] = useState<ScreenHeight>({
    detail: detailHeight,
    header: SCREEN_CONSTANTS.headerBarHeight,
  })

  useEffect(() => {
    state.open && !isSmallDevice && setState((prev: ScreenStatus) => ({ ...prev, open: false }))
  }, [isSmallDevice])

  const handleOpenDetail = (value: boolean) => {
    setState(prev => ({ ...prev, open: value }))
  }
  const handleToggleDetailOpen = () => {
    setState(prev => ({ ...prev, open: !prev.open }))
  }
  const handleDetailHeight = useCallback((val: number) => {
    setHeight((prev: ScreenHeight) => ({ ...prev, detail: val }))
  }, [])

  const props = useMemo<ScreenContextProps>(
    () => ({
      detail: state,
      height,
      // view,
      setDetailOpen: handleOpenDetail,
      setDetailHeight: handleDetailHeight,
      toggleDetailOpen: handleToggleDetailOpen,
      // setView,
    }),
    [state, height]
  )

  return <ScreenContext.Provider value={props}>{children}</ScreenContext.Provider>
})

export default ScreenContext
