import { Buffer } from 'buffer'

import { useEffect, useState } from 'react'
import BleManager from 'react-native-ble-manager'

import {
  BLE_DID_UPDATE_VALUE_FOR_CHARACTERISTIC,
  LEICA_CHARACTERISTIC_UUID,
  LEICA_SERVICE_UUID,
  MONOLITH_CHARACTERISTIC_UUID,
  MONOLITH_SERVICE_UUID,
  TAJFUN_CHARACTERISTIC_UUID,
  TAJFUN_SERVICE_UUID,
} from '../constants/Bluetooth'
import { BluetoothDevice } from '../types'

type LastMeasureType = {
  value: string
}
const useBluetoothNotifications = (device: BluetoothDevice | undefined, onMeasurement?: (measurement: string) => void) => {
  const [lastMeasure, setLastMeasure] = useState<LastMeasureType | undefined>(undefined)
  const [notificationStarted, setNotificationStarted] = useState(false)

  useEffect(() => {
    if (lastMeasure) onMeasurement?.(lastMeasure.value)
  }, [lastMeasure])

  const startNotifications = async () => {
    try {
      let service: string | undefined
      let characteristic: string | undefined
      if (device && device.deviceType === 'meter') {
        if (device.manufacturer === 'monolith') {
          service = MONOLITH_SERVICE_UUID
          characteristic = MONOLITH_CHARACTERISTIC_UUID
        }
      } else {
        if (device && device.manufacturer === 'leica') {
          service = LEICA_SERVICE_UUID
          characteristic = LEICA_CHARACTERISTIC_UUID
        } else if (device && device.manufacturer === 'tajfun') {
          service = TAJFUN_SERVICE_UUID
          characteristic = TAJFUN_CHARACTERISTIC_UUID
        }
      }
      if (service && characteristic) {
        await BleManager.startNotification(device?.id ?? '', service, characteristic)
        setNotificationStarted(true)

        console.info('Notifications started for characteristic.')
      }
    } catch (error) {
      console.error('Error starting notification:', error)
    }
  }

  const handleCharacteristicUpdate = (data: unknown) => {
    console.info('Button press notification received:', data)
    if (!data || typeof data !== 'object' || !('value' in data) || !data.value || !(data.value instanceof Array)) return
    if (device && device.deviceType === 'caliber') {
      const buffer = Buffer.from(data.value)
      const sensorData = buffer.readFloatLE(0).toFixed(2)
      console.info('Distance data:', sensorData)
      setLastMeasure({ value: sensorData.toString() })
    }
    if (device && device.deviceType === 'meter') {
      const resultString = String.fromCharCode(...(data.value as number[]))
      setLastMeasure({ value: resultString })
    }
  }

  useEffect(() => {
    if (!device?.id) return
    startNotifications().catch(console.error)
  }, [device])

  useEffect(() => {
    if (!notificationStarted) return
    const subscription = BleManager.addListener(BLE_DID_UPDATE_VALUE_FOR_CHARACTERISTIC, handleCharacteristicUpdate)

    return () => subscription.remove()
  }, [notificationStarted])

  return { lastMeasure, startNotifications }
}

export default useBluetoothNotifications
