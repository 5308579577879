import { IM, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useState } from 'react'
import { StyleSheet, TextStyle } from 'react-native'

import { TextInputRef } from '../types'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import PressableIcon from './PressableIcon'

export type PasswordTextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disabledInfo?: string | false
  }

const PasswordTextInput = memo(
  forwardRef(function PasswordTextInput(
    {
      title,
      titleFontWeight,
      details,
      editable = true,
      error,
      loading,
      disableFocus,
      disabledInfo,
      focusBorderColor,
      ...textInputProps
    }: PasswordTextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme()
    const [showPassword, setShowPassword] = useState(false)

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus} focusBorderColor={focusBorderColor}>
        <BaseTextInput ref={ref} secureTextEntry={!showPassword} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} required={textInputProps.required} />}

          <BaseTextInput.RightIcon
            disableBorder={textInputProps.disableBorderRadius}
            key={'PasswordIcon'}
            style={textInputProps.multiline ? styles.multiLineRightIcon : undefined}>
            <PressableIcon
              icon={['fal', showPassword ? 'eye-slash' : 'eye']}
              size={18}
              color={theme.textPlaceholder}
              onPress={() => setShowPassword(prev => !prev)}
            />
          </BaseTextInput.RightIcon>
          {!editable && !loading && disabledInfo && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              <IM.Text key={'DisabledInfoText'} style={[{ color: theme.textPlaceholder }, styles.disabledText]}>
                {disabledInfo}
              </IM.Text>
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default PasswordTextInput

const styles = StyleSheet.create({
  multiLineRightIcon: {
    alignItems: 'flex-start',
    paddingTop: 4,
  },
  disabledText: {
    paddingRight: 9,
  },
})
