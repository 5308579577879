import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import { SkipPageDirection } from '../../types/types'

export type SkipPageCardProps = {
  direction: SkipPageDirection
  currentPage: number
  handlePageChange: (page: number) => void
  totalPages: number
  skipAll?: boolean
}

export default function SkipPageCard({ direction, currentPage, handlePageChange, totalPages, skipAll }: SkipPageCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const regularIcon = direction === 'next' ? 'chevron-right' : 'chevron-left'
  const skipAllIcon = direction === 'next' ? 'angle-double-right' : 'angle-double-left'
  const icon = skipAll ? skipAllIcon : regularIcon

  //check if pages can be skipped
  //if direction is next, check if current page is less than total pages
  //if direction is prev, check if current page is greater than 1
  const canSkip = direction === 'next' ? currentPage < totalPages : currentPage > 1

  function onSkipPress() {
    if (direction === 'next' && currentPage < totalPages) {
      if (skipAll) {
        handlePageChange(totalPages)
      } else {
        handlePageChange(currentPage + 1)
      }
    }
    if (direction === 'prev' && currentPage > 1) {
      if (skipAll) {
        handlePageChange(1)
      } else {
        handlePageChange(currentPage - 1)
      }
    }
  }

  return (
    <IM.Card
      onPress={onSkipPress}
      style={[styles.pageCard, { backgroundColor: canSkip ? theme.table.rowEven : theme.table.rowOdd }]}
      disabled={!canSkip}>
      <IM.Icon icon={['fal', icon]} color={theme.table.icon} size={16} />
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  pageCard: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
