import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'

export type PageCardProps = { page: number; currentPage: number; handlePageChange: (page: number) => void }

export default function PageCard({ page, currentPage, handlePageChange }: PageCardProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card
      onPress={() => handlePageChange(page)}
      style={[styles.pageCard, { backgroundColor: currentPage === page ? theme.table.header.background : theme.table.rowEven }]}>
      <IM.Text style={{ color: currentPage === page ? theme.table.header.text : theme.table.text }}>{page}</IM.Text>
    </IM.Card>
  )
}

const styles = StyleSheet.create({
  pageCard: {
    height: 45,
    width: 45,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})
