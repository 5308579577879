const LIB_NAME = 'IM.Lib.App.ReactNative.License.'

export const PLACEHOLDER_PASSWORD = 'ThisIsAPlaceholderPasswordForWhoDoNotUseAPassword'

export const QueryKeys = {
  QUERY_IOS_TOKEN: LIB_NAME + 'QueryIosToken',
  QUERY_VALIDATION: LIB_NAME + 'QueryValidation',
  QUERY_VERSION_CHECK: LIB_NAME + 'QueryVersionCheck',
  QUERY_REGISTER_DEVICE: LIB_NAME + 'QueryRegisterDevice',
  QUERY_AUTHORIZE: LIB_NAME + 'QueryAuthorize',
  QUERY_AAD_CREDENTIALS: LIB_NAME + 'QueryAADCredentials',
  QUERY_AAD_LOGIN: LIB_NAME + 'QueryAADLogin',
}

export const InputsKeys = {
  LICENSE: LIB_NAME + 'License',
  CONNECTION: LIB_NAME + 'Connection',
  COMPANY: LIB_NAME + 'Company',
  MIGRATION_KEY: LIB_NAME + 'MigrationStatus',
  WEB_AUTH: LIB_NAME + 'WebAuth',
}

export const EventKeys = {
  CODE_SCANNED: LIB_NAME + 'CodeScannedEvent',
}

const APP_NAME = 'IlCubatore.'

export const STORAGE_KEYS = {
  PACKAGES_STORAGE_KEY: APP_NAME + 'LocalPackages',
  LOTS_STORAGE_KEY: APP_NAME + 'LocalLots',
  LICENSE_KEY: APP_NAME + 'LicenseKey',
  USERNAME_KEY: APP_NAME + 'Username',
  PASSWORD_KEY: APP_NAME + 'Password',
  CONNECTED_DEVICES_STORAGE_KEY: APP_NAME + 'ConnectedDevices',
  DEFAULT_MENU: APP_NAME + 'DefaultMenu',
  LAST_PACKAGE_DATA: APP_NAME + 'LastPackageData',
  LAST_LOT_DATA: APP_NAME + 'LastLotData',
  LAST_CONNECTED_INFO: APP_NAME + 'LastConnectedInfo',
  PACKAGE_SETTINGS: APP_NAME + 'PackageSettings',
  LOT_SETTINGS: APP_NAME + 'LotSettings',
  GENERAL_SETTINGS: APP_NAME + 'GeneralSettings',
  NAVIGATION_STATE: APP_NAME + 'NavigationState',
  DRAWER_OPEN: APP_NAME + 'DrawerOpen',
}
