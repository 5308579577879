import { CardProps, IM } from '@infominds/react-native-components'
import React from 'react'

import { Quality } from '../../apis/apiResponseTypes' // Assuming Quality type is defined

export type QualityCardProps = { quality: Quality } & CardProps

export default function QualityCard({ quality, ...cardProps }: QualityCardProps) {
  return (
    <IM.Card {...cardProps}>
      <IM.Text>{quality.qualityName}</IM.Text>
    </IM.Card>
  )
}
