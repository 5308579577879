import { useEffect, useState } from 'react'
import Sound from 'react-native-sound'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const beepSound = require('../assets/audio/beep.mp3') as Sound

export default function useCustomSound() {
  const [sound, setSound] = useState<Sound | null>(null)

  useEffect(() => {
    const soundInstance = new Sound(beepSound, error => {
      if (error) {
        console.error('Failed to load the sound', error)
      } else {
        console.log('Sound loaded successfully')
        setSound(soundInstance)
      }
    })

    return () => {
      if (soundInstance) {
        soundInstance.release()
      }
    }
  }, [])

  function playAlertSound() {
    if (sound) {
      sound.play(success => {
        if (success) {
          console.log('Sound played successfully')
        } else {
          console.log('Playback failed')
        }
      })
    } else {
      console.log('Sound is not loaded yet')
    }
  }

  return { playAlertSound }
}
