import React from 'react'

import { Screen } from '../../components/screen/Screen'
import SettingsHeader from './SettingsHeader'
import SettingsView from './SettingsView'

export default function SettingsScreen() {
  const [isBusy, setIsBusy] = React.useState(false)

  return (
    <Screen>
      <Screen.Header>
        <SettingsHeader setIsBusy={setIsBusy} />
      </Screen.Header>
      <Screen.Content>
        <SettingsView isBusy={isBusy} />
      </Screen.Content>
    </Screen>
  )
}
