import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import TypologyCreateScreen from '../screens/TypologyCreate/TypologyCreateScreen'
import { TypologyCreateViewProps } from '../screens/TypologyCreate/TypologyCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type TypologyCreateModalProps = {
  controller: ModalController<TypologyCreateViewProps>
} & Pick<TypologyCreateViewProps, 'onCreateTypology'>

export default function TypologyCreateModal({ controller, onCreateTypology }: TypologyCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <TypologyCreateScreen ref={ref} onCreateTypology={onCreateTypology} controller={controller} />
    </BaseServiceModal>
  )
}
