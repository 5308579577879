/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { Address } from '../types'
import { LicenseSelectType, LicenseType } from '../types/types'

const OrganizationUtils = {
  getEmail(emailString: string): string {
    const email = JSON.parse(emailString)
    return email.Work
  },
  createEmailObject(email: string): string {
    return JSON.stringify({ Work: email })
  },
  getPhoneNumber(phoneNumberString: string): string {
    const phoneNumber = JSON.parse(phoneNumberString)
    return phoneNumber.Work
  },
  createPhoneNumberObject(phoneNumber: string): string {
    return JSON.stringify({ Work: phoneNumber })
  },
  getAddress(addressString: string): Address {
    const workAddress = JSON.parse(addressString)
    const address = workAddress.Work
    return {
      line1: address.Line1,
      line2: address.Line2,
      zip: address.ZipCode,
      state: address.State,
      city: address.City,
      country: address.Country,
    } as Address
  },
  createAddressObject(address: Address): string {
    return JSON.stringify({
      Work: {
        Line1: address.line1,
        Line2: address.line2,
        ZipCode: address.zip,
        State: address.state,
        City: address.city,
        Country: address.country,
      },
    })
  },
  calculateInitials(inputString: string) {
    if (!inputString) return ''

    const simplifiedString = inputString
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(' ')
      .filter(el => el !== '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = ''
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0][0].toUpperCase()
    } else {
      initial = `${simplifiedString[0][0].toUpperCase()}${simplifiedString[1][0].toUpperCase()}`
    }

    return initial
  },
  convertLicenseTypeToSelector(type: LicenseType | undefined): LicenseSelectType | undefined {
    if (!type) return undefined
    switch (type) {
      case 'PackageAndLot':
        return { value: 'PackageAndLot', label: 'PACKAGE_AND_LOT' }
      case 'Complete':
        return { value: 'Complete', label: 'COMPLETE' }
      default:
        return { value: 'PackageAndLot', label: 'PACKAGE_AND_LOT' }
    }
  },
  convertLicenseSelectorToType(type: LicenseSelectType | undefined): LicenseType | undefined {
    if (!type) return undefined
    switch (type.value) {
      case 'PackageAndLot':
        return 'PackageAndLot'
      case 'Complete':
        return 'Complete'
      default:
        return 'PackageAndLot'
    }
  },
  convertStringToLicenseType(type: string): LicenseType {
    switch (type) {
      case 'PackageAndLot':
        return 'PackageAndLot'
      case 'Complete':
        return 'Complete'
      default:
        return 'PackageAndLot'
    }
  },
}

export default OrganizationUtils
