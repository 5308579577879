import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useMemo, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/apiResponseTypes'
import { useData } from '../../contexts/DataContext'
import useDataSearchFilter from '../../hooks/useDataSearchFilter'
import { ListSection } from '../../types/types'
import CustomerCard from '../cards/CustomerCard'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Customer | undefined) => void
  required?: boolean
  selectedCustomerId?: number
}

export default function CustomerSelector({ onChange, selectedCustomerId, ...props }: Props) {
  const { i18n } = useLanguage()

  const { customers, organizationId, setCustomers } = useData()
  const [search, setSearch] = useState('')

  const filteredData = useDataSearchFilter(customers, search, ['customerName', 'customerId', 'customerCode'])

  const render = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>, onPress?: () => void) => {
    return <CustomerCard customer={item} onPress={onPress} selected={item.customerId === selectedCustomerId} spacing={['bottom', 'horizontal']} />
  }

  const handleOnChange = (newValue: Customer | undefined) => {
    onChange(newValue)
  }

  const handleSave = (inputValue: string) => {
    saveCustomer(inputValue).catch(error => {
      console.error('Failed to save customer', error)
    })
  }

  const saveCustomer = async (inputValue: string) => {
    try {
      if (organizationId === undefined) return
      await api.postCustomer({ customerName: inputValue, organizationId: organizationId })
      const res = await api.getCustomers({ organizationId: organizationId })
      setCustomers(res)
    } catch (error) {
      console.error('Failed to save customer', error)
    }
  }

  const selectedCustomer = useMemo(() => customers?.find(elem => elem.customerId === selectedCustomerId), [customers, selectedCustomerId])

  return (
    <SelectInput
      id={'customerId'}
      data={filteredData}
      value={selectedCustomer}
      loading={false}
      refresh={undefined}
      title={i18n.t('CUSTOMER')}
      screenTitle={i18n.t('CUSTOMER')}
      noDataMessage={i18n.t('NO_CUSTOMER')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.customerName}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      autoSelectIfUnique
      onSave={(inputValue: string) => handleSave(inputValue)}
      searchField="customerName"
      {...props}
    />
  )
}
