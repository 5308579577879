import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { StyleSheet, useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'

import 'react-day-picker/dist/style.css'

import { IM, IMLayout, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { Button, createTheme, DialogActions, ThemeProvider } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker'
import Color from 'color'

import { ThemeColorExpanded } from '../types'
import { DateTimePickerProps } from '../types/types'
import appUtils from '../utils/appUtils'
import TimeUtils from '../utils/TimeUtils'
import Pressable from './Pressable'

export default function DateTimePicker({
  date,
  show,
  mode,
  minimumDate = new Date(1899, 0, 1),
  maximumDate = new Date(2199, 0, 1),
  setDate,
  setShow,
}: DateTimePickerProps) {
  const { i18n, language } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const { width, height } = useWindowDimensions()
  const muiTheme = createTheme({
    palette: {
      mode: colorScheme,
      primary: { main: theme.general.info, contrastText: 'white' },
    },
  })
  const [internalDate, setInternalDate] = useState<Date | undefined>(date ?? minimumDate)
  const [currentMode, setCurrentMode] = useState<'date' | 'time'>(mode !== 'datetime' ? mode : 'date')

  useEffect(() => {
    if (!show && mode === 'datetime') setCurrentMode('date')
    if (show) setInternalDate(date ?? minimumDate)
  }, [show, mode])

  const css = `
    .my-selected:not([disabled]) { 
      background-color: ${theme.general.info};
      color: ${IMStyle.palette.white}
    }
    .my-selected:hover:not([disabled]) { 
      background-color: ${theme.general.info};
      color: ${IMStyle.palette.white}
  
    }
    .my-selected:hover:not([disabled]):not(.rdp-day_selected) { 
      background-color: ${Color(theme.general.info)
        .alpha(colorScheme === 'dark' ? 0.5 : 0.8)
        .toString()};
      color: ${colorScheme === 'dark' ? theme.text : IMStyle.palette.white}
  
    }
   .my-today { 
      color: ${theme.general.info};
    }
  `

  function handleDateSelected(selectedDate: Date | undefined) {
    setInternalDate(selectedDate)
    if (mode === 'datetime') setCurrentMode('time')
  }

  const onAccept = () => {
    if (mode === 'datetime' && currentMode === 'date') {
      setCurrentMode('time')
      return
    }
    if (internalDate) setDate(internalDate)
    onClose()
  }

  const onClose = () => {
    setShow(false)
  }

  const actions = (
    <DialogActions
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        display: 'flex',
        width: '100%',
        flex: 1,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 25,
      }}>
      <Button onClick={onClose} style={{ color: theme.general.info }}>
        {i18n.t('CANCEL')}
      </Button>
      <Button disabled={internalDate === undefined} onClick={onAccept} style={{ color: theme.general.info }}>
        {i18n.t('OK')}
      </Button>
    </DialogActions>
  )

  return (
    <Modal
      isVisible={show}
      onBackdropPress={onClose}
      backdropOpacity={appUtils.getModalOpacity(colorScheme)}
      style={styles.modal}
      deviceHeight={height}
      deviceWidth={width}>
      <IM.View style={[styles.modalView, { backgroundColor: muiTheme.palette.background.default }]}>
        {currentMode === 'date' ? (
          <>
            <style>{css}</style>
            <DayPicker
              mode="single"
              selected={internalDate}
              onSelect={handleDateSelected}
              modifiersClassNames={{
                selected: 'my-selected',
                today: 'my-today',
              }}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                color: theme.text,
                fontFamily: 'Roboto',
                // @ts-ignore web
                '--rdp-background-color': Color(theme.general.info).alpha(0.15).toString(),
                '--rdp-background-color-dark': Color(theme.general.info).alpha(0.15).toString(),
              }}
              locale={TimeUtils.languageToLocale(language)}
              disabled={{ after: maximumDate, before: minimumDate }}
              required
            />
            {actions}
          </>
        ) : (
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={TimeUtils.languageToLocale(language)}>
              {mode === 'datetime' && !!internalDate && (
                <IM.View style={[styles.timePickerTitleView, IMLayout.flex.row]}>
                  <Pressable onPress={() => setCurrentMode('date')}>
                    <IM.Text>{TimeUtils.format(internalDate, language)}</IM.Text>
                  </Pressable>
                </IM.View>
              )}
              <StaticTimePicker
                value={internalDate}
                onChange={val => setInternalDate(val ?? undefined)}
                autoFocus
                localeText={{ toolbarTitle: i18n.t('SELECT_TIME'), okButtonLabel: i18n.t('OK'), cancelButtonLabel: i18n.t('CANCEL') }}
                componentsProps={{ actionBar: { actions: [] } }}
              />
              {actions}
            </LocalizationProvider>
          </ThemeProvider>
        )}
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  timePickerTitleView: {
    width: '100%',
    paddingLeft: 16,
  },
  modal: {
    margin: 0,
  },
  modalView: {
    minHeight: 420,
    borderRadius: 20,
    padding: 13,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 350,
    alignSelf: 'center',
  },
})
