import { IM, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DateInput from '../../components/DateInput'
import TableWrapper from '../../components/table/TableWrapper'
import { TableHeaderData } from '../../components/table/types/types'
import useDeleteAlert from '../../hooks/useDeleteAlert'
import LotCreateModal, { LotCreateModalControllerData } from '../../modals/LotCreateModal'
import { ThemeColorExpanded } from '../../types'
import { Lot } from '../../types/types'

export interface LotsTableData {
  lotId: number
  lotName: string
  woodName: string
  qualityName: string
  customerName: string
  measurerName: string
  sawmillName: string
  addDate: string
}

export default function LotsView() {
  const { colorScheme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()

  const [busy, setBusy] = useState(false)

  const [lots, setLots] = useState<Lot[]>([])
  const [fromDate, setFromDate] = useState<Date>(() => {
    const date = new Date()
    date.setDate(date.getDate() - 7)
    return date
  })
  const [toDate, setToDate] = useState<Date>(new Date())
  const deleteAlert = useDeleteAlert()
  const { alert } = useAlert()
  const createLotModal = useModalController<LotCreateModalControllerData>()

  const lotsTableHeader: TableHeaderData<LotsTableData>[] = useMemo(
    () => [
      { title: i18n.t('NAME'), property: 'lotName' },
      { title: i18n.t('DATE'), property: 'addDate' },
      { title: i18n.t('CUSTOMER'), property: 'customerName' },
      { title: i18n.t('WOOD'), property: 'woodName' },
      { title: i18n.t('QUALITY'), property: 'qualityName' },
      { title: i18n.t('MEASURER'), property: 'measurerName' },
      { title: i18n.t('SAWMILL'), property: 'sawmillName' },
    ],
    [i18n]
  )

  useEffect(() => {
    fetchLots()
  }, [])

  useEffect(() => {
    fetchLots()
  }, [fromDate, toDate])

  const lotsTableData: LotsTableData[] = useMemo(() => {
    return lots.map(pkg => ({
      lotId: pkg.lotId,
      lotName: pkg.lotName ?? '',
      woodName: pkg.wood?.woodName ?? '',
      qualityName: pkg.quality?.qualityName ?? '',
      customerName: pkg.customer?.customerName ?? '',
      measurerName: pkg.measurer?.measurerName ?? '',
      sawmillName: pkg.sawmill?.sawmillName ?? '',
      addDate: pkg.addDate
        ? new Date(pkg.addDate).toLocaleDateString('it-IT', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })
        : '',
    }))
  }, [lots, language])

  function fetchLots() {
    setBusy(true)
    api
      .getLotsFromDates({ startDate: fromDate, endDate: toDate })
      .then(response => {
        setLots(response)
      })
      .catch(console.error)
      .finally(() => setBusy(false))
  }

  function onNewLotCreated() {
    createLotModal.close()
    fetchLots()
  }

  function handleNewPackagePress() {
    createLotModal.show({})
  }

  function handlePackageEdit(lot: LotsTableData) {
    const selectedLot = lots.find(l => l.lotId === lot.lotId)
    if (!selectedLot?.lotId) return
    createLotModal.show({ editLotId: selectedLot?.lotId })
  }

  function handlePackageDelete(pkg: LotsTableData) {
    const selectedPackage = lots.find(p => p.lotId === pkg.lotId)
    if (!selectedPackage?.lotId) {
      alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }
    deleteAlert
      .show()
      .then(result => {
        if (result === 'delete') {
          api
            .deleteLot({ lotId: selectedPackage.lotId })
            .then(() => {
              fetchLots()
            })
            .catch(error => {
              console.log(error)
              alert(i18n.t('ERROR'), i18n.t('ERROR_DELETE_LINE'), [
                {
                  text: i18n.t('OK'),
                  style: 'default',
                  onPress: () => {
                    return
                  },
                },
              ])
            })
        }
      })
      .catch(console.error)
  }

  return (
    <>
      {busy && (
        <IM.View style={styles.loadingContainer}>
          <IM.LoadingSpinner isVisible={true} />
        </IM.View>
      )}
      <ScrollView indicatorStyle={colorScheme === 'light' ? 'black' : 'white'}>
        <IM.View spacing="all" style={styles.container}>
          <IM.View style={styles.datePickerRow}>
            <IM.Text>{i18n.t('FROM_DATE')}:</IM.Text>
            <DateInput
              value={fromDate.toDateString()}
              onChangeDate={date => date && setFromDate(date)}
              minimumDate={new Date(1899, 0, 1)}
              maximumDate={toDate}
              showCalendarButton={true}
            />

            <IM.Text style={{ marginLeft: 10 }}>{i18n.t('TO_DATE')}:</IM.Text>
            <DateInput
              value={toDate.toDateString()}
              onChangeDate={date => date && setToDate(date)}
              minimumDate={fromDate}
              maximumDate={new Date(2199, 0, 1)}
              showCalendarButton={true}
            />
          </IM.View>

          <TableWrapper
            tableContainerSpacing={'all'}
            headerData={lotsTableHeader}
            tableData={lotsTableData}
            hasPaging
            maxTableHeight={730}
            showNewItemRow
            onNewItemPress={handleNewPackagePress}
            hasEditAndDelete
            onEditPress={handlePackageEdit}
            onDeletePress={handlePackageDelete}
            flexArray={[1, 1, 1, 1, 1, 1]}
          />
        </IM.View>
      </ScrollView>
      <LotCreateModal controller={createLotModal} onSaveCallback={onNewLotCreated} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
  datePickerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: -20,
    marginLeft: 5,
    marginTop: 5,
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
})
