/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext, ReactNode, useContext } from 'react'

import { GeneralSettings, LotSettings, PackagesSettings } from '../types'

type SettingsContextType = {
  generalSettings: GeneralSettings
  setGeneralSettings: React.Dispatch<React.SetStateAction<GeneralSettings>>
  packageSettings: PackagesSettings
  setPackageSettings: React.Dispatch<React.SetStateAction<PackagesSettings>>
  lotSettings: LotSettings
  setLotSettings: React.Dispatch<React.SetStateAction<LotSettings>>
  loadSettings: () => Promise<void>
  currentLocale: string
}

// empty initializasion of SettingsContext for web
const SettingsContext = createContext<SettingsContextType | null>({
  generalSettings: {} as GeneralSettings,
  setGeneralSettings: () => {},
  packageSettings: {} as PackagesSettings,
  setPackageSettings: () => {},
  lotSettings: {} as LotSettings,
  setLotSettings: () => {},
  loadSettings: async () => {},
  currentLocale: '',
})

export const SettingsContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <>{children}</>
}

export const useSettings = (): SettingsContextType => {
  const context = useContext(SettingsContext)
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider')
  }
  return context
}
