import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import LicenseCreateScreen from '../screens/LicenseCreate/LicenseCreateScreen'
import { LicenseCreateViewProps } from '../screens/LicenseCreate/LicenseCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type LicenseCreateModalProps = {
  controller: ModalController<LicenseCreateViewProps>
} & Pick<LicenseCreateViewProps, 'onCreateLicense' | 'organizationId'>

export default function LicenseCreateModal({ controller, onCreateLicense, organizationId }: LicenseCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <LicenseCreateScreen organizationId={organizationId} ref={ref} onCreateLicense={onCreateLicense} controller={controller} />
    </BaseServiceModal>
  )
}
