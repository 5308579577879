/* eslint-disable @typescript-eslint/no-var-requires */

import { useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Image, ImageProps, ImageSourcePropType, ImageStyle, Pressable, StyleProp, StyleSheet, View } from 'react-native'

const cubatoreLogoBlack = require('../assets/cubatore_logo_black.png') as ImageProps
const cubatoreLogoWhite = require('../assets/cubatore_logo_white.png') as ImageProps
const infometricsLogoBlack = require('../assets/infometrics_logo_black.png') as ImageProps
const infometricsLogoWhite = require('../assets/infometrics_logo_bianco.png') as ImageProps

type Props = {
  source: ImageSourcePropType | undefined
  small?: boolean
  iconStyle?: StyleProp<ImageStyle>
  urlShown: boolean
  onLongPress: () => void
}

export default function LoginImage({ source, iconStyle, urlShown, onLongPress }: Props) {
  const { colorScheme } = useTheme()

  const infometricsLogo = source ?? (colorScheme === 'dark' ? infometricsLogoWhite : infometricsLogoBlack)
  const cubatoreLogo = source ?? (colorScheme === 'dark' ? cubatoreLogoWhite : cubatoreLogoBlack)

  return (
    <Pressable style={styles.container} onLongPress={onLongPress} delayLongPress={__DEV__ ? 0 : urlShown ? 0 : 3000}>
      <View style={styles.imageContainer}>
        <Image style={[styles.image, iconStyle]} resizeMode="contain" source={cubatoreLogo} />
        <Image style={[styles.image, iconStyle]} resizeMode="contain" source={infometricsLogo} />
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  imageContainer: {
    justifyContent: 'center',
    gap: 20,
    alignItems: 'center',
  },
  image: {
    height: 100,
    width: 400,
  },
})
