import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

interface Props {
  description: string
  error?: boolean
  errorMessage?: string
  icon?: IconProp
  iconSize?: number
  spacing?: SpacingProps
}

export default function NoEntry({ spacing = 'all' }: Props) {
  return (
    <IM.View spacing={spacing} style={styles.noDocument}>
      {/* {isOnline ? (
        <>
          {error ? (
            <Error message={errorMessage} style={styles.errorIcon} />
          ) : (
            <>
              <IM.Icon icon={icon ?? ['fal', 'file-slash']} color={theme.textDetail} size={iconSize} />
              <IM.Text secondary style={[styles.text, { fontSize: IMStyle.typography.fontSizeSmall - 2, marginTop: IMLayout.horizontalMargin / 2 }]}>
                {description}
              </IM.Text>
            </>
          )}
        </>
      ) : (
        <Offline />
      )} */}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  noDocument: {
    alignItems: 'center',
    flex: 1,
  },
  errorIcon: {
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
  },
})
