import { IM, IMLayout } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

type Props = {
  title: string
  compact?: boolean
  style?: StyleProp<ViewStyle>
}

export default function SettingsRow({ title, style, compact, children }: PropsWithChildren<Props>) {
  return (
    <IM.View style={[style, styles.row, compact && styles.compact]} spacing="horizontal">
      <IM.Text style={[IMLayout.flex.f1, styles.text]}>{title}</IM.Text>
      <IM.View style={styles.childrenWrapper}>{children}</IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 50,
  },
  compact: {
    minHeight: 25,
  },
  text: {
    marginRight: 12,
    flexShrink: 1,
  },
  childrenWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
})
