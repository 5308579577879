import { IMLayout } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native'

const KeyboardAwareAndroid = memo(function KeyboardAwareAndroid({ children }: PropsWithChildren) {
  if (Platform.OS !== 'android') {
    return <>{children}</>
  }

  return (
    <KeyboardAvoidingView style={[IMLayout.flex.f1, styles.container]} behavior="padding" enabled={Platform.OS === 'android'}>
      {children}
    </KeyboardAvoidingView>
  )
})

export default KeyboardAwareAndroid

const styles = StyleSheet.create({
  container: { backgroundColor: 'transparent' },
})
