import { useContext } from 'react'

import ScreenContext from '../contexts/ScreenContext'

export default function useScreen() {
  const context = useContext(ScreenContext)

  if (context === undefined) {
    throw new Error('useScreen() must be called inside ScreenProvider')
  }

  return context
}
