import { IM, IMLayout, IMStyle, useAlert, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'
import uuid from 'react-native-uuid'

import api from '../../apis/apiCalls'
import { PostPackageRequest } from '../../apis/apiRequestTypes'
import AnimatedButton from '../../components/AnimatedButton'
import DateInput from '../../components/DateInput'
import NumberInput from '../../components/NumberInput'
import Pressable from '../../components/Pressable'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import CustomerSelector from '../../components/selectors/CustomerSelector'
import GradeSelector from '../../components/selectors/GradeSelector'
import MeasurerSelector from '../../components/selectors/MeasurerSelector'
import PackageTypeSelector from '../../components/selectors/PackageTypeSelector'
import PackageUmSelector from '../../components/selectors/PackageUmSelector'
import QualitySelector from '../../components/selectors/QualitySelector'
import SawmillSelector from '../../components/selectors/SawmillSelector'
import TypologySelector from '../../components/selectors/TypologySelector'
import WoodSelector from '../../components/selectors/WoodSelector'
import SettingsRow from '../../components/SettingsRow'
import TextInput from '../../components/TextInput'
import TextWithIcon from '../../components/TextWithIcon'
import { ADD_PACKAGE_BUTTON_ID } from '../../constants/ButtonIds'
import { STORAGE_KEYS } from '../../constants/keys'
import { useData } from '../../contexts/DataContext'
import { usePackageCreation } from '../../contexts/PackageCreationContext'
import { useSettings } from '../../contexts/SettingsContext'
import { usePackages } from '../../hooks/usePackages'
import { PackagesSettings, ThemeColorExpanded } from '../../types'
import { Package, PackageType, packageTypeValues, PackageUm, packageUmValues } from '../../types/types'
import RoundingModal from '../Rounding/RoundingModal'

const now = new Date()

const year = now.getFullYear()
const month = (now.getMonth() + 1).toString().padStart(2, '0')
const day = now.getDate().toString().padStart(2, '0')

const formattedDate = `${year}-${month}-${day}`

type PackageCreateViewProps = {
  editPackageId?: number
  tmpId?: string
  onCreate?: (id: string) => void
  onSave?: () => void
}

const buttonAnimationValue = new Animated.Value(0)

const PackageCreateView: React.FC<PackageCreateViewProps> = ({ editPackageId, tmpId, onCreate, onSave }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { pkg, setPkg, isFormValid, setIsFormValid } = usePackageCreation()
  const { getPackageById } = usePackages()
  const { woods, currentOrganization } = useData()
  const roundingModalController = useModalController()
  const { packageSettings } = useSettings()
  const { addPackage, updatePackage } = usePackages()
  const { alert } = useAlert()

  const [localPackageSettings, setLocalPackageSettings] = useState<PackagesSettings>(packageSettings)

  const defaultPackageUm = packageUmValues.find(um => um.value === 'CMCMMM')

  useEffect(() => {
    if (editPackageId) {
      const fetchPackage = async () => {
        try {
          const existingPackage = await api.getPackageFromId({ packageId: editPackageId })
          setPkg({
            ...existingPackage,
            packageType: mapPackageTypeFromString(existingPackage.packageType),
            ums: mapUMSFromString(existingPackage.ums),
          })
        } catch (error) {
          console.error('Failed to fetch package:', error)
        }
      }

      fetchPackage().catch(console.error)
    } else if (tmpId) {
      fetchPackageById(tmpId)
    } else {
      const newPackage: Partial<Package> = {
        name: '',
        thickness: undefined,
        packageType: undefined,
        customerId: undefined,
        qualityId: undefined,
        woodId: undefined,
        gradeId: undefined,
        sawmillId: undefined,
        measurerId: undefined,
        typologyId: undefined,
        notes: '',
        date: formattedDate,
        commission: 0,
        settings: localPackageSettings,
        ums: defaultPackageUm,
      }
      setPkg(newPackage)
    }
  }, [tmpId, editPackageId, setPkg])

  useEffect(() => {
    if (!tmpId && Platform.OS !== 'web') {
      const fetchLastPackageData = async () => {
        const lastPackageDataString = await AsyncStorage.getItem(STORAGE_KEYS.LAST_PACKAGE_DATA)
        if (lastPackageDataString) {
          const savedPackage: Partial<Package> = JSON.parse(lastPackageDataString) as Partial<Package>
          setPkg({ ...pkg, ...savedPackage })
        }
      }

      fetchLastPackageData().catch(console.error)
    }
  }, [])

  function fetchPackageById(id: string) {
    try {
      const existingPackage = getPackageById(id) as Package
      setPkg(existingPackage)
    } catch (error) {
      console.error('Failed to fetch package:', error)
    }
  }

  function handleChange(value: Partial<Package>) {
    setPkg(prev => ({ ...prev, ...value }))
  }

  function mapPackageTypeFromString(typeString: string | PackageType): PackageType | undefined {
    return packageTypeValues.find(type => type.value === typeString)
  }

  function mapUMSFromString(typeString: string | PackageUm): PackageUm | undefined {
    return packageUmValues.find(type => type.value === typeString)
  }

  function validateForm(pkg2: Partial<Package>) {
    let requiredFields: (string | number | PackageType | undefined)[]

    if (Platform.OS === 'web') {
      requiredFields = [pkg2.name, pkg2.packageType, pkg2.customerId, pkg2.qualityId, pkg2.woodId, pkg2.gradeId, pkg2.sawmillId, pkg2.measurerId]
    } else {
      requiredFields = [
        pkg2.name,
        pkg2.thickness,
        pkg2.packageType,
        pkg2.customerId,
        pkg2.qualityId,
        pkg2.woodId,
        pkg2.gradeId,
        pkg2.sawmillId,
        pkg2.measurerId,
      ]
    }

    const invalidFields = Object.keys(requiredFields).filter(
      key => requiredFields[key as keyof typeof requiredFields] === undefined || requiredFields[key as keyof typeof requiredFields] === ''
    )
    let isValid = requiredFields.every(field => field !== undefined && field !== '')
    if (pkg2.packageType?.value === 'Weight') {
      const currentWood = woods.find(wood => wood.woodId === pkg2.woodId)
      if (!currentWood?.unit || currentWood.unit === 0) {
        isValid = false
      }
    }
    setIsFormValid(isValid)
    if (!isValid) {
      console.log('Invalid fields:', invalidFields)
    }
  }

  const handlePackageSettingsChange = (updatedSettings: PackagesSettings) => {
    setLocalPackageSettings(updatedSettings)
    pkg.settings = updatedSettings
  }

  useEffect(() => {
    validateForm(pkg)
  }, [pkg, validateForm])

  const handleSave = async () => {
    if (!currentOrganization) return
    const updatedPkg: PostPackageRequest = {
      commission: pkg.commission,
      packageNumber: pkg.packageId,
      packageName: pkg.name ?? '',
      addDate: pkg.date ?? '',
      arrivalDate: undefined,
      editDate: undefined,
      isClosed: false,
      isNonCompliant: undefined,
      organizationId: pkg.organizationId ?? currentOrganization.organizationId,
      customerId: pkg.customerId ?? -1,
      typologyId: pkg.typologyId ?? -1,
      qualityId: pkg.qualityId ?? -1,
      woodId: pkg.woodId ?? -1,
      measurerId: pkg.measurerId ?? -1,
      sawmillId: pkg.sawmillId ?? -1,
      gradeId: pkg.gradeId ?? -1,
      packageType: (pkg.packageType?.value as string) ?? '',
      ums: pkg.ums?.value ?? 'CMCMMM',
      note: pkg.notes ?? '',
      packageBoards: pkg.boards,
    }

    await api.postPackages(updatedPkg).then(() => {
      if (onSave) onSave()
    })
  }

  const handlePress = () => {
    if (onCreate) handleCreate().catch(console.error)
    if (onSave) handleSave().catch(console.error)
  }

  const handleCreate = async () => {
    if (isFormValid) {
      try {
        const serializedPkg = JSON.stringify(pkg)
        await AsyncStorage.setItem(STORAGE_KEYS.LAST_PACKAGE_DATA, serializedPkg)
      } catch (error) {
        console.error('Error saving package data:', error)
      }

      const updatedPkg: Package = {
        ...pkg,
        tmpId: tmpId ? tmpId : uuid.v4(),
        closed: false,
        boards: [],
        settings: pkg.settings ?? packageSettings,
      } as Package
      setPkg(updatedPkg)
      if (!tmpId) {
        addPackage(updatedPkg)
      } else {
        updatePackage(updatedPkg)
      }
      if (onCreate) onCreate(updatedPkg.tmpId)
    } else {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
    }
  }

  return (
    <>
      <ScrollViewForm>
        <DateInput
          containerStyle={IMLayout.flex.f1}
          title={i18n.t('DATE')}
          value={pkg.date || formattedDate}
          editable={false}
          onChangeDate={() => handleChange({ date: formattedDate })}
          spacing={['all']}
        />
        <NumberInput
          title={i18n.t('COMMISSION')}
          placeholder={i18n.t('COMMISSION')}
          value={pkg.commission || 0}
          disableFastInputs
          onEndEditing={value => handleChange({ commission: Number(value.nativeEvent.text) })}
          spacing={['all']}
        />
        <TextInput
          required
          title={i18n.t('NAME')}
          placeholder={i18n.t('NAME')}
          value={pkg.name || pkg.packageName || ''}
          onChangeText={value => handleChange({ name: value })}
          spacing={'all'}
        />
        {Platform.OS !== 'web' && (
          <NumberInput
            required
            title={i18n.t('THICKNESS')}
            placeholder={i18n.t('THICKNESS')}
            value={pkg.thickness === undefined ? '' : pkg.thickness.toString()}
            disableFastInputs
            onEndEditing={value => handleChange({ thickness: Number(value.nativeEvent.text) })}
            spacing={'all'}
          />
        )}
        {pkg.packageType?.value === 'AutomaticLength' && (
          <NumberInput
            required
            title={i18n.t('MAX_LENGTH')}
            placeholder={i18n.t('MAX_LENGTH')}
            value={pkg.thickness === undefined ? '' : pkg.maxLength?.toString()}
            disableFastInputs
            onEndEditing={value => handleChange({ maxLength: Number(value.nativeEvent.text) })}
            spacing={'all'}
          />
        )}
        <TextInput
          title={i18n.t('NOTES')}
          placeholder={i18n.t('NOTES')}
          value={pkg.notes || ''}
          onChangeText={value => handleChange({ notes: value })}
          spacing={'all'}
        />
        <PackageTypeSelector
          editable={true}
          required
          selectedPackageType={pkg.packageType}
          onChange={value => handleChange({ packageType: value })}
          spacing={'all'}
        />
        <PackageUmSelector editable={true} required selectedPackageUm={pkg.ums} onChange={value => handleChange({ ums: value })} spacing={'all'} />
        {Platform.OS !== 'web' && (
          <>
            <SettingsRow title={i18n.t('ROUNDING')} style={styles.settingsRow}>
              <Pressable
                onPress={() => {
                  roundingModalController.show()
                }}>
                <TextWithIcon secondary alignIcon="right" icon={['fal', 'chevron-right']} color={theme.textDetail}>
                  {i18n.t('OPEN')}
                </TextWithIcon>
              </Pressable>
            </SettingsRow>
            <RoundingModal
              controller={roundingModalController}
              packageSettings={localPackageSettings}
              onPackageSettingsChange={handlePackageSettingsChange}
            />
          </>
        )}
        <CustomerSelector
          required
          selectedCustomerId={pkg.customerId}
          editable={true}
          onChange={value => handleChange({ customerId: value?.customerId })}
          spacing={['all']}
        />
        <TypologySelector
          required
          selectedTypologyId={pkg.typologyId}
          editable={true}
          onChange={value => handleChange({ typologyId: value?.typologyId })}
          spacing={['all']}
        />
        <QualitySelector
          required
          selectedQualityId={pkg.qualityId}
          editable={true}
          onChange={value => handleChange({ qualityId: value?.qualityId })}
          spacing={['all']}
        />
        <WoodSelector
          required
          selectedWoodId={pkg.woodId}
          editable={true}
          onChange={value => handleChange({ woodId: value?.woodId })}
          spacing={['all']}
        />
        <GradeSelector
          required
          selectedGradeId={pkg.gradeId}
          editable={true}
          onChange={value => handleChange({ gradeId: value?.gradeId })}
          spacing={['all']}
        />
        <SawmillSelector
          required
          selectedSawmillId={pkg.sawmillId}
          editable={true}
          onChange={value => handleChange({ sawmillId: value?.sawmillId })}
          spacing={['all']}
        />
        <MeasurerSelector
          required
          selectedMeasurerId={pkg.measurerId}
          editable={true}
          onChange={value => handleChange({ measurerId: value?.measurerId })}
          spacing={['all']}
        />
      </ScrollViewForm>
      {Platform.OS === 'web' ? (
        <IM.View style={styles.buttonContainer}>
          <PressableTextIcon
            icon={['fal', 'floppy-disk']}
            alignIcon="right"
            pressableStyle={styles.webButton}
            style={{ color: theme.button.icon }}
            iconColor={theme.button.icon}
            onPress={() => handlePress()}>
            {i18n.t('SAVE')}
          </PressableTextIcon>
        </IM.View>
      ) : (
        <AnimatedButton
          style={styles.button}
          id={ADD_PACKAGE_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'check']}
          iconSize={50}
          onPress={() => handlePress()}
        />
      )}
    </>
  )
}

export default PackageCreateView

const styles = StyleSheet.create({
  settingsRow: {
    borderWidth: 1,
    borderColor: '#ccc',
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 10,
    marginHorizontal: 10,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
})
