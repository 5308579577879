import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import GradeCreateScreen from '../screens/GradeCreate/GradeCreateScreen'
import { GradeCreateViewProps } from '../screens/GradeCreate/GradeCreateView'
import { ModalScreenRef } from '../types'
import BaseServiceModal from './BaseServiceModal'

export type GradeCreateModalProps = {
  controller: ModalController<GradeCreateViewProps>
} & Pick<GradeCreateViewProps, 'onCreateGrade'>

export default function GradeCreateModal({ controller, onCreateGrade }: GradeCreateModalProps) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <GradeCreateScreen ref={ref} onCreateGrade={onCreateGrade} controller={controller} />
    </BaseServiceModal>
  )
}
