import { IM } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import SpinningIcon from '../SpinningIcon'

interface Props {
  loading: boolean
  size?: number
  style?: StyleProp<ViewStyle>
}

const BaseTextInputSpinningIcon = memo(function BaseTextInputSpinningIcon({ loading, size, style }: Props) {
  return (
    <IM.View style={[styles.icon, style]}>
      <SpinningIcon loading={loading} size={size} />
    </IM.View>
  )
})

export default BaseTextInputSpinningIcon

const styles = StyleSheet.create({
  icon: { paddingHorizontal: 10 },
})
