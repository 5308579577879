import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Animated, Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { PostUserRequest } from '../../apis/apiRequestTypes'
import { User } from '../../apis/apiResponseTypes'
import AnimatedButton from '../../components/AnimatedButton'
import DateInput from '../../components/DateInput'
import GroupSpacer from '../../components/GroupSpacer'
import PasswordTextInput from '../../components/PasswordTextInput'
import PressableTextIcon from '../../components/PressableTextIcon'
import ScrollViewForm from '../../components/ScrollViewForm'
import TextInput from '../../components/TextInput'
import { CREATE_USER_BUTTON_ID } from '../../constants/ButtonIds'
import { useData } from '../../contexts/DataContext'
import { ThemeColorExpanded, UploadStatus } from '../../types'

const buttonAnimationValue = new Animated.Value(0)

export interface UserCreateViewProps {
  organizationId?: number
  editId?: string
  onCreateUser?: (user: User) => void
}

type Props = {
  onUploadStatus: (status: UploadStatus) => void
}

type ValidateUserField = 'firstName' | 'lastName' | 'username' | 'password' | 'repeatedPassword'
type RequiredField = { value: string | undefined; field: ValidateUserField }

const UserCreateView: React.FC<UserCreateViewProps & Props> = ({ editId, onCreateUser, onUploadStatus, organizationId }) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { alert } = useAlert()
  const { currentOrganization } = useData()
  const [user, setUser] = useState<Partial<PostUserRequest>>({})
  const [repeatedPassword, setRepeatedPassword] = useState<string | undefined>(undefined)
  const [passwordsNotMatching, setPasswordsNotMatching] = useState(false)
  const [missingFields, setMissingFields] = useState<ValidateUserField[]>([])
  const [showMissingFields, setShowMissingFields] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValue, setInitialValue] = useState<Partial<PostUserRequest>>({})
  const [initialRepeatedPassword, setInitialRepeatedPassword] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (editId) {
      loadEditUser().catch(console.error)
    }
  }, [editId])

  async function loadEditUser() {
    setIsLoading(true)
    await api
      .getUser({ userId: Number(editId) })
      .then(response => {
        const editUser = {
          firstName: response.firstName,
          lastName: response.lastName,
          username: response.username,
          password: response.password,
          birthDate: new Date(response.birthDate),
          city: response.city,
          state: response.state,
          country: response.country,
          organizationId: response.organizationId,
        }
        setUser(editUser)
        setRepeatedPassword(response.password)
        setInitialValue(editUser)
        setInitialRepeatedPassword(response.password)
      })
      .catch(console.error)
      .then(() => setIsLoading(false))
  }

  function handleChange(value: Partial<PostUserRequest>) {
    setUser(prev => ({ ...prev, ...value }))
  }

  function validateForm() {
    const requiredFields: RequiredField[] = [
      { value: user.firstName, field: 'firstName' },
      { value: user.lastName, field: 'lastName' },
      { value: user.username, field: 'username' },
      { value: user.password, field: 'password' },
      { value: repeatedPassword, field: 'repeatedPassword' },
    ]
    const notFilledFields = requiredFields.filter(field => field.value === undefined || field.value === '').map(field => field.field)
    setMissingFields(notFilledFields)
    if (notFilledFields.length > 0) {
      onUploadStatus('mandatoryMissing')
    }
    return notFilledFields.length === 0
  }

  function validatePassword() {
    const passwordsMatching = repeatedPassword === user.password
    setPasswordsNotMatching(!passwordsMatching)
    if (!passwordsMatching) {
      onUploadStatus('mandatoryMissing')
    }
    return passwordsMatching
  }

  function haveValuesChanged() {
    return JSON.stringify(user) !== JSON.stringify(initialValue) || repeatedPassword !== initialRepeatedPassword
  }

  useEffect(() => {
    if (haveValuesChanged()) {
      onUploadStatus('waiting')
    }
    if (!showMissingFields) return
    if (!validateForm()) return
    validatePassword()
  }, [user, repeatedPassword, showMissingFields])

  const handlePress = () => {
    setShowMissingFields(true)
    setIsLoading(true)
    onUploadStatus('uploading')
    handleCreate()
      .catch(console.error)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleCreate = async () => {
    //VALIDATION
    // check if all required fields are filled
    if (!validateForm()) {
      alert(i18n.t('WARNING'), i18n.t('MISSING_MANDATORY_FIELDS'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    // check if password and repeated password are the same
    if (!validatePassword()) {
      alert(i18n.t('WARNING'), i18n.t('PASSWORDS_DO_NOT_MATCH'), [
        {
          text: i18n.t('OK'),
          style: 'default',
          onPress: () => {
            return
          },
        },
      ])
      return
    }

    const userBody: PostUserRequest = {
      firstName: user.firstName ?? '***',
      lastName: user.lastName ?? '***',
      username: user.username ?? '',
      password: user.password ?? '',
      birthDate: user.birthDate ?? new Date(),
      city: user.city ?? '***',
      state: user.state ?? '***',
      country: user.country ?? '***',
      organizationId: user.organizationId ?? organizationId ?? currentOrganization?.organizationId ?? 0,
    }

    //UPDATE USER
    if (editId) {
      await api
        .putUser({
          userId: Number(editId),
          ...userBody,
        })
        .then(response => {
          console.log(response)
          //TODO: fix edit
          //onCreateUser?.(response)
          onUploadStatus('done')
        })
        .catch(error => {
          console.log(error)
          alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
            {
              text: i18n.t('OK'),
              style: 'default',
              onPress: () => {
                return
              },
            },
          ])
        })
      return
    }

    //CREATE USER
    await api
      .postUser(userBody)
      .then(response => {
        onCreateUser?.(response)
        onUploadStatus('done')
      })
      .catch(error => {
        console.log(error)
        alert(i18n.t('ERROR'), i18n.t('USER_SAVE_ERROR'), [
          {
            text: i18n.t('OK'),
            style: 'default',
            onPress: () => {
              return
            },
          },
        ])
      })
  }

  return (
    <>
      {!isLoading && (
        <>
          <ScrollViewForm>
            <TextInput
              required
              title={i18n.t('NAME')}
              placeholder={i18n.t('NAME')}
              value={user.firstName ?? ''}
              onChangeText={value => handleChange({ firstName: value })}
              spacing={'all'}
              error={missingFields.includes('firstName')}
            />
            <TextInput
              required
              title={i18n.t('LAST_NAME')}
              placeholder={i18n.t('LAST_NAME')}
              value={user.lastName ?? ''}
              onChangeText={value => handleChange({ lastName: value })}
              spacing={'all'}
              error={missingFields.includes('lastName')}
            />
            <TextInput
              required
              title={i18n.t('USERNAME')}
              placeholder={i18n.t('USERNAME')}
              value={user.username ?? ''}
              onChangeText={value => handleChange({ username: value })}
              spacing={'all'}
              error={missingFields.includes('username')}
            />

            <PasswordTextInput
              required
              title={i18n.t('PASSWORD')}
              placeholder={i18n.t('PASSWORD')}
              value={user.password ?? ''}
              onChangeText={value => handleChange({ password: value })}
              spacing={'all'}
              error={passwordsNotMatching || missingFields.includes('password')}
            />
            <PasswordTextInput
              required
              title={i18n.t('REPEAT_PASSWORD')}
              placeholder={i18n.t('REPEAT_PASSWORD')}
              value={repeatedPassword ?? ''}
              onChangeText={setRepeatedPassword}
              spacing={'all'}
              error={passwordsNotMatching || missingFields.includes('repeatedPassword')}
            />

            <GroupSpacer />

            <DateInput
              containerStyle={IMLayout.flex.f1}
              title={i18n.t('BIRTH_DATE')}
              value={user.birthDate?.toISOString()}
              onChangeDate={value => handleChange({ birthDate: value })}
              showCalendarButton
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_CITY')}
              placeholder={i18n.t('ADDRESS_CITY')}
              value={user.city ?? ''}
              onChangeText={value => handleChange({ city: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_STATE')}
              placeholder={i18n.t('ADDRESS_STATE')}
              value={user.state ?? ''}
              onChangeText={value => handleChange({ state: value })}
              spacing={'all'}
            />
            <TextInput
              title={i18n.t('ADDRESS_COUNTRY')}
              placeholder={i18n.t('ADDRESS_COUNTRY')}
              value={user.country ?? ''}
              onChangeText={value => handleChange({ country: value })}
              spacing={'all'}
            />
          </ScrollViewForm>

          {Platform.OS === 'web' ? (
            <IM.View style={styles.buttonContainer}>
              <PressableTextIcon
                icon={['fal', 'floppy-disk']}
                alignIcon="right"
                pressableStyle={styles.webButton}
                style={{ color: theme.button.icon }}
                iconColor={theme.button.icon}
                onPress={() => handlePress()}>
                {i18n.t('SAVE')}
              </PressableTextIcon>
            </IM.View>
          ) : (
            <AnimatedButton
              style={styles.button}
              id={CREATE_USER_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'check']}
              iconSize={50}
              onPress={() => handlePress()}
            />
          )}
        </>
      )}
      {isLoading && <IM.LoadingSpinner isVisible={isLoading} />}
    </>
  )
}

export default UserCreateView

const styles = StyleSheet.create({
  webButton: {
    backgroundColor: IMStyle.palette.grey,
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: IMLayout.borderRadius,
  },
  button: { backgroundColor: IMStyle.palette.grey, margin: 0, marginBottom: 5, padding: 3 },
  buttonContainer: {
    paddingHorizontal: IMLayout.horizontalMargin,
    paddingVertical: IMLayout.verticalMargin,
  },
})
