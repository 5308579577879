import { CardProps, IM, useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { PackageType } from '../../types/types'

export type PackageTypeProps = { packageType: PackageType } & CardProps

export default function PackageTypeCard({ packageType, ...cardProps }: PackageTypeProps) {
  const { i18n } = useLanguage()

  return (
    <IM.Card {...cardProps}>
      <IM.Text>{i18n.t(packageType.label)}</IM.Text>
    </IM.Card>
  )
}
