import AsyncStorage from '@react-native-async-storage/async-storage'
import React, { createContext, ReactNode, useContext, useState } from 'react'
import DeviceInfo from 'react-native-device-info'

import api from '../apis/apiCalls'
import { apiOptions } from '../apis/apiOptions'
import { SessionLoginResponse } from '../apis/apiResponseTypes'
import { STORAGE_KEYS } from '../constants/keys'
import { useData } from './DataContext'

type AuthenticationProviderProps = { children: ReactNode | (({ isLoggedIn, isAdmin }: { isLoggedIn: boolean; isAdmin: boolean }) => ReactNode) }

export type AuthenticationContextType = {
  isLoggedIn: boolean
  isAdmin: boolean
  userLogin: (username: string, password: string, onLoginSuccess?: () => void) => Promise<SessionLoginResponse>
  licenseLogin: (license: string, onLoginSuccess?: () => void) => Promise<string>
  logout: () => Promise<void>
}

const AuthenticationContext = createContext<AuthenticationContextType | undefined>(undefined)

export function AuthenticationProvider({ children }: AuthenticationProviderProps) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const { fetchData, setOrganizationId, setLicense } = useData()

  async function userLogin(username: string, password: string, onLoginSuccess?: () => void) {
    const response = await api.postSessionLogin(username, password)
    if (response && response.token) {
      //set token and admin status
      apiOptions.token = 'Bearer ' + response.token
      setIsAdmin(response.isCubatoreAdmin)

      //store username and password
      AsyncStorage.setItem(STORAGE_KEYS.USERNAME_KEY, username).catch(console.error)
      AsyncStorage.setItem(STORAGE_KEYS.PASSWORD_KEY, password).catch(console.error)

      //get organizationId and fetch data
      const userResponse = await api.getUser({ userId: response.userId })
      if (userResponse.organizationId) {
        setOrganizationId(userResponse.organizationId)
        await fetchData({ organizationId: userResponse.organizationId, isAdmin: response.isCubatoreAdmin })
      }

      //set logged in status and call onLoginSuccess
      setIsLoggedIn(true)
      onLoginSuccess?.()
    }
    return response
  }

  async function licenseLogin(license: string, onLoginSuccess?: () => void) {
    const response = await api.postSession(license.trim())

    if (response) {
      //set token
      apiOptions.token = 'Bearer ' + response

      //store license
      AsyncStorage.setItem(STORAGE_KEYS.LICENSE_KEY, license).catch(console.error)

      //get organizationId and fetch data
      const responseActivate = await api.activate({
        licenseKey: license,
        deviceId: DeviceInfo.getUniqueId(),
      })
      if (responseActivate.organizationId) {
        setOrganizationId(responseActivate.organizationId)
        setLicense(license.trim())
        await fetchData({ organizationId: responseActivate.organizationId })
      }

      //set logged in status and call onLoginSuccess
      setIsLoggedIn(true)
      onLoginSuccess?.()
    }
    return response
  }

  async function logout() {
    return AsyncStorage.clear().then(() => {
      setIsLoggedIn(false)
      setIsAdmin(false)
      return
    })
  }
  return (
    <AuthenticationContext.Provider value={{ isLoggedIn, isAdmin, userLogin, licenseLogin, logout }}>
      {typeof children === 'function'
        ? children({
            isLoggedIn: isLoggedIn,
            isAdmin: isAdmin,
          })
        : children}
    </AuthenticationContext.Provider>
  )
}

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext)
  if (!context) {
    throw new Error('useAuthentication() must be used within a AuthenticationProvider')
  }
  return context
}
